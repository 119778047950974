import React, { FC, useEffect, useState, useMemo } from "react"
import { useLocation } from "react-router-dom"

import { Helmet } from "react-helmet"

import { pdcStore } from "@assets/store/pdcStore";

export const DynamicStyles: FC = () => {

  const { pathname } = useLocation()

  const ContaSidenavOpened = pdcStore.use.ContaSidenavOpened()
  const CashbackSidenavOpened = pdcStore.use.CashbackSidenavOpened()

  const hideChatBot = useMemo(() =>
    [
      'cadastro',
      'webpush-pagina'
    ].some(e => pathname.includes(e))
    || ContaSidenavOpened
    || CashbackSidenavOpened
    , [
      pathname,
      CashbackSidenavOpened,
      ContaSidenavOpened,
    ])

  return (
    <Helmet>
      <style>
        {hideChatBot ? `
            #root {
                z-index: 2147483601;
                }
            ` : ''}
      </style>
    </Helmet>
  );
};
