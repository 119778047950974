import { Offer } from "@assets/types"

import {
  formatPrice,
  formatPercent,
} from '@assets/utils'

export const promotionCardTopTagContent = (promotion: Offer | undefined) => {

  const mechanic = promotion?.mechanic || promotion?.propertiesMap?.mechanic

  switch (mechanic) {
    case "money_off":
      return `${formatPrice(promotion?.discountAmount || promotion?.propertiesMap?.discountAmount)} OFF`
      break;

    case "percent_off":
      return `${formatPercent(Math.floor(promotion?.discountPercent || promotion?.propertiesMap?.discountPercent))}% OFF`
      break;

    case "virtual_pack":
    case 'virtual_pack_percent_off':
    case 'virtual_pack_money_off':
    case 'virtual_pack_fixed_price':
      return `LEVE ${promotion?.minQuantity || promotion?.propertiesMap?.minQuantity} UNIDADES`
      break;

    case "get_and_pay":
    case 'get_and_pay_percent_off':
    case 'get_and_pay_money_off':
      return `LEVE ${promotion?.minQuantity || promotion?.propertiesMap?.minQuantity} OU MAIS`
      break;

    case 'virtual_pack_item_off':
      return `LEVE ${promotion?.minQuantity || promotion?.propertiesMap?.minQuantity} PAGUE ${Number((promotion?.minQuantity || promotion?.propertiesMap?.minQuantity)) - Number(promotion?.applyQuantity || promotion?.propertiesMap?.applyQuantity)}`
      break;

    case 'get_and_pay_item_off':
      return 'PRESENTE PARA VOCÊ'
      break;

    case 'cashback_product_money':
      return `${formatPrice(promotion?.discountAmount || promotion?.propertiesMap?.discountAmount)} DE VOLTA`
      break;

    case 'cashback_product_percent':
      return `${formatPercent(Math.floor(promotion?.discountPercent || promotion?.propertiesMap?.discountPercent))}% DE VOLTA`
      break;

    default:
      return ''
      break;
  }
}
