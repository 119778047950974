import React, { useState, FC, useEffect } from 'react'

import {
  useLocation,
  useHistory,
} from "react-router-dom"

import { Helmet } from "react-helmet"

//@ts-ignore
import debounce from 'lodash.debounce'

import { Button } from "react-materialize"

/* Data */
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import {
  updateCustomer,
} from "@/features/propzMedia/propzMediaSlice"

import { DisableOverflowScrolling } from '@assets/components/DisableOverflowScrolling'

import {
  TextInputWithSearchDropdown
} from '@/parts'

import {
  getNextRegStep,
} from '@/utils/internal functions'

import './style.sass'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

export const CategoryForm: FC = () => {

  const history = useHistory()

  const dispatch = useAppDispatch()

  const {
    search,
    pathname,
  } = useLocation()

  const [selectedItem, setSelectedItem] = useState('')

  const customer = useTrackedStore().app.customer()

  const saveCategory = debounce(() => {
    dispatch(updateCustomer({ businessCategory: selectedItem }))
      .finally(() => {
        document.body.style.cursor = 'default'
        history.push(`/cadastro-${getNextRegStep(pathname)}${search}`)
      });
  }, 300)

  useEffect(()=>{
    setSelectedItem(customer?.businessCategory || '')
  },[customer])

  return (

    <div className='roll_in_from_bottom_animation category_form'>
      <Helmet>
        <title>Informe uma categoria</title>
        <meta name="description" content={`Com que categoria você trabalha?`} />
      </Helmet>

      <TextInputWithSearchDropdown
        placeholder="Selecione uma categoria"
        initialValue={customer?.businessCategory || ''}
        options={window.parameters.category_options}
        setSelectedItem={setSelectedItem}
        classnames='CategoriaSelector dropdownIcon'
      />

      {/* Submit */}
      <Button
        flat
        disabled={!selectedItem}
        className="white-text primary_background text_primary_background common_text_button common_button_border_radius common_button_shadow"
        onClick={saveCategory}
      >
        <DisableOverflowScrolling />
        Continuar
      </Button>

    </div>
  )
}