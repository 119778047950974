import React, { useState, useEffect, FC } from 'react'

import {
  useHistory,
  useLocation
} from "react-router-dom"

import { Helmet } from "react-helmet"

/* Data */
import { useAppDispatch } from "@/app/hooks"
import {
  updateCustomer,
} from "@/features/propzMedia/propzMediaSlice";

import { ReactComponent as ListSVG } from './list.svg'

import "./style.sass"

export const RegistrationIncomplete: FC = () => {

  const history = useHistory()

  const dispatch = useAppDispatch()

  const { search } = useLocation()

  const [timer, setTimer] = useState(5)

  useEffect(() => {

    document.body.style.cursor = 'wait';

    let props = {
      pmRegisteredInPartnerProgram: true,
      registeredInPartnerProgram: true,
      lastRegistrationUpdate: new Date().toISOString(),
    }

    dispatch(updateCustomer(props))
      .finally(() => {
        document.body.style.cursor = 'default'
        let int = setInterval(() => {
          let t;
          setTimer(time => { t = --time; return t })
          if (!t) {
            clearInterval(int)
            history.push(`/${search}`)
          }
        }, 1000)

      });
  }, [])

  return (

    <div className="incomplete_registration roll_in_from_bottom_animation" >
      <Helmet>
        <title>Cadastro completo com sucesso</title>
        <meta name="description" content="Cadastro concluído realizado com sucesso." />
      </Helmet>
      <ListSVG className='svg' />
      <div className="bottom_text">
        Você será redirecionado para suas ofertas em {timer} segundo...
      </div>
    </div>
  )
}