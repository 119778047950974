export const vipcommerce_redirect = () => {
  try {
    if (window.top) {
      if (
        window.parameters.vipcommerce_cart_url
        //@ts-ignore
        /* navigator.standalone
        || window.matchMedia('(display-mode: standalone)')?.matches
        || document.referrer?.startsWith('android-app://')
        || !navigator.userAgent.includes('Safari/') */
      ) {
        //app
        window.top.location.href = window.parameters.vipcommerce_cart_url as string
      } else {
        //browser
        //window.top.location.pathname = '/checkout/carrinho'
      }
    }
  }
  catch (e) {
    console.log('vipcommerce redirect error : ', e)
  }
}