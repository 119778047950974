import React, { useState, useEffect, FC, useMemo } from 'react'

import {
  useLocation,
  useHistory
} from "react-router-dom"

import { Helmet } from "react-helmet"

/* Form components */
import { useFormik } from 'formik'
import * as yup from 'yup'

import {
  Button,
  Checkbox,
} from "react-materialize"

import { DisableOverflowScrolling } from '@assets/components/DisableOverflowScrolling'

/* Data */
import { useAppDispatch, } from "@/app/hooks"
import {
  updateCustomer,
  deleteCustomerProps,
} from "@/features/propzMedia/propzMediaSlice"

import {
  getNextRegStep,
} from '@/utils/internal functions'

import {
  useTrackedStore,
} from '@assets/store'

import {
  preparePhone,
} from '@assets/utils'

import {
  setupABTest,
} from "@assets/utils"

import {
  pdc_local_storage,
} from '@assets/constants/localStorage'

import {
  phone,
} from '@assets/utils/validationSchemas'

import { Phone } from '@assets/components/CustomerInputs/Phone'

export const PhoneForm: FC = () => {

  const history = useHistory()

  const dispatch = useAppDispatch()

  const [checkbox, setCheckbox] = useState(false)

  const {
    search,
    pathname,
  } = useLocation()

  const customer = useTrackedStore().app.customer()

  useEffect(() => {
    setCheckbox(Boolean(customer.smsContactFlag))
  }, [customer])

  let validationSchema = useMemo(() => yup.object({
    phone: phone(),
  }), [])

  const formik = useFormik({
    initialValues: {
      phone: preparePhone(customer?.mobilePhone?.replace('+55', '')),
    },
    validationSchema: validationSchema,
    onSubmit: async (value) => {
      document.body.style.cursor = 'wait'

      if (value.phone) {

        let phone = '+55' + preparePhone(value.phone)

        let props = {
          mobilePhone: phone,
          smsContactFlag: true,
          pmUnsubscribedSms: false,
        }

        await dispatch(updateCustomer(props))

      } else {

        if (customer.mobilePhone) {
          await dispatch(deleteCustomerProps(['smsContactFlag']))
          await dispatch(deleteCustomerProps(['mobilePhone']))
          await dispatch(deleteCustomerProps(['pmUnsubscribedSms']))
        }
      }

      document.body.style.cursor = 'default'
      formik.setSubmitting(false)
      history.push(`/cadastro-${getNextRegStep(pathname)}${search}`)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit} className='roll_in_from_bottom_animation full_page_form' >
      <Helmet>
        <title>
          Informe seu celular
        </title>
        <meta name="description" content={`Solicitação do celular de contato.`} />
      </Helmet>

      <Phone
        formik={formik}

        onBlur={(e) => {
          formik.handleBlur(e)
        }}

        text_input_props={{
          autofocus: "true",
        }}
      />

      <div className="info" style={{
        textAlign: window.parameters.glob_LGPDFlow ? 'start' : 'center'
      }}>
        {
          window.parameters.glob_LGPDFlow ?
            <Checkbox
              className="rounded_checkbox"
              checked={Boolean(customer.smsContactFlag)}
              filledIn
              label=""
              //@ts-ignore
              value={true}
              //@ts-ignore
              onChange={e => setCheckbox(e.target.checked)}
            />
            : ''
        }

        <span>
          {
            window.parameters.glob_LGPDFlow ?
              'Aceito receber comunicações sobre ofertas e descontos exclusivos no número informado.'
              :
              'Ao continuar, você aceita receber nossas comunicações sobre ofertas e descontos exclusivos no número informado.'
          }
        </span>
      </div>

      {/* Submit */}
      <Button
        flat
        //@ts-ignore
        type="submit"
        disabled={
          formik.isSubmitting
          || !formik.isValid
          || Boolean(
            window.parameters.glob_LGPDFlow
            && formik.values.phone
            && !checkbox
          )
        }
        waves="light" className="white-text"
      >
        <DisableOverflowScrolling />
        Quero receber ofertas no meu número
      </Button>
    </form >
  )
}