export const ignoreErrors = [
  // Random plugins/extensions
  "top.GLOBALS",
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  "originalCreateNotification",
  "canvas.contentDocument",
  "MyApp_RemoveAllHighlights",
  "http://tt.epicplay.com",
  "Can't find variable: ZiteReader",
  "jigsaw is not defined",
  "ComboSearch is not defined",
  "http://loading.retry.widdit.com/",
  "atomicFindClose",
  // Facebook borked
  "fb_xd_fragment",
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  "bmi_SafeAddOnload",
  "EBCallBackMessageReceived",
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  "conduitPage",
  "Unexpected token '<'",
  "'getBattery' on 'Navigator'",
  "Object.sendBeaconData",
  "boomerang",
  "getBattery",
  "cross-origin frame",
  "Failed to fetch",
  "The notification permission was not granted and blocked instead",
  "Missing App configuration",
  "BeforeInstallPromptEvent",
  "_pageTimings",
  "got '<'",
  "Object Not Found Matching Id",
  "ibFindAllVideos",
  "tgetT",
  "@webkit-masked-url",
  "require is not defined",
  "Load failed",
  "FCM",
  "push service error",
  "ServiceWorker",
  "cancelado",
  "Messaging",
  "insertBefore",
  "Registration failed",
  "service-worker",
  "MIME type",
  "QuotaExceededError",
  'access property "endpoint"',
  'The operation is not supported',
  "Unexpected token '.'",
  "Service Worker",
  "User denied permission to use the Push API",
  "Can't find variable: Notification",
  "Unexpected token u in JSON at position 0",
  "The operation is insecure",
  "Notification is not defined",
  "Script load failed",
  "NetworkError",
  "Notification.requestPermission",
  "Unexpected end of input",
  "Error retrieving push subscription",
  "Application offline",
  "removeChild",
  "not valid JSON",
  "evaluating 'n.length'",
  "Connection is closing",
  "The transaction was aborted",
  "IDBDatabase",
  "Maximum update depth exceeded",
  "update depth",
  "Error: Maximum update depth exceeded. This can happen when a component repeatedly calls setState inside componentWillUpdate or componentDidUpdate. React limits the number of nested updates to prevent infinite loops.",
  "Maximum update depth exceeded. This can happen when a component repeatedly calls setState inside componentWillUpdate or componentDidUpdate. React limits the number of nested updates to prevent infinite loops.",
  "iw is not a function",
  "Access is denied for this document",
  "getItem",
  "apiRoot",
  "AbortError",
  "Internal error",
  "timed out",
  "Unexpected token 'else'",
  "textContent",
  "msDiscoverChatAvailable",
  "#185",
  "Unexpected identifier 'https'",
  "downloads",
  "Illegal invocation",
  "A conexão de rede foi perdida",
  "Failed to execute 'replaceState' on 'History'",
  "ibPauseAllVideos",
  "controlsList",
  "Access is denied",
  "denied in this document",
  "Failed to read the 'serviceWorker' property from 'Navigator': Access to service workers is denied in this document origin.",
  "PerformanceObserver",
  "window.webkit.messageHandlers",
  "prompt()",
  "firefoxSample",
  "__gCrWeb.instantSearch.clearHighlight",
  "window.instantSearchSDKJSBridge.onTextSelected",
  "intermediate value",
  "reading 'style'",
  "evaluating 'a.O'",
  "Properties can only be defined on Objects",
  "reading 'consoleLog'",
  "Not allowed by ContentSecurityPolicy",
  "e.toLowerCase is not a function",
  "evaluating 't.TranslatedText'",
  "Inconsistent Data",
  "reading 'cookie'",
  "The object can not be found here",
  "ResizeObserver loop completed with undelivered notifications",
  "Non-Error promise rejection captured with value: undefined",
  "this.cookie",
  "evaluating 'a.N'",
  '[sw] push event',
  '[sw] notificationclick event',
  'jQuery is not defined',
  "reading 'classList'",
  "getDropdownRule",
  "too much recursion",
  "Cannot set properties of null (setting 'tabIndex')",
  "obtainVideoInfos",
  "Not allowed to focus a window",
  "Database deleted by request of the user",
  "Error looking up record in object store by key range",
  'this.$indicators.find(".indicator-item").eq(le)[0].classList',
  "Non-Error promise rejection captured with value: null",
  "Attempt to get a record from database without an in-progress transaction",
  "Something went wrong while trying to open the window",
  "Object store cannot be found in the backing store",
  "response.ready",
  "onDisconnect",
  "[SW] fetch error :  TypeError: Failed to fetch",
  "window.pdfjsLib.GlobalWorkerOptions",
  "reading 'src'",
  "mv().getRestrictions is not a function. (In 'mv().getRestrictions(1,a)', 'mv().getRestrictions' is undefined)",
  "undefined is not an object (evaluating 'n.url')",
  "Cannot read properties of undefined (reading 'return')",
  "Java exception was raised during method invocation",
]