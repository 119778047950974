import { mapValuesKey } from 'zustand-x'

import { appStore } from './appStore'
import { persistedAppStore } from './persistedAppStore'

// Global store
export const rootStore = {
  app: appStore,
  persistedApp: persistedAppStore,
};

// Global hook selectors
export const useStore = () => mapValuesKey('use', rootStore);

// Global tracked hook selectors
export const useTrackedStore = () => mapValuesKey('useTracked', rootStore);

// Global getter selectors
export const store = mapValuesKey('get', rootStore);

// Global actions
export const actions = mapValuesKey('set', rootStore);