import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import propzMediaReducer from "@/features/propzMedia/propzMediaSlice";

export const store = configureStore({
  reducer: {
    propzMedia: propzMediaReducer,
  },
  devTools: import.meta.env.MODE == "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "propzMedia/activeAll/fulfilled",
          "propzMedia/activeAll/rejected",
        ],
        ignoredPaths: [
          "propzMedia.offers",
        ],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
