import { FC, useMemo, useEffect, createRef } from "react"
import { useHistory, useLocation } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import {
  setPropzMediaStateParameter,
} from "@/features/propzMedia/propzMediaSlice"

import { Button, Modal } from "react-materialize"

import {
  Icon
} from '@/parts'

import { pdcStore } from "@assets/store/pdcStore"

import svg from './message.svg'

import './style.sass'

export const DeniedNotificationsModal: FC = () => {

  const dispatch = useAppDispatch()

  const {
    DeniedNotificationsModalState,
    checkNotificationsPermission,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const checkPermission = (permission: any, event?: string | null | boolean) => {
    switch (permission.state) {
      case 'granted':

        dispatch(setPropzMediaStateParameter({
          DeniedNotificationsModalState: {
            continue: false,
            opened: false
          }
        }))

        dispatch(setPropzMediaStateParameter({ checkNotificationsPermission: null }))

        if (event && event !== true) {
          window.dataLayer.push({
            event,
            pwaTrackingId: window.pwaTrackingId,
          })
        }

        pdcStore.set.triggerUpdateFirebaseRegistration()

        break
      default:
        console.log('[DeniedNotificationsModal] permission default')

        dispatch(setPropzMediaStateParameter({
          DeniedNotificationsModalState: {
            continue: false,
            opened: true
          }
        }))

        window?.Notification?.requestPermission()
    }
  }

  useEffect(() => {
    if (checkNotificationsPermission) {
      try {
        window.navigator?.permissions?.query({ name: 'notifications' })
          .then(permission => {

            checkPermission(permission, checkNotificationsPermission)

            permission.onchange = () => {
              //console.log('notification permission changed : ', permission)
              checkPermission(permission, checkNotificationsPermission)
            }
          })
      } catch (e) {
        console.log('[DeniedNotificationsModal] notification permission error : ', e)
      }
    }
  }, [checkNotificationsPermission])

  return (
    <Modal
      open={DeniedNotificationsModalState.opened}

      id="DeniedNotificationsModal"
      options={{
        dismissible: true,
        /* endingTop: "10%",
        inDuration: 250,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,*/
        startingTop: "17%",
        onCloseEnd: () => {
          dispatch(setPropzMediaStateParameter({
            DeniedNotificationsModalState: {
              opened: false,
              continue: true
            }
          }))

          dispatch(setPropzMediaStateParameter({ checkNotificationsPermission: null }))
        }
      }}
    >

      <Button
        flat
        className="close_button"

        onClick={() => {
          dispatch(setPropzMediaStateParameter({
            DeniedNotificationsModalState: {
              opened: false,
              continue: true
            }
          }))

          dispatch(setPropzMediaStateParameter({ checkNotificationsPermission: null }))
        }}
      >
        <i className="material-icons">close</i>
      </Button>

      <Icon className="modal_icon" image={svg} />

      <div className="header">
        Habilite as notificações do seu navegador
      </div>

      <div className="content">
        Identificamos que suas notificações estão bloqueadas. Altere as permissões nas configurações no seu navegador para ficar por dentro das melhores ofertas!
      </div>

    </Modal>
  )
}