import { getApiRoot } from '@assets/functions/getApiRoot'

import { Benefits } from '@assets/types'

import {
  sessionParameter,
} from '@assets/utils'

export const benefits = (id: string) => {
  return fetch(`${getApiRoot()}/v2/cxp/benefits/${id}?${sessionParameter()}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
  })
}