import React, { FC, useState, useEffect, useRef, useMemo, useCallback, } from "react"

import {
  promotionCardTopTagContent,
} from '@assets/functions'

import {
  Offer,
} from "@assets/types"

interface Props {
  promotion?: Offer;
  [x: string]: any,
}

export const PromotionDiscountTag: FC<Props> = ({ promotion, children, ...rest }) => {

  const topTag = useMemo(() => promotionCardTopTagContent(promotion), [promotion])

  return (
    topTag ?
      <div {...rest}>
        {topTag}
        {children}
      </div>
      : <></>
  )
}
