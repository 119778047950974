import { FC, useMemo, useEffect, createRef } from "react"

import {
  QueryLink,
  Icon,
} from '@/parts'

import { prevent_el_scroll } from "@/utils/ui functions/prevent_el_scroll"

import { ReactComponent as ListSVG } from '@/assets/icons/list.svg'
import { ReactComponent as SmallPersonSVG } from '@/assets/icons/person.svg'
import { ReactComponent as LogoutSVG } from '@/assets/icons/logout.svg'

import { ReactComponent as IzioSVG } from '@assets/icons/izio.svg'

import { ReactComponent as CupomSVG } from '@assets/icons/cupom.svg'

import ajudaSVG from '@/assets/icons/ajuda.svg'

import { logout } from "@/utils/internal functions/logout"
import { pdcStore } from "@assets/store/pdcStore"
import { useDebounceCallback } from "usehooks-ts"

export const ContaSidenav: FC<{ icons: string[] }> = ({ icons }) => {

  const ContaSidenavOpened = pdcStore.use.ContaSidenavOpened()
  const marketplaceEnable = pdcStore.use.marketplaceEnable()

  const sidenavRef = createRef<HTMLDivElement>()

  useEffect(() => {
    prevent_el_scroll(sidenavRef.current)
  }, [])

  const { registrationDisable } = useMemo(() => window.parameters, [])

  const close = useDebounceCallback(() => pdcStore.set.ContaSidenavOpened(false), 50)

  const logout_click = useDebounceCallback(()=>{
    close()
    logout()
  }, 50)

  return (
    <div ref={sidenavRef}
      className={`conta_sidenav ${ContaSidenavOpened ? 'active' : ''}`}
    >

      {marketplaceEnable && (
        <QueryLink
          to="/shopping"
          className=""
          isActive={(match, location) => Boolean(match && match.url == location.pathname)}
          onClick={close}>
          <ListSVG />
          <span>Meu extrato</span>
        </QueryLink>
      )}

      {
        registrationDisable != 'true'
          ?
          (registrationDisable == '' || registrationDisable == 'false')
            ?
            < QueryLink to="/cadastro"
              onClick={close}>
              <SmallPersonSVG />
              <span>Informações pessoais</span>
            </QueryLink>
            :
            <a href={registrationDisable} target="_blank">
              <SmallPersonSVG />
              <span>Informações pessoais</span>
            </a>
          : ''
      }

      {
        icons.indexOf('izio') > 1 ?
          < QueryLink to="/benefits/izio"
            onClick={close}>
            <IzioSVG />
            <span>Vantagens</span>
          </QueryLink>
          : <></>
      }

      {
        icons.indexOf('cv') > 1 ?
          < QueryLink to="/benefits/cv"
            onClick={close}>
            <CupomSVG />
            <span>Cupom Verde</span>
          </QueryLink>
          : <></>
      }

      < QueryLink to="/ajuda"
        onClick={close}>
        <Icon image={ajudaSVG} className="menu_link_icon" />
        <span>Ajuda</span>
      </QueryLink>

      <a
        className="logout"
        onClick={logout_click}
      >
        <LogoutSVG />
        <span>Sair da conta</span>
      </a>

    </div >
  )
}