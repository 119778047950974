import { FC, useEffect, useMemo, useState, createRef } from "react"

import {
  Modal,
  TextInput,
  Button,
  Checkbox,
} from "react-materialize"

import { useFormik } from 'formik'
import * as yup from 'yup'

import { useAppDispatch, useAppSelector } from "@/app/hooks"
import {
  setPropzMediaStateParameter,
  updateCustomer,
} from "@/features/propzMedia/propzMediaSlice"

import { useMediaQuery } from 'react-responsive'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

import '@/assets/sass/components/ContactSkippedPopup.sass'

import '&/sass/components/input-field.sass'

export const EmailSkippedPopup: FC = () => {

  const dispatch = useAppDispatch()

  const isNotMobile = useMediaQuery({ query: '(min-width: 768.1px)' }) ? 'input-outlined' : ''

  const {
    showEmailSkippedPopup,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const customer = useTrackedStore().app.customer()

  let validationSchema = yup.object({
    email: yup
      .string()
      .email("Insira um endereço de e-mail válido")
      .required("Insira um endereço de e-mail válido"),
  })

  const formik = useFormik({
    initialValues: {
      email: customer?.homeEmail || '',
      emailContact: customer?.emailContactFlag && !customer.pmUnsubscribedEmail,
    },
    validationSchema: validationSchema,
    onSubmit: async (value) => {

      document.body.style.cursor = 'wait';

      let props = {
        homeEmail: value.email,
        emailContactFlag: value.emailContact,
        pmUnsubscribedEmail: !value.emailContact
      }

      dispatch(updateCustomer(props))
        .finally(() => {
          document.body.style.cursor = 'default'
          formik.setSubmitting(false);
          dispatch(setPropzMediaStateParameter({ showEmailSkippedPopup: false }))
        })
    },
  })

  const [inputNode, setInputNode] = useState<HTMLInputElement | null>()

  useEffect(() => {
    if (inputNode) {
      //@ts-ignore
      inputNode.addEventListener('input', function (e: InputEvent) {

        formik.setFieldValue("email", (e?.target as HTMLInputElement)?.value, false)
      })
    }
  }, [inputNode])

  return (
    <Modal
      //@ts-ignore
      className="ContactSkippedPopup"
      open={
        showEmailSkippedPopup
        && !customer.mobilePhone
        && !customer.homeEmail
      }
      options={{
        dismissible: false,
        preventScrolling: true,
        onCloseEnd: () => dispatch(setPropzMediaStateParameter({ showEmailSkippedPopup: false })),
      }}
    >

      <div
        className="header"
      >
        Cadastre um dado de contato
      </div>

      <div className="subheader">
        É importante informar ao menos um meio de contato para garantir o acesso à sua conta.
      </div>

      <form onSubmit={formik.handleSubmit} >
        {/* email */}
        <TextInput
          id="email"
          name="email"
          label="E-mail"

          //@ts-ignore
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          autofocus="true"

          //@ts-ignore
          ref={(e) => {
            setInputNode(e)
          }}

          //@ts-ignore
          className={`${isNotMobile} ${formik.touched.email && Boolean(formik.errors.email) && 'invalid' || ''}`}
          value={formik.values.email}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik.touched.email && formik.errors.email || ' '}

          disabled={formik.isSubmitting}
        />

        <div className="checkbox_container">
          <Checkbox
            className="rounded_checkbox"
            checked={!!formik?.values.emailContact}
            filledIn
            name="emailContact"
            id="emailContact"
            //@ts-ignore
            value={false}
            onChange={formik?.handleChange}
            disabled={formik?.isSubmitting}
          />

          <span>
            Quero receber ofertas no e-mail informado.
          </span>
        </div>

        {/* Submit */}
        <Button
          flat
          //@ts-ignore
          type="submit"
          disabled={
            formik.isSubmitting
            || !formik.isValid
          }
          className="text_primary_background common_text_button primary_background common_button_font_size common_button_border_radius common_button_shadow"
        >
          Continuar
        </Button>

        <a
          className="alt_skip_link"
          onClick={() => {
            dispatch(setPropzMediaStateParameter({ showEmailSkippedPopup: false }))
            dispatch(setPropzMediaStateParameter({ showPhoneSkippedPopup: true }))
          }}
        >
          Informar celular
        </a>

      </form>

      <div className="footer">
        Caso não queira receber nossas ofertas no e-mail informado, sua escolha será respeitada.
      </div>

    </Modal>
  );
};