import { FC, useMemo, useEffect, createRef } from "react"

import { QueryLink } from '@/parts'

import { prevent_el_scroll } from "@/utils/ui functions/prevent_el_scroll"
import { pdcStore } from "@assets/store/pdcStore"
import { useDebounceCallback } from "usehooks-ts"

export const CashbackSidenav: FC = () => {

  const CashbackSidenavOpened = pdcStore.use.CashbackSidenavOpened()

  const sidenavRef = createRef<HTMLDivElement>()

  useEffect(() => {
    prevent_el_scroll(sidenavRef.current)
  })

  const close = useDebounceCallback(() => pdcStore.set.CashbackSidenavOpened(false), 50)

  return (
    <div ref={sidenavRef}
      className={`cashback_sidenav ${CashbackSidenavOpened ? 'active' : ''}`}
    >

      {
        (
          window.settings["retail.marketplace.enable"] ||
          window.settings["retail.pos.cashback.enable"]
        )
        && (
          <QueryLink
            to="/shopping"

            isActive={(match, location) => Boolean(match && match.url == location.pathname)}

            className={(isActive) => `${isActive && 'active' || ''}`}
            onClick={close}
          >
            <span>
              Ofertas de cashback
            </span>
          </QueryLink>
        )}

      <QueryLink
        to="/shopping/faq"
        className={(isActive) => `${isActive && 'active' || ''}`}
        onClick={close}
      >
        <span>
          FAQ
        </span>
      </QueryLink>

    </div>
  )
}