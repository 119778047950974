import React, { FC } from "react";

import "./style.css";

export const ProgramLogo: FC = () => {
  const { glob_programLogo } = window.parameters
  return (
    <img
      src={glob_programLogo}
      alt="Program-logo"
      className="logo"
    />
  );
};
