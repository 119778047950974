import { parametersObjectToQueryString } from '@assets/functions/parametersObjectToQueryString'

import {
  sessionParameter,
  trackingIdsParameter,
} from '@assets/utils'

import { getApiRoot } from '@assets/functions/getApiRoot'

import { Offer } from '@assets/types'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

export const user_retail_skus = async (skuIds: Extract<Offer["properties"], object>["PRODUCT_ID_INCLUSION"]) => {
  const resp = await fetch(`${getApiRoot()}/v1/user/retail-sku?${parametersObjectToQueryString({ skuIds })}${sessionParameter()}${trackingIdsParameter()}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
  })

  if (resp.status == 200) {
    return await resp.json()
  }
  return []
}