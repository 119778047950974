import {
  Customer
} from '@assets/types'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

export const propIsPresent = (propName: keyof Customer) => {

  const customer = store.app.customer()

  return customer.hasOwnProperty(propName) && customer[propName] != null ? 1 : 0
}