import React, { useState, useEffect, FC, useMemo, useRef } from 'react'

import {
  useLocation,
  useHistory,
} from "react-router-dom"

import { Helmet } from "react-helmet"

import InputMask from "react-input-mask"

/* Form components */
import { useFormik } from 'formik'
import * as yup from 'yup'

import { TextInput, Button, Select } from "react-materialize"

import { useMediaQuery } from 'react-responsive'

/* Data */
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import {
  deleteCustomerProps,
  updateCustomer
} from "@/features/propzMedia/propzMediaSlice"

import { QueryLink } from '@/parts'

import {
  remove_select_touch_end,
  getNextRegStep,
} from '@/utils/internal functions'

import { DisableOverflowScrolling } from '@assets/components/DisableOverflowScrolling'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

let cepPrev: string | undefined
let cepPrevValid = false

let prevValues = {
  rua: '',
  bairro: '',
  cidade: '',
  uf: ''
}

export const AddressForm: FC = () => {

  const history = useHistory()

  const dispatch = useAppDispatch()

  const {
    search,
    pathname
  } = useLocation()

  const isNotMobile = useMediaQuery({ query: '(min-width: 768.1px)' }) ? 'input-outlined' : ''

  const customer = useTrackedStore().app.customer()

  const [showAddress, setShowAddress] = useState(
    !!customer?.homeZip
    || !!customer?.homeStreet
    || !!customer?.homeNum
    || !!customer?.homeDistrict
    || !!customer?.homeCity
    || !!customer?.homeState
    || !!customer?.homeComplement
  )

  const numRef = useRef()

  useEffect(() => {
    //console.log('[AddressForm] rerendered cepPrev')
    cepPrev = customer?.homeZip || ''
    cepPrevValid = !!customer?.homeZip
  }, [])

  const validationSchema = useMemo(() => {
    //console.log('[AddressForm] rerendered yup')
    return yup.object({
      cep: yup
        .string()
        .required('Insira um CEP válido')
        .test({
          message: 'Insira um CEP válido',
          test: async (value) => {
            //console.log('cep : ', value)

            if (cepPrev != value) {

              cepPrev = value

              if (value && value?.length) {
                let cep = value.replace(/\D/g, '')
                if (cep?.length == 8 && /^[0-9]{8}$/.test(cep)) {
                  let dados = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
                    .then(res => res.json())

                  //console.log('dados: ', dados);
                  if (
                    !("erro" in dados)
                  ) {

                    if (
                      prevValues.rua != dados.logradouro
                      || prevValues.bairro != dados.bairro
                      || prevValues.cidade != dados.localidade
                      || prevValues.uf != dados.uf
                    ) {

                      //console.log('[AddressForm] update 4 dependent fields : ', dados)

                      prevValues.rua = dados.logradouro
                      prevValues.bairro = dados.bairro
                      prevValues.cidade = dados.localidade
                      prevValues.uf = dados.uf

                      formik.setFieldValue("rua", dados.logradouro, false)
                      formik.setFieldValue("bairro", dados.bairro, false)
                      formik.setFieldValue("cidade", dados.localidade, false)
                      formik.setFieldValue("uf", dados.uf, false)

                      setShowAddress(showAddress => {
                        if (!showAddress) {
                          formik.setTouched({ ...formik.touched, num: true }, false);
                          (numRef.current as unknown as HTMLInputElement)?.focus()
                        }
                        return true
                      })
                    }

                    cepPrevValid = true
                    return true

                  }
                }
              }
              cepPrevValid = false
              return false

            } else return cepPrevValid
          }
        }),
      rua: yup
        .string()
        .required('O logradouro é obrigatório'),
      num: yup
        .string()
        .required('O número é obrigatório'),
      bairro: yup
        .string()
        .required('O bairro é obrigatório'),
      cidade: yup
        .string()
        .required('A cidade é obrigatória'),
      uf: yup
        .string()
        .required('O estado é obrigatório'),
    })
  }, [])

  const formik = useFormik({
    initialValues: {
      cep: customer?.homeZip || '',
      rua: customer?.homeStreet || '',
      num: customer?.homeNum || '',
      bairro: customer?.homeDistrict || '',
      cidade: customer?.homeCity || '',
      uf: customer?.homeState || '',
      complemento: customer?.homeComplement || '',
    },
    validationSchema: validationSchema,
    //validateOnChange: false,
    onSubmit: async (value) => {

      document.body.style.cursor = 'wait';

      let props = {
        homeZip: value.cep.replace(/\D/g, ""),
        homeStreet: value.rua,
        homeNum: value.num.replace(/\D/g, ""),
        homeDistrict: value.bairro,
        homeCity: value.cidade,
        homeState: value.uf,
        ...value.complemento ? { homeComplement: value.complemento } : {}
      }


      if (value.complemento) {
        props.homeComplement = value.complemento
      } else {
        if (customer.homeComplement) {
          await dispatch(deleteCustomerProps(['homeComplement']))
        }
      }

      dispatch(updateCustomer(props))
        .finally(() => {
          document.body.style.cursor = 'default'
          formik?.setSubmitting(false);

          history.push(`/cadastro-${getNextRegStep(pathname)}${search}`)
        });
    },
  })

  const addressVisible = useMemo(() => {
    //console.log('[AddressForm] rerendered addressVisible : ', showAddress)
    return showAddress
      || !!customer?.homeZip
      || !!customer?.homeStreet
      || !!customer?.homeNum
      || !!customer?.homeDistrict
      || !!customer?.homeCity
      || !!customer?.homeState
      || !!customer?.homeComplement
  }, [showAddress, customer])

  return (

    <form onSubmit={formik.handleSubmit} >
      <Helmet>
        <title>Informe seu endereço</title>
        <meta name="description" content="Solicitação do endereço." />
      </Helmet>

      {/* CEP */}
      <InputMask mask="99999-999"
        value={formik.values.cep}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        disabled={formik.isSubmitting}
        maskChar={null}
      >
        {(inputProps: any) =>
          <TextInput
            id="cep"
            name="cep"
            label="CEP"
            type="tel"
            inputMode="numeric"

            className={`${isNotMobile} ${formik.touched.cep && Boolean(formik.errors.cep) && 'invalid' || ''}`}

            {...inputProps}

            error={formik.touched.cep && formik.errors.cep || ' '}

          />
        }
      </InputMask>

      {/* Visibility switching part */}
      <div className={`visibility_switchable ${addressVisible ? 'visible' : ''}`} >

        {/* Rua */}
        <TextInput
          id="rua"
          name="rua"
          label="Logradouro"

          //@ts-ignore
          className={`${isNotMobile} ${formik.touched.rua && Boolean(formik.errors.rua) && 'invalid' || ''}`}

          value={formik.values.rua}

          onBlur={formik.handleBlur}
          onChange={formik.handleChange}

          error={formik.touched.rua && formik.errors.rua || ' '}

          disabled={formik.isSubmitting}
        />

        {/* House Num */}
        <InputMask mask="99999"
          value={formik.values.num}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          disabled={formik.isSubmitting}
          maskChar={null}
        >
          {(inputProps: any) =>
            <TextInput
              id="num"
              name="num"
              label="Número"
              type="tel"
              inputMode="numeric"
              ref={numRef}

              className={`${isNotMobile} ${formik.touched.num && Boolean(formik.errors.num) && 'invalid' || ''}`}

              {...inputProps}

              error={formik.touched.num && formik.errors.num || ' '}
            />
          }
        </InputMask>

        {/* Complemento */}
        <TextInput
          id="complemento"
          name="complemento"
          label="Complemento (opcional)"

          //@ts-ignore
          className={`${isNotMobile}`}

          value={formik.values.complemento}

          onBlur={formik.handleBlur}
          onChange={formik.handleChange}

          error={formik.touched.complemento && formik.errors.complemento || ' '}

          disabled={formik.isSubmitting}
        />

        {/* Bairro */}
        <TextInput
          id="bairro"
          name="bairro"
          label="Bairro"
          //@ts-ignore
          className={`${isNotMobile} ${formik.touched.bairro && Boolean(formik.errors.bairro) && 'invalid' || ''}`}

          value={formik.values.bairro}

          onBlur={formik.handleBlur}
          onChange={formik.handleChange}

          error={formik.touched.bairro && formik.errors.bairro || ' '}

          disabled={formik.isSubmitting}
        />

        {/* Cidade */}
        <TextInput
          id="cidade"
          name="cidade"
          label="Cidade"

          //@ts-ignore
          className={`${isNotMobile} ${formik.touched.cidade && Boolean(formik.errors.cidade) && 'invalid' || ''}`}

          value={formik.values.cidade}

          onBlur={formik.handleBlur}
          onChange={formik.handleChange}

          error={formik.touched.cidade && formik.errors.cidade || ' '}

          disabled={formik.isSubmitting}
        />

        {/* UF */}

        <Select
          name="uf"
          id="uf"
          label="Estado"

          multiple={false}
          options={{
            classes: `${isNotMobile} select-restricted-height ${formik.touched.uf && Boolean(formik.errors.uf) && 'invalid' || ''}`,
            dropdownOptions: {
              alignment: 'left',
              autoTrigger: true,
              closeOnClick: true,
              constrainWidth: true,
              coverTrigger: false,
              hover: false,
              inDuration: 150,
              onCloseStart: (e) => {
                remove_select_touch_end(e)
                document.getElementById('uf')?.parentElement?.classList.remove('expanded')
              },
              //onCloseEnd: null,
              //onOpenEnd: null,
              onOpenStart: () => {
                document.getElementById('uf')?.parentElement?.classList.add('expanded')
              },
              outDuration: 250
            }
          }}

          value={formik.values.uf}

          //@ts-ignore
          onBlur={formik.handleBlur}
          onChange={(e) => {
            e.preventDefault()
            e.stopPropagation()

            remove_select_touch_end(e.target)
            formik.handleChange(e)
          }}

          error={formik.touched.uf && formik.errors.uf || ' '}

          disabled={formik.isSubmitting}
        >
          <option value="" disabled selected>Estado</option>

          {['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO', 'DF'].map((state, i) =>
            <option key={`${state}-${i}`} value={state}>
              {state}
            </option>
          )}
        </Select>

      </div>

      {/* Submit */}
      <Button
        flat
        //@ts-ignore
        type="submit"
        disabled={formik.isSubmitting || !formik.isValid}
        waves="light" className="white-text"
      >
        <DisableOverflowScrolling />
        Continuar
      </Button>

      <QueryLink
        to={`/cadastro-${getNextRegStep(pathname)}`}
        className="alt_skip_link"
      >
        Não quero receber ofertas da loja mais próxima do meu endereço
      </QueryLink>

    </form>
  )
}