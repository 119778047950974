import React, { useState, useLayoutEffect } from 'react'

import { elementTouch } from '@assets/functions/elementTouch'

//this component disables overflow scrolling in some pages with forms
//to use it place in any Button in form

export function DisableOverflowScrolling() {

  const [button, setButton] = useState<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    if (button) {
      elementTouch(button)
    }
  }, [button])

  return (
    <div style={{ display: 'none' }}
      ref={e => {
        setButton(e)
      }} />
  )
}