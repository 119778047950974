import React, { FC, useEffect, useMemo, useCallback } from "react"

import {
  useParams,
} from "react-router-dom"

import { QueryLink } from '@/parts'

/* Data */
import { useAppDispatch, useAppSelector } from "@/app/hooks"

/* Form components */
import {
  NameForm,
  EmailForm,
  PhoneForm,
  Webpush,
  CategoryForm,
  ProfessionForm,
  AddressForm,
  BirthdayForm,
  GenderForm,
  RegistrationComplete,
  RegistrationIncomplete
} from './forms'

import "./style.sass"

import {
  regStepEnabled,
  getRegPhases,

} from "@/utils/internal functions"

import {
  updateCustomer,
  setPropzMediaStateParameter,
} from "@/features/propzMedia/propzMediaSlice"

import {
  RegistrationPhase,
  Breadcrumb,
} from '@assets/types'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

import {
  setupABTest,
} from "@assets/utils"

import {
  pdc_local_storage,
} from '@assets/constants/localStorage'

type Breadcrumbs = Breadcrumb[]

export const RegistrationCard: FC = () => {

  const dispatch = useAppDispatch()

  let { phase } = useParams<{
    phase: RegistrationPhase
  }>()

  const customer = useTrackedStore().app.customer()

  const registrationPhases = useMemo(() => getRegPhases(), [customer])

  const question = useMemo(() => {
    return {
      nome: window.userIsFisicalPerson ? 'Como podemos te chamar?' : 'Qual a razão social da sua empresa?',
      email: window.userIsFisicalPerson ? 'Qual é seu e-mail?' : 'Qual é o e-mail da sua empresa?',
      celular: window.userIsFisicalPerson ? `Qual é seu celular?` : `Qual é o celular da sua empresa?`,
      'aceite-webpush': '',
      category: 'Com que categoria você trabalha?',
      profession: 'Qual é sua profissão?',
      endereco: window.userIsFisicalPerson ? 'Qual o endereço de onde você mora?' : 'Qual o endereço da sua empresa?',
      'data-nascimento': 'Qual é sua data de nascimento?',
      genero: 'Com qual gênero você se identifica?',
      atualizado: 'Informações salvas com sucesso!',
      completo: '',
    }[phase]
  }, [phase])

  const currentPhaseIndex = useMemo(() => registrationPhases.indexOf(phase), [registrationPhases, phase])

  const back = useMemo(() => {

    return (currentPhaseIndex && currentPhaseIndex < registrationPhases.length - 1)
      ? registrationPhases[currentPhaseIndex - 1] : ''
  }, [registrationPhases, currentPhaseIndex])

  const forward = useMemo(() => (
    (currentPhaseIndex < registrationPhases.length - 1)
      ? registrationPhases[currentPhaseIndex + 1] : ''
  ), [registrationPhases, currentPhaseIndex])

  const formComponent = useMemo(() => {
    return {
      nome: <NameForm />,
      email: <EmailForm />,
      celular: <PhoneForm />,
      'aceite-webpush': <Webpush />,
      category: <CategoryForm />,
      profession: <ProfessionForm />,
      endereco: <AddressForm />,
      'data-nascimento': <BirthdayForm />,
      genero: <GenderForm />,
      atualizado: <RegistrationIncomplete />,
      completo: <RegistrationComplete />
    }[phase]
  }, [phase, forward])

  const breadcrumbs = useMemo<Breadcrumbs>(() =>
    [
      'nome',
      'email',
      ...regStepEnabled('category'),
      ...regStepEnabled('profession'),
      ...regStepEnabled('endereco'),
      ...(
        //additional forms for fisical person
        window.userIsFisicalPerson ? [
          ...regStepEnabled('data-nascimento'),
          ...regStepEnabled('genero')
        ] : []
      ),
    ]
    , [])

  const breadcrumbsNames: {
    nome: string;
    email: string;
    category: string;
    profession: string;
    endereco: string;
    'data-nascimento': string;
    genero: string;
  } = useMemo(() => (
    {
      nome: 'Identificação',
      email: 'Contato',
      category: 'Categoria',
      profession: 'Profissão',
      endereco: 'Endereço',
      'data-nascimento': 'Nascimento',
      genero: 'Gênero',
    }
  ), [])

  useEffect(() => {

    try {
      if (!customer.registeredIn) {
        dispatch(updateCustomer({ registeredIn: "SITE" }))
      }
    } catch (e) {
      console.log('failed to update registeredIn=SITE : ', e)
    }

  }, [phase])

  const link_active = useCallback((breadcrumb: Breadcrumb, i: number) => {
    return phase == breadcrumb
      || (
        !breadcrumbs.includes(phase as Breadcrumb)
        && registrationPhases.indexOf(phase) < registrationPhases.indexOf(breadcrumbs[i + 1])
        && registrationPhases.indexOf(phase) > registrationPhases.indexOf(breadcrumb)
      )

  }, [registrationPhases, phase, breadcrumbs])

  const past_link = useCallback((breadcrumb: Breadcrumb, i: number) => {
    return !link_active(breadcrumb, i) && registrationPhases.indexOf(breadcrumb) < registrationPhases.indexOf(phase)

  }, [link_active, registrationPhases, phase])

  const notFinalStep = useMemo(() => phase != 'atualizado' && phase != 'completo', [phase])

  useEffect(() => {
    //this is to remove uncatchable oferflow in registration flow
    setTimeout(() => {
      if ('ontouchstart' in window || 'ontouchend' in document) {
        let b = document.querySelector('.registration-wrapper .registration-card button')
        b?.dispatchEvent(new Event('touchstart', { bubbles: true }))
        b?.dispatchEvent(new Event('touchend', { bubbles: true }))
      }
    }, 700)
  }, [phase])

  const notAcceptedLgpdTerms = useMemo(() =>
    phase == 'nome'
    && localStorage.getItem('lgpdCheckboxChecked') != 'true'
    , [phase])

  const showSkipStepButton = useMemo(() =>
    phase == 'nome'
      ? localStorage.getItem('lgpdCheckboxChecked') == 'true'
      : !['endereco', 'data-nascimento', 'genero', 'aceite-webpush'].includes(phase)
    , [phase])

  const showPrevStepButton = useMemo(() =>
    !['aceite-webpush'].includes(phase)
    , [phase])

  useEffect(() => {
    //console.log('[RegistrationCard] customerId : ', customer?.customerId)
    if (customer?.customerId) {
      if (
        ![
          'nome',
          'email',
          'celular',
        ].includes(phase)
        && !customer.mobilePhone
        && !customer.homeEmail
      ) {
        dispatch(setPropzMediaStateParameter({ showEmailSkippedPopup: true }))
      }
    }
  }, [phase, customer])

  return (
    <div className="registration-wrapper">
      <div className={`registration-card ${window.parameters.glob_LGPDFlow ? 'lgpdflow' : 'nonlgpdflow'}`}>

        {
          notFinalStep ?
            <>
              <div className="card-header">
                Complete seu cadastro
              </div>

              <div className="breadcrumbs">
                {
                  breadcrumbs.map((breadcrumb, i) =>
                    <>
                      <QueryLink
                        key={`breadcrumb-${breadcrumb}-${i}`}
                        to={`/cadastro-${breadcrumb}`}
                        className={() =>
                          `link ${link_active(breadcrumb, i) ? "link-active" : ''} ${past_link(breadcrumb, i) ? 'past-link' : ''}`
                        }
                        onClick={e => {
                          if (notAcceptedLgpdTerms) {
                            e.preventDefault()
                          }
                        }}
                        style={{
                          ...notAcceptedLgpdTerms ? {
                            cursor: 'default'
                          } : {}
                        }}
                      >
                        {breadcrumbsNames[breadcrumb]}
                      </QueryLink>
                      {
                        i == breadcrumbs.length - 1
                          ?
                          '' :
                          <div className="divider"></div>
                      }
                    </>
                  )
                }
              </div>

              <div className="navigation">

                <div className="left">
                  {
                    back?.length
                      && showPrevStepButton ?
                      <QueryLink
                        to={`/cadastro-${back}`}
                        className="back navlink"
                      >
                        Voltar
                      </QueryLink>
                      : ''
                  }
                </div>

                <div className="right">
                  {
                    forward?.length
                      && showSkipStepButton
                      ?
                      <QueryLink
                        to={`/cadastro-${forward}`}
                        className="forward navlink"
                      >
                        Pular etapa
                      </QueryLink>
                      : ''
                  }
                </div>
              </div>
            </>
            : <></>
        }

        {
          notFinalStep ?
            <div className="underscore"></div>
            : <></>
        }

        {
          question ?
            <div className={`question roll_in_from_right_animation`}>
              {question}
            </div>
            : <></>
        }

        {
          phase == 'atualizado'
          &&
          <div className="subheader roll_in_from_right_animation">
            Volte mais tarde para completar o cadastro e ganhar mais ofertas!
          </div>
        }

        {/* Form part */}
        <div className="form">
          {formComponent}
        </div>

        {/* Mobile breadcrumbs */}
        {
          notFinalStep ?
            <>
              <div className="mobile-breadcrumbs">

                {
                  breadcrumbs.map((breadcrumb, i) =>
                    <QueryLink
                      key={`mobile-breadcrumb-${breadcrumb}-${i}`}
                      to={`/cadastro-${breadcrumb}`}
                      className={() =>
                        `link ${link_active(breadcrumb, i) ? "link-active" : ''}`
                      }
                      onClick={e => {
                        if (notAcceptedLgpdTerms) {
                          e.preventDefault()
                        }
                      }}
                      style={{
                        ...notAcceptedLgpdTerms ? {
                          cursor: 'default'
                        } : {}
                      }}
                    />
                  )
                }

              </div>

              <div className="bottom_line">
                {
                  `Você está atualizando o cadastro do ${window.userIsFisicalPerson ? 'CPF' : 'CNPJ'} ${window.userIsFisicalPerson ? `${customer?.customerId?.slice(0, -3).replace(/[0-9]/g, '*')}${customer?.customerId?.slice(-3)}` : `${customer?.customerId?.slice(0, 3)}${customer.customerId?.slice(3, -2).replace(/[0-9]/g, '*')}${customer?.customerId?.slice(-2)}`}`
                }
              </div>
            </>
            : ''
        }

        <img src={window.parameters.glob_programLogo} alt={window.parameters.glob_programName} className="reg_logo" />

      </div>

    </div>
  );
};
