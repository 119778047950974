import { FC, useCallback, } from "react"

import { Modal } from "react-materialize"

import { StoresSearch } from '@assets/components/StoresSearch'

import './style.sass'

import {
  closeSVG,
} from '@/assets/icons'

import {
  Icon,
} from '@/parts'

import {
  actions,
  useTrackedStore,
  useStore,
} from "@assets/store"

export const StoresSearchPopup: FC = () => {

  const showStoresSearchPopup = useTrackedStore().app.showStoresSearchPopup()

  const stores = useStore().app.stores()

  const close = useCallback(() => {
    actions.app.showStoresSearchPopup(false)
  }, [])

  return (
    <Modal
      actions={[]}
      bottomSheet
      id="StoresSearchPopup"
      open={showStoresSearchPopup}
      options={{
        dismissible: true,
        endingTop: "10%",
        inDuration: 250,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: "4%",
        onCloseEnd: close,
      }}
    >
      <Icon image={closeSVG} color='#393939' className='closeModal_icon' onClick={close} />

      <StoresSearch
        header="Encontre nossas lojas"
        stores={stores}
        className='StoresSearchPopup_store_search'
        placeholder='Busque por CEP, endereço ou cidade'
      />

    </Modal>
  );
};