import { FC, useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import { Button } from "react-materialize"

import { cancelRequestDeleteData } from "@/features/propzMedia/propzMediaSlice"

import {
  FullPageCard
} from '@/ui components'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

import { ReactComponent as CancelledAccountSVG } from './cancelled_account.svg'

import './style.sass'

export const DeleteRequestPage: FC = () => {

  const dispatch = useAppDispatch()

  const { search } = useLocation()

  const history = useHistory()

  const customer = useTrackedStore().app.customer()

  const renewAcc = () => {
    dispatch(cancelRequestDeleteData())
  }

  useEffect(() => {
    console.log(customer)
    if (!customer?.deleteRequestDate) {
      history.push(`/${search}`)
    }
  }, [customer])

  return (
    <FullPageCard className="deleteRequestDate_card">

      <CancelledAccountSVG />

      <div className="header">
        Sentiremos sua falta
      </div>

      <div className="info">
        Sua conta será excluída permanentemente em até 15 dias. Caso mude de ideia, você tem 7 dias para cancelar esta solicitação.
      </div>

      <Button flat node="button" waves="light" className="text_primary_background primary_background common_text_button common_button_font_size common_button_border_radius common_button_shadow"
        onClick={renewAcc}
      >
        Quero continuar recebendo ofertas
      </Button>

    </FullPageCard>
  )
}
