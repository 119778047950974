import { Offer } from '@assets/types'
import { createStore } from 'zustand-x'

type persistedAppStore = {
  // email to send password recovery
  email: string,
  customer: any,
  existingUser: boolean, // editing existing user
  formViewState: boolean,// false - edit, true - view
  showOfferConditionsPopup: boolean,
  offerConditionsItem: Offer | null,
  offersList: Offer[],
  newCustomerId: string,
}

export const persistedAppStore = createStore('app')({
  email: "",
  customer: null,
  existingUser: false,
  formViewState: false,
  showOfferConditionsPopup: false,
  offerConditionsItem: null,
  offersList: [],
  newCustomerId: '',
} as persistedAppStore, {
  persist: {
    enabled: true,
    name: 'user'
  }
})