import React, { FC, useState, useEffect, useRef, useMemo } from "react";

import { Button } from "react-materialize"

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import { Offer } from "@assets/types"

/* SVG */
import { ReactComponent as BackShadow } from './big_back_shadow.svg'

import './style.sass'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

interface Props {
  item: Offer;
}

const formatPrice = (price: number | null) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(price || 0)

const formatPercent = (percent: number | null) =>
  new Intl.NumberFormat("pt-br").format(percent || 0)

export const TopBanner: FC<Props> = ({ item }) => {

  const dispatch = useAppDispatch()

  const { glob_programName, glob_programLogo } = window.parameters

  const isMobile = 'ontouchstart' in window

  const {
    mechanic
  } = item
  /* const mechanic = 'spend_and_get_gift' */

  const usualItem: boolean = useMemo(() => !['shopping_page'].includes(mechanic), [mechanic])

  return (
    <div
      className={`banner mechanic_marketplace_banner ${item?.requiresRegisterComplete
        || [
          'cashback_percent',
          'cashback_money',
          'cashback_product',
          'cashback_product_percentage'
        ].includes(mechanic)
        ? 'secondary_background text_secondary_background' : 'primary_background text_primary_background'
        }`}
      onClick={() => {
        if (isMobile && usualItem) {
          actions.app.OfferConditionsModalItem(item)
          actions.app.OfferConditionsModalOpened(true)
        }
      }}
    >

      <BackShadow className="logo_background" />

      <img
        className="logo_img"
        src={
          [
            'spend_and_get_gift',
          ].includes(mechanic) ? item?.image || glob_programLogo : glob_programLogo
        }
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src = window.parameters.glob_programLogo
        }}
      />

      <div className="main_content">

        <div className="main_content_align" style={!usualItem ? { maxWidth: '95%' } : {}}>

          <div className="main_content_text" style={!usualItem ? { maxHeight: '90%' } : {}}>

            {
              mechanic == "spend_and_get"
              &&
              !item?.requiresRegisterComplete
              &&
              (
                <>
                  <div className="header not_requires_reg">

                    <span>
                      {'Ganhe '}
                      <span className="amount">
                        {formatPrice(item?.discountAmount)}
                      </span>
                    </span>

                    <span>
                      {' de desconto'}
                    </span>
                  </div>

                  <div className="subheader mobile_medium">
                    {'em compras acima de '}
                    {formatPrice(item?.minAmount)}
                  </div>
                </>
              )
            }

            {/* Use case #2 - Rendering “spend_and_get” promotions that require register complete  */}
            {
              mechanic == "spend_and_get"
              &&
              item?.requiresRegisterComplete
              && (
                <>
                  <div className="small_header">
                    Complete seu cadastro e ganhe
                  </div>

                  <div className="header">
                    {formatPrice(item?.discountAmount)}
                    {' de desconto'}
                  </div>

                  <div className="subheader">
                    {'em compras acima de '}
                    {formatPrice(item?.minAmount)}
                  </div>
                </>
              )
            }

            {
              mechanic == "spend_and_get_percent"
              &&
              !item?.requiresRegisterComplete
              && (
                <>
                  <div className="header not_requires_reg">

                    <span>
                      {'Ganhe '}
                      <span className="amount">
                        {formatPercent(item?.discountPercent)}%
                      </span>
                    </span>

                    <span>
                      {' de desconto'}
                    </span>
                  </div>

                  <div className="subheader mobile_medium">
                    {'em compras acima de '}
                    {formatPrice(item?.minAmount)}
                  </div>
                </>
              )
            }

            {
              mechanic == "spend_and_get_percent"
              &&
              item?.requiresRegisterComplete
              && (
                <>
                  <div className="small_header">
                    Complete seu cadastro e ganhe
                  </div>

                  <div className="header">
                    {formatPercent(item?.discountPercent)}
                    {'% de desconto'}
                  </div>

                  <div className="subheader">
                    {'em compras acima de '}
                    {formatPrice(item?.minAmount)}
                  </div>
                </>
              )
            }

            {
              mechanic == "cashback_percent" && (
                <>
                  <div className="small_header thin">
                    {'Compre acima de '}
                    {formatPrice(item?.minAmount)}
                    {' e receba'}
                  </div>

                  <div className="header cashback_header">

                    {formatPercent(item?.discountPercent)}
                    {'% do seu dinheiro de volta'}
                  </div>

                  <div className="subheader smaller">
                    para usar em nossas lojas
                  </div>
                </>
              )
            }

            {/* Use case #6 - Rendering shpping page banner */}
            {
              mechanic == "shopping_page" ? (
                <>
                  <div className="small_header thin" style={{ marginTop: 0 }}>
                    Seu dinheiro de volta!
                  </div>

                  <div className="shopping_header">
                    {'Compre nas melhores marcas e receba até '}
                    {formatPercent(item?.discountPercent)}
                    {'% de cashback'}
                  </div>

                  <div className="subheader smaller">
                    {'para usar em suas compras '} {glob_programName}
                  </div>
                </>
              ) : ''
            }

            {
              mechanic == "cashback_money" && (
                <>
                  <div className="small_header thin">
                    {'Compre acima de '}
                    {formatPrice(item?.minAmount)}
                    {' e receba'}
                  </div>

                  <div className="header cashback_header cashback_money">
                    {formatPrice(item?.discountAmount)}
                    {' do seu dinheiro de volta'}
                  </div>

                  <div className="subheader smaller">
                    para usar em nossas lojas
                  </div>
                </>
              )
            }

            {
              mechanic == "cashback_product" && (
                <>
                  <div className="small_header thin">
                    {'Compre acima de '}
                    {formatPrice(item?.minAmount)}
                    {' e receba'}
                  </div>

                  <div className="header cashback_header">
                    {formatPrice(item?.discountAmount)}
                    {' de volta'}
                  </div>

                  <div className="subheader smaller">
                    para usar em nossas lojas
                  </div>
                </>
              )
            }

            {
              mechanic == "cashback_product_percentage" && (
                <>
                  <div className="small_header thin">
                    {'Compre acima de '}
                    {formatPrice(item?.minAmount)}
                    {' e receba'}
                  </div>

                  <div className="header cashback_header">

                    {formatPercent(item?.discountPercent)}
                    {'% do seu dinheiro de volta'}
                  </div>

                  <div className="subheader smaller">
                    para usar em nossas lojas
                  </div>
                </>
              )
            }

            {
              mechanic == "spend_and_get_gift" ?
                <>
                  <div className="header not_requires_reg">

                    <span>
                      Ganhe uma
                    </span>

                    <span>
                      {item?.description ?? ''}
                    </span>
                  </div>

                  <div className="subheader mobile_medium">
                    {'nas compras acima de '}
                    {formatPrice(item?.minAmount)}
                  </div>
                </> : ''
            }

          </div>

          {
            item?.paymentMethod &&
              item?.paymentMethod == window.parameters.glob_cardPaymentId
              ?
              <div className="seal_text">
                Oferta válida somente para compras no cartão da loja
              </div>
              : ''
          }

          {
            usualItem
            &&
            <Button
              flat
              waves="light"
              className="conditions-button"

              onClick={() => {
                actions.app.OfferConditionsModalItem(item)
                actions.app.OfferConditionsModalOpened(true)
              }}
            >
              Clique aqui para conferir as condições da oferta
            </Button>
          }
        </div>

      </div>

    </div >
  );
};
