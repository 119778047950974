import { FC, useEffect, useMemo, useState, createRef, useCallback, forwardRef } from "react"
import { NavLink, useLocation, NavLinkProps } from "react-router-dom"

export const QueryLink: FC<NavLinkProps> = forwardRef(({ children, to, ...props }, ref) => {

  let { search } = useLocation()
  //console.log('[QueryLink] ref : ', ref)

  return (
    <NavLink to={to + search} {...props} ref={ref}>
      {children}
    </NavLink>
  )
})
