import React, { FC, } from "react"

import {
  Offer,
} from "@assets/types"

import './style.sass'

import { PromotionDiscountTag } from '@assets/components/PromotionDiscountTag'

import { PromotionImageWithFallback } from '@assets/components/PromotionImageWithFallback'

import { PromotionPrices } from '@assets/components/PromotionPrices'

import { PromotionTypeTag } from '@assets/components/PromotionTypeTag'

import { PromotionSeals } from '@assets/components/PromotionSeals'

import { CashbackSeal } from '@assets/components/CashbackSeal'

import { isCashbackProduct } from '@assets/functions/isCashbackProduct'

import { ReactComponent as CloseSvg } from '@assets/icons/close.svg'

import { pdcStore } from "@assets/store/pdcStore"

interface Props {
  promotion?: Offer;
  close: () => void;
}

export const PromotionInfoForPopup: FC<Props> = ({ promotion, close }) => {


  const current_promotion_sku = pdcStore.use.get_current_promotion_sku(promotion?.id)

  return (
    <div className="promotion_info_for_popup"

    >
      <div className="tags_and_seals">
        <div className="tags">

          <PromotionDiscountTag promotion={promotion} className="tag secondary_background text_secondary_background" />

          <PromotionTypeTag primary className="tag" promotion={promotion} />
        </div>

        <PromotionSeals promotion={promotion}>
          {
            (
              isCashbackProduct(promotion) ? <CashbackSeal promotion={promotion} /> : []
            )
          }
        </PromotionSeals>

      </div>

      <PromotionImageWithFallback
        promotion={promotion}
        className="promotion_image"
      />

      <CloseSvg className="close_popup" onClick={close} />

      <div className={`promotion_information`}>

        <div
          className={`product-name`}
          title={current_promotion_sku?.name || promotion?.description || promotion?.propertiesMap?.description}
        >
          {current_promotion_sku?.name || promotion?.description || promotion?.propertiesMap?.description}
        </div>

        <PromotionPrices promotion={promotion} className="prices" />

      </div>

    </div>
  )
}
