import { FC, useCallback, useEffect, useMemo, useState } from "react"

import { useMediaQuery } from 'react-responsive'

import { Button } from "react-materialize"

import { Icon } from "@/parts"

import './style.sass'

import closeSVG from '@/assets/icons/close.svg'

interface Props {
  icon: any,
  header: string,
  subheader: string,
  className?: any,
  close: any,
  opened: any,
  action: any,
}

export const BaloonPopup: FC<Props> = ({ icon, header, subheader, action, opened, close, className }) => {

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const [popup, setPopup] = useState(null)

  const popupRef = useCallback(node => {
    if (node !== null) {
      setPopup(node)
    }
  }, [])

  const handler = useCallback((e: Event) => {
    if (popup && !e.composedPath().includes(popup)) {
      close()
    }
  }, [popup])

  useEffect(() => {
    if (opened === 'opened' && isMobile && popup) {

      document.addEventListener('click', handler, { once: true })
      document.addEventListener('touchstart', handler, { once: true })

    }
    return () => {
      if (opened === 'opened' && isMobile) {
        document.removeEventListener('click', handler)
        document.removeEventListener('touchstart', handler)
      }
    }
  }, [opened, isMobile, handler, popup])

  return (
    <div
      className={`BaloonPopup ${opened} ${className}`}
      ref={popupRef}
    >
      <div className="BaloonPopup_content">

        <div className="icon" style={{ backgroundImage: `url(${icon})` }} />

        <div className="text">
          <div className="head_text">
            {header}
          </div>
          <div className="subhead_text">
            {subheader}
          </div>
        </div>

      </div>

      <Button flat node="button" waves="light" className="baloon_popup_big_button common_text_button common_button_border_radius common_button_shadow primary_color_text"
      onClick={(e) => {

        if (typeof e?.preventDefault === 'function') e?.preventDefault()

        if (typeof e?.stopPropagation === 'function') e?.stopPropagation()

        action()
      }}
      >
        Ative agora mesmo!
      </Button>

      <Icon className="close" image={closeSVG}
        onClick={() => {
          close()
        }} />

    </div>
  )
}