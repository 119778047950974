import React, { FC, useEffect, useRef, useState, useMemo, } from 'react'

import {
  useLocation,
  useParams,
  useHistory
} from "react-router-dom"

import { Helmet } from "react-helmet"

import InputMask from "react-input-mask"

/* Form components */
import { useFormik } from 'formik'
import * as yup from 'yup'

import { Button } from "react-materialize"

import { QueryLink } from '@/parts'

import { DisableOverflowScrolling } from '@assets/components/DisableOverflowScrolling'

/* Data */
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import {
  updateCustomer,
} from "@/features/propzMedia/propzMediaSlice"

import { parseDate } from '@assets/utils'

/* Date picker  */
import DatePicker, { registerLocale } from "react-datepicker"
import ptBR from "date-fns/locale/pt-BR"
import { formatISO, compareAsc } from 'date-fns'

import { useMediaQuery } from 'react-responsive'

import "./style.sass"

import {
  getNextRegStep
} from '@/utils/internal functions'

import {
  RegistrationPhase
} from '@assets/types'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

let eighteenYearsAgo = new Date()
eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18)

export const BirthdayForm: FC = () => {

  const history = useHistory()

  const dispatch = useAppDispatch()

  const {
    search,
    pathname,
  } = useLocation()

  let { phase } = useParams<{
    phase: RegistrationPhase
  }>()

  const isNotMobile = useMediaQuery({ query: '(min-width: 767.9px)' }) ? 'input-outlined' : ''

  const customer = useTrackedStore().app.customer()

  let validationSchema = yup.object({
    //@ts-ignore
    date: yup
      .string()
      .required("Insira uma data de nascimento válida")
      .typeError("Insira uma data de nascimento válida")
      .test({
        message: "Insira uma data de nascimento válida",
        test: v => {
          //@ts-ignore
          return !!v && v != 0
        }
      })
      .test({
        message: "Você deve ser maior de 18 anos para se cadastrar",
        test: v => {
          return compareAsc(new Date(v), eighteenYearsAgo) != 1
        }
      }),
  })

  const formik = useFormik({
    initialValues: {
      date: customer?.dateOfBirth ? parseDate(customer?.dateOfBirth) : 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (value) => {

      document.body.style.cursor = 'wait'

      dispatch(updateCustomer({
        dateOfBirth: new Date(formatISO(value.date as Date, { representation: 'date' })).toISOString(),
      }))
        .finally(() => {
          document.body.style.cursor = 'default'
          formik.setSubmitting(false);
          history.push(`/cadastro-${getNextRegStep(pathname)}${search}`)
        });
    },
  });

  const pickerRef = useRef<DatePicker>()

  const [raw, set_raw] = useState<string | undefined>()

  const [value, set_value] = useState<Date | null>(null)

  useEffect(() => {
    if (
      (
        (
          raw?.length == 10
          || raw === undefined
        )
        && value
      )
      || (
        !raw?.length
        && raw !== undefined
        && !value
      )
    ) {
      formik.setFieldValue('date', value)
    }
  }, [
    raw,
    value,
  ])

  return (
    <form onSubmit={formik.handleSubmit} className='roll_in_from_bottom_animation' >
      <Helmet>
        <title>Informe sua data de nascimento</title>
        <meta name="description" content="Solicitação da data de nascimento." />
      </Helmet>
      
      {/* Date */}
      <div className={`input-field hidden_calendar`} id="DatePicker">
        {/* @ts-ignore */}
        <DatePicker
          ref={pickerRef}
          customInput={
            <InputMask
              mask="99/99/9999"
              maskChar={null}
              type="tel"
              inputMode="numeric"
            />
          }
          dateFormat="dd/MM/yyyy"
          selected={formik.values.date || null}
          
          onChangeRaw={(e) => {

            set_raw(e.target?.value)

            if (e.target?.value?.length == 10) {
              pickerRef.current?.setOpen(false)
            } else {
              pickerRef.current?.setOpen(true)
            }
          }}

          onChange={v => set_value(v)}

          onBlur={() => {
            formik.setFieldTouched('date', true, true)
          }}
          placeholderText="DD/MM/AAAA"
          className={`${isNotMobile} ${formik.touched.date && Boolean(formik.errors.date) && 'invalid' || ''}`}
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={2022}
          scrollableYearDropdown
          locale={ptBR}
          minDate={new Date("1900/01/01")}
          maxDate={new Date()}
          openToDate={formik.values.date || new Date("1993/09/28")}
          popperModifiers={[
            {
              name: 'arrow',
              options: {
                padding: ({ popper, reference, placement }) => ({
                  right: Math.min(popper.width, reference.width) - 24,
                }),
              },
            },
          ]}
          disabled={!!customer.dateOfBirth}
        />
        <span className="helper-text">
          {formik.errors.date || ' '}
        </span>
      </div>

      {/* Submit */}
      <Button
        flat
        //@ts-ignore
        type="submit"
        disabled={formik.isSubmitting || !formik.isValid}
        waves="light" className="white-text"
      >
        <DisableOverflowScrolling />
        Continuar
      </Button>

      <QueryLink
        to={`/cadastro-${getNextRegStep(pathname)}`}
        className="alt_skip_link"
      >
        Não quero receber descontos exclusivos no meu aniversário
      </QueryLink>
    </form>
  )
}