import React, { FC, createRef, useState, useEffect, useCallback, useMemo, } from 'react'

import {
  useLocation,
  useParams,
  useHistory
} from "react-router-dom"

import { useAppSelector } from "@/app/hooks"

import "./style.sass"

import {
  Offer,
} from "@assets/types"

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

import {
  TopBanner,
  PromoteMarketplaceBanner,
  RemindersBanner,
  AppCTABanner,
} from '@/components'

import {
  ImageBanner
} from './ImageBanner'

import { generateUuid } from '@/utils'

import {
  supportsBannerImage,
} from '@/utils/internal functions'

import {
  checkStorageListAndStore,
} from '@assets/utils'
import { pdcStore } from '@assets/store/pdcStore'
import { useApp } from '@assets/hooks/useApp'

export type CarouselProps = {
  list?: Offer[] | undefined | null,
}

const stop_events = ['mousedown', 'touchstart', 'touchend', 'touchcancel', 'touchmove', 'dragstart']

let scrollInt: any = null

let carouselStopped = false

let carousel: any

const bannerDataObject = (el: HTMLDivElement | undefined): {
  bannerPosition?: string | number;
  bannerTitle?: string | null;
  bannerId?: string;
  bannerName?: string;
  bannerImageUrl?: string;
  bannerDestinationUrl?: string;
} => {
  return el?.dataset ? {
    ...Object.fromEntries(
      Object.entries(el.dataset)
        .filter(
          entry => entry[0].includes('banner')
        )
    ),

    ...el?.querySelector('.main_content_text')?.textContent ? {
      bannerTitle: el?.querySelector('.main_content_text')?.textContent,
    } : {}
  } : {}
}

export const TopCarousel: FC<CarouselProps> = ({ list }) => {

  const offersPage = document.querySelector('.offers_page')

  const history = useHistory()

  const { search, pathname } = useLocation()

  const fullCarouselRef = createRef<HTMLDivElement>()

  const {
    reminders,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const StoreSelectModalOpened = pdcStore.use.StoreSelectModalOpened()

  const showSplashScreenPopup = pdcStore.use.showSplashScreenPopup()

  const marketplaceEnable = pdcStore.use.marketplaceEnable()

  const customer = useTrackedStore().app.customer()

  const registrationRedirectLink = useTrackedStore().app.registrationRedirectLink()

  const showRecentlyActivatedPromotionsModal = pdcStore.use.openRecentlyActivatedPromotionsModal()

  const switchSlide = (direction: string) => {
    if (carousel) carousel[direction]()
  }

  const stopCarousel = () => {
    clearInterval(scrollInt)

    carouselStopped = true

    stop_events.forEach(e => fullCarouselRef?.current?.removeEventListener(e, stopCarousel))
  }

  //refs to main cards price to track observation
  const [bannerRefs, setBannerRefs] = useState<Array<HTMLDivElement | null>>([])

  const [show_banner_app_cta] = useApp('banner')

  const carouselDeps = [list, window.parameters.bannersList, marketplaceEnable, reminders, window.offersGroup?.[0]?.banner, window.parameters.virtualBanners, window.showcaseProps, window.parameters.glob_banner_app_cta, show_banner_app_cta]

  const carouselCondition = useMemo(() => (
    window.inIframe ?
      window.parameters.virtualBanners?.length
      :
      (
        window.showcaseProps?.bannerUrlImage
        || list?.length
        || window.parameters.bannersList?.length
        || marketplaceEnable
        || reminders?.length
        || window.offersGroup?.[0]?.banner
        || (
          window.parameters.glob_banner_app_cta
          &&
          show_banner_app_cta
        )
      )
  ), [...carouselDeps])

  const carouselOptions = {
    dist: 0,
    duration: 50,
    fullWidth: true,
    indicators: true,
    padding: 0,
    onCycleTo: () => {
      if (carousel?.pressed || carousel?.dragged) {
        stopCarousel()
      }
    }
  }

  const [carouselNode, setCarouselNode] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    //carousel initialization
    //console.log('[carousel initialization] rerun ', id, carousel, carouselNode)
    if (
      carouselNode
      && carouselCondition
    ) {
      //console.log('[carousel initialization] inside condition rerun ')

      if (carousel) {
        carousel.$indicators[0].remove()
        carousel.destroy()
      }

      clearInterval(scrollInt)

      carousel = window.M.Carousel.init(carouselNode, carouselOptions)

      if (carousel?.hasMultipleSlides && !carouselStopped) {

        scrollInt = setInterval(() => switchSlide('next'), window.parameters.top_carousel_interval || 1200)

        stop_events.forEach(e => fullCarouselRef?.current?.addEventListener(e, stopCarousel))

      }
    }

    return () => {
      if (carousel) {
        carousel.$indicators[0].remove()
        carousel.destroy()
      }
      clearInterval(scrollInt)
    }
  }, [carouselNode, bannerRefs])

  const observedTargets: HTMLElement[] = []

  const [intersectingBanner, setIntersectingBanner] = useState<null | Element>(null)

  const bannerObserver = useMemo(() => {
    return carouselNode && window.IntersectionObserver ? new window.IntersectionObserver((entries) => {
      entries.forEach(entry => {

        if (
          entry.isIntersecting
            && bannerDataObject(entry?.target as HTMLDivElement)?.bannerPosition == 1 ? true :
            (
              entry.boundingClientRect?.width > 100
              && entry.boundingClientRect?.height > 50
            )
        ) {
          setIntersectingBanner(entry.target)
        }
      })
    }, {
      root: offersPage,
      threshold: 0.9
    }) : null
  }, [carouselNode])

  useEffect(() => {
    //for when some of overlaying popup is opened
    if (
      !(
        StoreSelectModalOpened
        || showSplashScreenPopup
        || showRecentlyActivatedPromotionsModal
      )
      && intersectingBanner
    ) {

      const { dataset: { id } } = intersectingBanner as HTMLDivElement

      if (id && !checkStorageListAndStore(sessionStorage, 'offersViewed', id)) {

        if (id == 'banner-app-cta') {
          window.dataLayer.push({
            event: 'pwa_install_banner_view'
          })

        } else {
          window.dataLayer.push({
            event: 'banner_view',
            ...bannerDataObject(intersectingBanner as HTMLDivElement)
          })
        }
      }

      bannerObserver?.unobserve(intersectingBanner)

      setIntersectingBanner(null)
    }
  }, [StoreSelectModalOpened, showSplashScreenPopup, showRecentlyActivatedPromotionsModal, intersectingBanner])

  useEffect(() => {
    if (bannerObserver) {
      bannerRefs.forEach((el) => {

        if (el && !observedTargets.includes(el)) {

          observedTargets.push(el)

          bannerObserver?.observe(el)
        }
      })
    }
  }, [bannerRefs, bannerObserver])

  useEffect(() => {
    return function cleanup() {
      bannerObserver?.disconnect()
      observedTargets.length = 0
    }
  }, [])

  const saveBannerRef = useCallback((e: HTMLDivElement) => {
    setBannerRefs(bannerRefs => [...bannerRefs, e])
  }, [])

  const carouselElement = useMemo(() => {

    let position = 0

    const bannerCount = () => {
      position++;
      return position
    }

    return carouselCondition ? (
      <div className="tickets-carousel-container" onClick={stopCarousel}>
        <div
          ref={e => setCarouselNode(e)}
          className='carousel carousel-slider'
          onClick={stopCarousel}
        >

          {
            window.parameters.glob_banner_app_cta && show_banner_app_cta ?
              <div
                className="carousel-item banner_clickable"
                data-banner-position={bannerCount()}
                data-id={`banner-app-cta`}
                ref={saveBannerRef}

                onClick={(e) => {
                  stopCarousel()
                  //@ts-ignore
                }}>
                <AppCTABanner />
              </div>
              : ''
          }

          {
            window.showcaseProps?.bannerUrlImage ?
              <div
                data-banner-position={bannerCount()}

                data-banner-id={window.showcaseProps?.theme ?? ''}

                data-banner-name={window.showcaseProps?.name ?? ''}

                data-banner-image-url={window.showcaseProps?.bannerUrlImage}

                data-id={window.showcaseProps?.theme}

                ref={saveBannerRef}

                className={`carousel-item banner_item`}

                style={{ backgroundImage: `url(${window.showcaseProps?.bannerUrlImage})` }}

                onClick={(e) => {
                  stopCarousel()
                  //@ts-ignore
                  let el = (e.target?.className ?? '').includes('carousel-item') ? e.target : e.target.closest('.carousel-item')

                  window.dataLayer.push({
                    event: 'banner_click',
                    ...bannerDataObject(el),
                  })
                }}>
              </div>
              : <></>
          }

          {
            window.inIframe ?
              window.parameters.virtualBanners?.map(banner =>
                <div
                  data-banner-position={bannerCount()}

                  data-banner-name={`banner-virtual`}

                  {...banner.link ? { 'data-banner-destination-url': banner.link } : {}}

                  data-id={banner.image}

                  ref={saveBannerRef}

                  className={`carousel-item banner_item ${banner.link ? 'banner_clickable' : ''}`}
                  style={{ backgroundImage: `url(${banner.image})` }}
                  onClick={e => {
                    stopCarousel()
                    //@ts-ignore
                    let el = (e.target?.className ?? '')?.includes('carousel-item') ? e.target : e.target.closest('.carousel-item')

                    window.dataLayer.push({
                      event: 'banner_click',
                      ...bannerDataObject(el),
                    })
                  }}>

                  {
                    banner.link
                      ?
                      <a href={banner.link} className="banner_link" target='_blank'></a>
                      : ''
                  }
                </div>
              ) :
              <>
                {
                  window.offersGroup?.[0]?.banner ?
                    <div
                      data-banner-position={bannerCount()}

                      data-banner-name={`banner-offer-group-${window.offersGroup?.[0]?.group}`}

                      data-id={window.offersGroup?.[0]?.banner}

                      ref={saveBannerRef}

                      className={`carousel-item banner_item`}
                      style={{ backgroundImage: `url(${window.assetsCdnPrefix}${window.offersGroup?.[0]?.banner})` }}
                      onClick={(e) => {
                        stopCarousel()
                        //@ts-ignore
                        let el = (e.target?.className ?? '')?.includes('carousel-item') ? e.target : e.target.closest('.carousel-item')

                        window.dataLayer.push({
                          event: 'banner_click',
                          ...bannerDataObject(el),
                        })
                      }}>
                    </div>
                    : ''
                }

                {
                  reminders?.length ?
                    <div
                      className="carousel-item banner_clickable"
                      data-banner-position={bannerCount()}

                      data-banner-name={`banner-reminders`}

                      data-banner-destination-url={`/lembretes${search}`}

                      data-id={`banner-reminders`}

                      ref={saveBannerRef}

                      onClick={(e) => {
                        stopCarousel()
                        //@ts-ignore
                        let el = (e.target?.className || '')?.includes && (e.target?.className || '')?.includes('carousel-item') ? e.target : e.target.closest('.carousel-item')

                        window.dataLayer.push({
                          event: 'banner_click',
                          ...bannerDataObject(el),
                        })

                        history.push(`/lembretes${search}`)
                      }}>
                      <RemindersBanner />
                    </div>
                    : ''
                }

                {
                  list?.map(item =>
                    supportsBannerImage(item.mechanic) ?
                      <ImageBanner
                        item={item}

                        bannerCount={bannerCount()}

                        bannerRef={saveBannerRef}

                        onclick_handler={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                          stopCarousel()
                          //@ts-ignore
                          let el = (e.target?.className ?? '')?.includes('carousel-item') ? e.target : e.target.closest('.carousel-item')

                          window.dataLayer.push({
                            event: 'banner_click',
                            ...bannerDataObject(el),
                          })

                          if (
                            item?.requiresRegisterComplete
                            && !customer?.isRegisterComplete
                          ) {
                            history.push(
                              registrationRedirectLink || '/')
                          } else {
                            actions.app.OfferConditionsModalItem(item)
                            actions.app.OfferConditionsModalOpened(true)
                          }

                        }}

                      />
                      :
                      <div
                        data-banner-position={bannerCount()}

                        data-banner-name={`banner-offer-${item.id || ''}`}

                        data-id={item.id}

                        ref={saveBannerRef}

                        className="carousel-item"
                        onClick={e => {
                          stopCarousel()

                          //@ts-ignore
                          let el = (e.target?.className ?? '')?.includes('carousel-item') ? e.target : e.target.closest('.carousel-item')

                          window.dataLayer.push({
                            event: 'banner_click',
                            ...bannerDataObject(el),
                          })
                        }}>
                        <TopBanner item={item} key={item.id} />
                      </div>
                  )
                }

                {
                  window.parameters.bannersList?.map(banner =>
                    <div
                      className={`carousel-item banner_item ${banner.link ? 'banner_clickable' : ''}`}
                      style={{ backgroundImage: `url(${banner.image})` }}

                      data-banner-position={bannerCount()}

                      data-banner-name={`banner-list-item`}

                      {...banner.link ? { 'data-banner-destination-url': banner.link } : {}}

                      data-id={banner.image}

                      ref={saveBannerRef}

                      onClick={e => {
                        stopCarousel()
                        //@ts-ignore
                        let el = (e.target?.className ?? '')?.includes('carousel-item') ? e.target : e.target.closest('.carousel-item')

                        window.dataLayer.push({
                          event: 'banner_click',
                          ...bannerDataObject(el),
                        })
                      }}>

                      {
                        banner.link
                          ?
                          <a href={banner.link} className="banner_link" target='_blank'></a>
                          : ''
                      }

                    </div>
                  )
                }

                {
                  marketplaceEnable &&
                  <div
                    className="carousel-item"

                    data-banner-position={bannerCount()}

                    data-banner-name={`banner-marketplace`}

                    data-banner-destination-url={`/shopping?utm_source=portal-do-cliente&utm_medium=banner-home&utm_campaign=cta-mktplace`}

                    data-id='banner-marketplace'

                    ref={saveBannerRef}

                    onClick={e => {
                      stopCarousel()
                      //@ts-ignore
                      let el = (e.target?.className ?? '')?.includes('carousel-item') ? e.target : e.target.closest('.carousel-item')

                      window.dataLayer.push({
                        event: 'banner_click',
                        ...bannerDataObject(el),
                      })
                    }}
                  >
                    <PromoteMarketplaceBanner />
                  </div>
                }
              </>
          }

        </div>
      </div>
    ) : ''
  }, [...carouselDeps, carouselCondition])

  return (
    <>
      {
        carouselCondition
          ?
          <div className="top-carousel-container">
            <div className='banner_carousel scale_in_animation' ref={fullCarouselRef} onClick={stopCarousel}>
              {
                carousel?.hasMultipleSlides
                  ?
                  <a
                    className={`btn-flat left`}
                    onClick={() => { switchSlide('prev'); stopCarousel() }}>
                    <i className="material-icons">chevron_left</i>
                  </a>
                  : ''
              }

              {carouselElement}

              {
                carousel?.hasMultipleSlides
                  ?
                  <a
                    className={`btn-flat right`}
                    onClick={() => { switchSlide('next'); stopCarousel() }}>
                    <i className="material-icons">chevron_right</i>
                  </a>
                  : ''
              }
            </div>
          </div>
          : ''
      }
    </>
  )
}