import { iOSVersion } from "@assets/utils/iOSVersion"

//true == notifications are not available on this device
export const notificationsNotAvailable = () => {
  const iOSV = iOSVersion()

  return (
    !window.navigator?.permissions?.query
    || !window.Notification?.requestPermission
    || typeof window?.Notification?.requestPermission != 'function'
    || (
      iOSV && (
        //iOS 16.4 supports push notifications
        iOSV.major < 16 ? true : iOSV.minor < 4
      )
      && !(
        //@ts-ignore
        navigator.standalone
        || window.matchMedia('(display-mode: standalone)')?.matches
      )
    )
  )
}