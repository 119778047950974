import React, { FC } from "react"

import { useLocation } from "react-router-dom"

import svg from '@/assets/icons/whatsapp.svg'

import './style.sass'

export const ContactService: FC = () => {
  
  const {pathname} = useLocation()

  return (
    <>
      {
        (
          window.parameters.contact_service == 'WhatsApp'
          || window.parameters.contact_service == 'Whatsapp'
          || window.parameters.contact_service == 'whatsapp'
        )
          && window.parameters.glob_retailerWhatsapp.replace(/\D/g, '')
          && !pathname.includes('cadastro')
          ?
          <a className="whatsapp_link" target="_blank" href={`https://wa.me/${window.parameters.glob_retailerWhatsapp.replace(/\D/g, '')}${window.parameters.standard_whatsapp_message ? `?text=${encodeURIComponent(window.parameters.standard_whatsapp_message)}` : ''}`}>
            <img src={svg} alt="whatsapp" />
          </a>
          : <></>
      }
    </>
  )
}
