
//time in milliseconds after each popup closes

export const orderedPopupTimings = {
  haveNotStarted: 0,
  StoreSelectModal: 20 * 1000,
  RecentlyActivatedPromotionsModal: 15 * 1000,
  SplashscreenAppCTA: 15 * 1000,
  ContinueRegistrationModal: 15 * 1000,
  RemindersPopup: 15 * 1000,
  MarketplacePopup: 15 * 1000,
} as const

//used for dev purposes
/* export const orderedPopupTimings = {
  haveNotStarted: 0,
  StoreSelectModal: 0,
  RecentlyActivatedPromotionsModal: 0,
  SplashscreenAppCTA: 0,
  ContinueRegistrationModal: 0,
  RemindersPopup: 0,
  MarketplacePopup: 0,
} as const */