import React, { FC } from 'react'

import {
  useLocation,
  useHistory
} from "react-router-dom"

import { Helmet } from "react-helmet"

/* Form components */
import { useFormik } from 'formik'
import * as yup from 'yup'

import { Button, Select } from "react-materialize"

import { useMediaQuery } from 'react-responsive'

import { DisableOverflowScrolling } from '@assets/components/DisableOverflowScrolling'

/* Data */
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import {
  updateCustomer,
} from "@/features/propzMedia/propzMediaSlice"

import {
  remove_select_touch_end,
  getNextRegStep,
} from "@/utils/internal functions"

import { QueryLink } from '@/parts'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

import './index.sass'

export const GenderForm: FC = () => {

  const history = useHistory()

  const dispatch = useAppDispatch()

  const {
    search,
    pathname,
  } = useLocation()

  const isNotMobile = useMediaQuery({ query: '(min-width: 768.1px)' }) ? 'input-outlined' : ''

  const customer = useTrackedStore().app.customer()

  let validationSchema = yup.object({
    gender: yup
      .string()
      .min(1, 'Selecione uma opção para prosseguir')
      .required('Selecione uma opção para prosseguir'),
  })

  const formik = useFormik({
    initialValues: {
      gender: customer?.gender || '',
    },
    validationSchema: validationSchema,
    //validateOnChange: false,
    onSubmit: async (value) => {

      document.body.style.cursor = 'wait';

      let props = {
        gender: value.gender
      }

      dispatch(updateCustomer(props))
        .finally(() => {
          document.body.style.cursor = 'default'
          formik.setSubmitting(false)
          history.push(`/cadastro-${getNextRegStep(pathname)}${search}`)
        })
    },
  })

  return (

    <form onSubmit={formik.handleSubmit} className='roll_in_from_bottom_animation gender_form' >
      <Helmet>
        <title>Informe o gênero que se identifica</title>
        <meta name="description" content="Solicitação do gênero." />
      </Helmet>

      {/* gender */}
      <Select
        name="gender"
        id="gender"
        label="Selecione seu gênero"

        multiple={false}
        options={{
          classes: `${isNotMobile} ${formik.touched.gender && Boolean(formik.errors.gender) && 'invalid' || ''}`,
          dropdownOptions: {
            //@ts-ignore
            alignment: 'top left',
            autoTrigger: true,
            closeOnClick: true,
            constrainWidth: true,
            coverTrigger: false,
            hover: false,
            inDuration: 150,
            onCloseStart: () => {
              document.getElementById('gender')?.parentElement?.classList.remove('expanded')
            },
            //onCloseEnd: null,
            //onOpenEnd: null,
            onOpenStart: (e) => {
              remove_select_touch_end(e)
              document.getElementById('gender')?.parentElement?.classList.add('expanded')
            },
            outDuration: 250
          }
        }}

        value={formik.values.gender}

        onBlur={formik.handleBlur}
        onChange={e => {
          e.preventDefault()
          e.stopPropagation()

          remove_select_touch_end(e.target)

          formik.setFieldTouched('gender')
          formik.handleChange(e)
        }}

        error={formik.errors.gender || ' '}

        disabled={formik.isSubmitting}
      >
        <option value="" disabled selected>Selecione seu gênero</option>

        {[
          {
            name: "Feminino",
            val: 'F'
          }, {
            name: "Masculino",
            val: 'M'
          }, {
            name: "Outro",
            val: 'O'
          }, {
            name: "Prefiro não dizer",
            val: 'P'
          }].map((state, i) =>
            <option key={`${state.name}-${i}`} value={state.val}>
              {state.name}
            </option>
          )}
      </Select>

      {/* Submit */}
      <Button
        flat
        //@ts-ignore
        type="submit"
        disabled={formik.isSubmitting || !formik.isValid}
        waves="light" className="white-text"
      >
        <DisableOverflowScrolling />
        Continuar
      </Button>

      <QueryLink
        to={`/cadastro-${getNextRegStep(pathname)}`}
        className="alt_skip_link"
      >
        Não quero informar este dado
      </QueryLink>

    </form>
  )
}