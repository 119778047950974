import React, { FC, } from "react"

import { RawReminder, Reminder } from "@assets/types"

import {

} from "@/features/propzMedia/propzMediaSlice"

import './style.sass'

interface Props {
  reminder: Reminder | RawReminder;
}

import { formatDuration } from 'date-fns'
import ptBR from "date-fns/locale/pt-BR"

import fallback from './fallback_product.png'

export const ReminderCard: FC<Props> = ({ reminder }) => {

  //console.log('reminder : ', reminder)

  return (
    <div className="reminder_card">

      {
        window.parameters.reminders_supermarket ?
          '' :
          <div className="card_header">
            {
              reminder.remainingDays > 0
                ?
                `Acaba em ${formatDuration({ days: reminder.remainingDays }, { locale: ptBR })}!`
                :
                'Seu medicamento acabou?'
            }
          </div>
      }

      <div className="card_content">

        <img src={reminder.data?.urlImage1 || (window.parameters.reminders_supermarket ? window.parameters.glob_programLogo : fallback)} onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src = window.parameters.reminders_supermarket ? window.parameters.glob_programLogo : fallback
        }} />

        <div className="description">
          {reminder.data?.name}
        </div>

      </div>

    </div>
  );
};
