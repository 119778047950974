import {
  sessionParameter,
} from '@assets/utils'

import { getApiRoot } from '@assets/functions/getApiRoot'

import {
  actions,
} from '@assets/store'
import { Customer } from '@assets/types'

export const v2_cxp_get_customer = async (): Promise<Customer | null> => {

  actions.app.update_dataLoading({ customer: true })

  const response = await fetch(`${getApiRoot()}/v2/cxp/customer?${sessionParameter()}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
  })

  if (response.status != 200) {
    console.log('[v2_cxp_get_customer] error : ', response)

    actions.app.update_dataLoading({ customer: false })

    return null
  }

  const json = await response.json()

  actions.app.update_dataLoading({ customer: false })

  actions.app.customer(json)

  return json
}