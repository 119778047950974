import React, { FC, useState, useEffect, useMemo } from "react"
import { useLocation, useHistory } from "react-router-dom"

import { Button } from "react-materialize"

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import './style.sass'

const formatPercent = (percent: number) =>
  new Intl.NumberFormat("pt-br").format(percent);

export const PromoteMarketplaceBanner: FC = () => {

  let history = useHistory()

  const { glob_programName } = window.parameters

  const { advertiser } = useAppSelector(
    (state) => state.propzMedia
  )

  const isMobile = 'ontouchstart' in window

  const topShops = useMemo(() => advertiser.slice(0, isMobile ? 3 : 4), [advertiser])

  function bannerClick() {
    history.push('/shopping?utm_source=portal-do-cliente&utm_medium=banner-home&utm_campaign=cta-mktplace')

    window.dataLayer.push({
      event: "Click-CTA-Mktplace-Banner",
    })
  }

  return (
    <div className={`promote_marketplace_banner`} onClick={bannerClick}>

      <div className="top">
        <div className="text">
          {
            isMobile ?
              ''
              :
              'Seu dinheiro de volta! '
          }
          <b>Compre nas melhores marcas e receba cashback</b> para usar em compras {glob_programName}!
        </div>
        {
          isMobile
            ?
            ''
            :
            <Button flat node="button" >
              Eu quero!
            </Button>
        }

      </div>

      <div className="bottom">
        {
          topShops.map(shop =>
            <div className="shop">
              <div className="shop_logo"
                style={{
                  backgroundImage: `url(${shop.logoUrl})`
                }}>
              </div>

              <div className="cashback_percent">
                {shop.customerCashbackMaxPercent}% de volta
              </div>
            </div>
          )
        }

        {
          isMobile
            ?
            <Button flat node="button" className="primary_background text_primary_background" >
              Eu quero!
            </Button>
            :
            ''
        }
      </div>

    </div>
  );
};
