

import React, { FC, useEffect, useState, useCallback } from "react"

import { Preloader, PreloaderSize } from "react-materialize"

import "./style.sass"

interface Props {
  className?: string;
  size?: PreloaderSize;
}

export const LoadingCircle: FC<Props> = ({ className, size, ...rest }) => {

  return (
    <Preloader
      active
      flashing={false}
      size={`${size ?? "big"}`}
      className={`loading_circle ${className ?? ''}`}
    />
  );
};