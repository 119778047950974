import React, { FC, useState, useEffect, useRef, useMemo, useCallback, } from "react"

import {
  promotionWithQRCode,
} from '@assets/utils'

import {
  Offer,
} from "@assets/types"

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

interface Props {
  promotion?: Offer;
  [x: string]: any,
}

const primary_class = 'primary_background text_primary_background'

const secondary_class = 'secondary_background text_secondary_background'

export const PromotionTypeTag: FC<Props> = ({ promotion, className, primary, ...rest }) => {

  const customer = useTrackedStore().app.customer()

  const [qrLeftTag, setQrLeftTag] = useState(false)

  const mechanic = promotion?.mechanic || promotion?.propertiesMap?.mechanic

  const withQRCode = useMemo(() => promotionWithQRCode(promotion), [])

  useEffect(() => {
    if (withQRCode && promotion?.type == "PERSONALIZED") {
      let dateDiff = Math.abs(new Date().getTime() - new Date(promotion?.activationDate).getTime())
      // < 2mins
      if (dateDiff < 120000) {
        setQrLeftTag(true);
        setTimeout(() => {
          setQrLeftTag(false)
        }, dateDiff)
      }
    }
  }, [])

  const [localClass, set_localClass] = useState('')

  const [content, set_content] = useState('')

  useEffect(() => {
    set_content('')
    if (
      promotion?.requiresRegisterComplete
      && window.currentTemplate == 'portal-do-cliente'
    ) {
      set_content(customer?.isRegisterComplete
        ?
        'COMPLETOU E GANHOU'
        :
        'COMPLETE E GANHE')

      set_localClass(primary_class)

    } else {
      if (promotion?.tags?.includes('offerbait')) {
        set_content('OFERTA DESBLOQUEADA')

        set_localClass(primary ? primary_class : secondary_class)

      } else {
        if (promotion?.type == "PERSONALIZED"
          &&
          mechanic != 'promote_regular_price') {

          set_localClass(primary_class)

          set_content(withQRCode ?
            qrLeftTag ? 'Desbloqueada por QR code' : 'exclusiva para você'
            :
            'exclusiva para você')
        }
      }
    }
  }, [
    promotion,
    customer,
    withQRCode,
    qrLeftTag,
  ])

  return (
    content ?
      <div className={`${localClass} ${className ?? ''}`} {...rest}>
        {content}
      </div> : <></>
  )
}
