import { FC, useEffect, useMemo, useState, useCallback } from "react"

import { Modal, } from "react-materialize"

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

import { PromotionInfoForPopup } from '@assets/components/PromotionInfoForPopup'

import { promotionEcomEvent } from "@assets/utils/promotionEcomEvent"

import { StoresSearch } from '@assets/components/StoresSearch'

import './style.sass'

export const CurrentOfferStoresModal: FC = () => {

  const promotion = useTrackedStore().app.CurrentOfferStoresModalPromotion()

  const stores = useTrackedStore().app.stores()

  //console.log('stores : ', stores)

  const currentOfferStoresModalOpened = useTrackedStore().app.currentOfferStoresModalOpened()

  const currentOfferStores = useMemo(() => {
    return promotion?.storeIds?.length ? stores?.filter(store => promotion?.storeIds?.includes(store.storeId))
      :
      promotion?.type == 'PERSONALIZED' ? stores : []
  }, [
    promotion,
    stores,
  ])

  const closePopup = useCallback(() => actions.app.currentOfferStoresModalOpened(false), [])

  useEffect(() => {
    if (currentOfferStoresModalOpened && promotion) {
      promotionEcomEvent({
        event: 'offer_detail_modal_view',
      }, promotion)
    }
  }, [
    currentOfferStoresModalOpened,
    promotion,
  ])

  return (
    <Modal
      actions={[]}
      bottomSheet
      id="currentStore"
      open={currentOfferStoresModalOpened}
      options={{
        dismissible: true,
        endingTop: "10%",
        inDuration: 250,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: "4%",
        onCloseEnd: closePopup,
      }}
    >
      <PromotionInfoForPopup promotion={promotion} close={closePopup} />

      <StoresSearch
        header="Veja em quais lojas esta oferta é válida"
        stores={currentOfferStores}
        className='currentStorePopup_store_search'
        placeholder="Busque por endereço ou cidade da loja"
        favoriteStoreLine='Disponível na sua loja favorita!'
        place="CurrentOfferStoresModal"
      />
    </Modal>
  )
}