/* 

type of PDFPopupState = {
  url: string; // url of document or external page
  opened: boolean; 
  title: string; //title in popup
}

To add it to the template:
  1. Add component to main file of template 
  <PDFPopup />
  
  2. Add content of script.js to index.html of template

 */

import { FC, useEffect, useState, useCallback, useMemo } from "react"

import { Modal, Button } from "react-materialize"

import { format } from 'date-fns'
import ptBR from "date-fns/locale/pt-BR"

import { LoadingCircle } from '@assets/components/LoadingCircle'

import "./style.sass"

import { generateUuid } from "@assets/utils/generateUuid"

import { ReactComponent as CloseSVG } from "&/icons/close.svg"

import { pdfStore } from '@assets/store/pdfStore'

export const PDFPopup: FC = () => {

  useEffect(()=>{
    document.addEventListener("pdfjsLoaded", function () {
      pdfStore.set.pdfjsLoaded(true)
    })
    if (window.pdfjsLoaded){
      pdfStore.set.pdfjsLoaded(true)
    }
  },[])

  const pdfjsLoaded = pdfStore.use.pdfjsLoaded()

  const state = pdfStore.use.PDFPopupState()

  const [lastModified, setLastModified] = useState<null | Date>()

  const [pdfContainerWidth, setPdfContainerWidth] = useState(0)

  const [pdfContainer, setPdfContainer] = useState<null | HTMLDivElement>(null)

  const [isLoading, setIsLoading] = useState(true)

  const doNotLoad = useMemo(() => 'ontouchstart' in window || !window.pdfjsLib, [pdfjsLoaded])

  const closePopup = useCallback(() => {
    pdfStore.set.PDFPopupState({
      url: '',
      opened: false,
      title: ``,
    })
    if (pdfContainer) {
      pdfContainer.innerHTML = ''
    }
  }, [])

  useEffect(() => {
    if (pdfjsLoaded) {
      if (
        state.opened
        && state.url
        && (
          doNotLoad
          || !state.url.includes('.pdf')
        )
      ) {
        window.open(state.url, '_blank')
        closePopup()
      }
    }
  }, [state, pdfjsLoaded])

  const pdfContainerRef = useCallback((node) => {
    setTimeout(() => {
      if (state.opened && node != null) {
        //console.log(node, node.getBoundingClientRect().width)
        setPdfContainer(node)
        setPdfContainerWidth(node.getBoundingClientRect().width)
      }
    }, 500)

  }, [state])

  useEffect(() => {
    if (pdfjsLoaded && window.pdfjsLib && !doNotLoad) {
      window.pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.0.379/pdf.worker.min.mjs'
    }
  }, [pdfjsLoaded])

  useEffect(() => {
    //popup opening and document rendering
    if (
      pdfjsLoaded
      && state.opened
      && state?.url
      && pdfContainerWidth
      && pdfContainer
      && !doNotLoad
      && state.url.includes('.pdf')
    ) {
      pdfContainer.innerHTML = '';

      setIsLoading(true)
      const id = generateUuid()
      pdfContainer.dataset.id = id
      let currentPage = 1

      //get last updated date of document
      fetch(state.url, { method: "HEAD" })
        .then(r => setLastModified(new Date(r.headers.get('Last-Modified') || '')))

      //get content of document
      const { getDocument } = window.pdfjsLib

      getDocument(state.url).promise.then((pdf: any) => {
        //console.log('pdf : ', pdf)

        const renderPage = (page: any) => {

          const canvas = document.createElement('canvas')
          canvas.dataset.id = id

          var viewport = page.getViewport({ scale: pdfContainerWidth / page.getViewport({ scale: 1 }).width })

          //console.log('dataset id ', pdfContainer.dataset.id, id, pdfContainer.dataset.id == id)

          if (pdfContainer.dataset.id == id) {
            pdfContainer?.appendChild(canvas)
          }

          var canvasContext = canvas.getContext('2d')
          canvas.height = viewport.height
          canvas.width = viewport.width
          if (pdfContainer.dataset.id == id) {
            page.render({
              canvasContext,
              viewport
            }).promise.then(function () {
              if (currentPage < pdf.numPages && pdfContainer.dataset.id == id) {
                setIsLoading(true)

                currentPage++

                pdf.getPage(currentPage).then(renderPage)
              } else {
                // all pages are loaded
                setIsLoading(false)
              }
            });
          }
        }

        if (pdfContainer.dataset.id == id) {
          pdf.getPage(currentPage).then(renderPage)
        }
      })

    }
  }, [pdfjsLoaded, state, pdfContainerWidth])

  return (
    <Modal
      id="pdf_popup"
      open={
        state.opened
        && !doNotLoad
        && state.url.includes('.pdf')
      }
      options={{
        dismissible: true,
        opacity: 0.5,
        onCloseEnd: () => closePopup(),
      }}
    >
      <CloseSVG className="close_button"
        onClick={closePopup} />

      <div className="document">

        <div className="title">
          {state.title}
        </div>

        <div className="subtitle">
          {`Última atualização: ${format(lastModified || new Date(), "d 'de' MMMM 'de' yyyy", { locale: ptBR })}`}
        </div>

        <div className="pdf_content" ref={pdfContainerRef} />

        {isLoading ? <LoadingCircle /> : ''}

      </div>

      <Button flat node="button" className="common_text_button common_button_border_radius common_button_shadow"
        onClick={closePopup}
      >
        Voltar
      </Button>

    </Modal>
  );
};