import React, { useEffect, FC } from 'react'

import {
  useLocation,
  useHistory,
  useParams,
} from "react-router-dom"

import { Helmet } from "react-helmet"

/* Data */
import { useAppDispatch, useAppSelector } from "@/app/hooks"

import {
  setPropzMediaStateParameter
} from "@/features/propzMedia/propzMediaSlice"

import {
  getNextRegStep
} from '@/utils/internal functions'

import {
  useStore,
} from '@assets/store'
import { pdcStore } from '@assets/store/pdcStore'

export const Webpush: FC = () => {

  const history = useHistory()

  const dispatch = useAppDispatch()

  const {
    search,
    pathname,
  } = useLocation()

  const customer = useStore().app.customer()

  useEffect(() => {
    //console.log('[Webpush] webPushContactFlag check : ', customer)

    if (customer?.webPushContactFlag) {
      pdcStore.set.WebpushModalOpened(false)
      history.push(`/cadastro-${getNextRegStep(pathname)}${search}`)
    } else {
      pdcStore.set.WebpushModalOpened(true)
    }

  }, [customer?.webPushContactFlag])

  return (
    <Helmet>
      <title>Aceita receber notificações web</title>
      <meta name="description" content="Solicitação para envio de notificações web." />
    </Helmet>
  )
}