import { createStore } from 'zustand-x'

type pdfStore = {
  PDFPopupState: {
    url: string,
    opened: boolean,
    title: string,
  };
  pdfjsLoaded: boolean;
}

const initialState = {
  PDFPopupState: {
    url: '',
    opened: false,
    title: '',
  },
  pdfjsLoaded: false,
} as pdfStore

export const pdfStore = createStore('pdc')(initialState)
  .extendActions((set, get, api) => ({
    reset: () => {
      Object.getOwnPropertyNames(set)
        .filter(n => ![
          'mergeState',
          'state'
        ].includes(n))
        //@ts-ignore
        .forEach(n => set[n](initialState[n]))
    },
  }))