import {
  Offer,
  ecommerceCartProduct,
  SKU_Product,
} from '@assets/types'

//@ts-ignore
import clone from 'just-clone'

import { createStore } from 'zustand-x'

import { orderedPopupTimings } from '@assets/constants/orderedPopupTimings'

type PdCStore = {
  updateFirebaseRegistration: boolean;
  //all promotions skus
  promotion_skus: {
    [promotion_id: string]: SKU_Product[];
  };
  //current selected product index in array for each promotion
  current_promotions_sku_index: {
    [promotion_id: string]: number;
  };
  ecommerceCart: ecommerceCartProduct[];
  showcaseList: Offer[] | null;
  mainPromotions: Offer[] | null;
  bannerPromotions: Offer[] | null;
  showSplashscreenAppCTA: boolean;
  pwa_event: any;
  lastClosedPopup: keyof typeof orderedPopupTimings;
  WebpushModalOpened: boolean;
  UserWantsPWA: boolean;
  TopBarAppCTAOpened: boolean;
  showFullsizeWebpushPopup: boolean;
  showSplashScreenPopup: boolean;
  showed_popups_count: number;
  StoreSelectModalOpened: boolean;
  webpushCardInteracted: boolean;
  openRecentlyActivatedPromotionsModal: boolean;
  RemindersPopupOpened: boolean;
  MarketplacePopupOpened: boolean;
  ContaSidenavOpened: boolean;
  CashbackSidenavOpened: boolean;
  marketplaceEnable: boolean;
  TermsSidenavOpened: boolean;
  showEcommerceCartErrorPopup: boolean;
  ecommerceFailures: string[];
  showEcommerceCartSuccess: boolean;
  redirectAfterEcommerceCartErrorPopup: boolean;
}

const initialState = {
  updateFirebaseRegistration: false,
  ecommerceCart: [],
  promotion_skus: {},
  current_promotions_sku_index: {},
  showcaseList: null,
  mainPromotions: null,
  bannerPromotions: null,
  showSplashscreenAppCTA: false,
  pwa_event: null,
  lastClosedPopup: 'haveNotStarted',
  WebpushModalOpened: false,
  UserWantsPWA: false,
  TopBarAppCTAOpened: false,
  showFullsizeWebpushPopup: false,
  showSplashScreenPopup: false,
  showed_popups_count: 0,
  StoreSelectModalOpened: false,
  webpushCardInteracted: false,
  openRecentlyActivatedPromotionsModal: false,
  RemindersPopupOpened: false,
  MarketplacePopupOpened: false,
  ContaSidenavOpened: false,
  CashbackSidenavOpened: false,
  marketplaceEnable: false,
  TermsSidenavOpened: false,
  showEcommerceCartErrorPopup: false,
  ecommerceFailures: [],
  showEcommerceCartSuccess: false,
  redirectAfterEcommerceCartErrorPopup: false,
} as PdCStore

export const pdcStore = createStore('pdc')(initialState)
  .extendSelectors((state, get, api) => ({
    get_promotion_skus: (promotion_id?: string) => promotion_id ? get.promotion_skus((p, n) => p[promotion_id]?.length === n[promotion_id]?.length)[promotion_id] : null,
  }))
  .extendSelectors((state, get, api) => ({
    get_current_promotion_sku_index: (promotion_id?: string) => promotion_id ? get.current_promotions_sku_index((p, n) => p[promotion_id] == n[promotion_id])[promotion_id] : 0,
  }))
  .extendSelectors((state, get, api) => ({
    get_current_promotion_sku: (promotion_id?: string) => promotion_id ?
      get.get_promotion_skus(promotion_id)?.[get.get_current_promotion_sku_index(promotion_id)]
      : null,
  }))
  .extendActions((set, get, api) => ({
    reset: () => {
      Object.getOwnPropertyNames(set)
        .filter(n => ![
          'mergeState',
          'state'
        ].includes(n))
        //@ts-ignore
        .forEach(n => set[n](initialState[n]))
    },
    triggerUpdateFirebaseRegistration: () => {
      set.updateFirebaseRegistration(!get.updateFirebaseRegistration())
    },
    sku_add: (promotionId: string, skus: SKU_Product[]) => {

      const promotion_skus = clone(get.promotion_skus())

      promotion_skus[promotionId] = skus

      set.promotion_skus(promotion_skus)
    },
    set_current_promotion_sku_index: (promotionId: string, index: number) => {

      const current_promotions_sku_index = clone(get.current_promotions_sku_index())

      current_promotions_sku_index[promotionId] = index

      set.current_promotions_sku_index(current_promotions_sku_index)
    },
  }))
  .extendActions((set, get, api) => ({
    switch_current_promotion_sku_index: (promotionId: string | undefined, next: number) => {

      if (promotionId) {
        const curr_sku_index = get.get_current_promotion_sku_index(promotionId)

        const promotion_skus = get.get_promotion_skus(promotionId)

        if (promotion_skus) {

          let target_index = curr_sku_index + next

          if (Math.abs(target_index) >= promotion_skus.length) {
            target_index = target_index % promotion_skus.length
          }

          const next_sku_index = target_index >= 0 ? target_index : promotion_skus.length + target_index

          set.set_current_promotion_sku_index(promotionId, next_sku_index)
        }
      }
    },
    increment_showed_popups_count() {
      set.showed_popups_count(get.showed_popups_count() + 1)
    },
  }))