export const isCommonPromotion = (mechanic: string) => [
  'reduced_price',
  'percent_off',
  'money_off',
  'virtual_pack',
  'promote_regular_price',
  'get_and_pay',
  'virtual_pack_percent_off',
  'virtual_pack_money_off',
  'virtual_pack_item_off',
  'virtual_pack_fixed_price',
  'get_and_pay_percent_off',
  'get_and_pay_money_off',
  'get_and_pay_item_off',
  'cashback_product_money',
  'cashback_product_percent',
]
  .includes(mechanic ?? '')