import React, { FC, } from "react"

import {
  Offer,
} from "@assets/types"

import {
  isCashbackProduct,
} from '@assets/functions'

import { ReactComponent as CashbackSealSVG } from "./cashback_seal.svg"

interface Props {
  promotion?: Offer;
  [x: string]: any,
}

export const CashbackSeal: FC<Props> = ({ promotion, className, ...rest }) => {

  return (
    isCashbackProduct(promotion) ?
      <CashbackSealSVG className={`cashback_seal ${className ?? ''}`} {...rest} />
      : <></>
  )
}
