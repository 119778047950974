export const templateBasename = (templateName: string) => {
  const { pathname } = window.location

  const indexOfTemplate = pathname.indexOf(templateName)

  const secondPart = pathname.substring(indexOfTemplate)

  const indexOfTemplateSlash = secondPart.indexOf('/')

  const basename = pathname.substring(0, indexOfTemplate) + (indexOfTemplateSlash > -1 ? secondPart.substring(0, indexOfTemplateSlash) : secondPart.substring(-1))

  // basename === /v1/apps/${templateName}${_(feature || fix)}${_dev}
  return basename
}