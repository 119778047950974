export const gaTagSend = (
  page_title: string,
  path: string,
  action_desc: string
) => {
  window.dataLayer.push({
    event: 'page_view',
    page_title: page_title,
    page_path: path,
    page_location: window.location.href,
    page_action_descL: action_desc,
  })
};

export const gaTagSendEvent = (
  event: string,
  event_category: string,
  event_label: string
) => {
  window.dataLayer.push({
    event,
    event_category,
    event_label
  })
};

export const gaTagSendRoutes = (params: string) => {
  switch (params) {
    case "cashbackPage":
      gaTagSend("Marketplace", "/shopping", "Página Marketplace, exibição de lojas parceiras");
      break;
    case "offersPage":
      gaTagSend("Suas Ofertas", "/ofertas", "Exibição das ofertas disponíveis para o shopper");
      break;
    case "lgpdPage":
      gaTagSend(
        "Menu Usuário - Configuração",
        "/configuration",
        "Open Page LGPD/configuration"
      );
      break;
    case "homePage":
      gaTagSend("Home", "/home", "Open Page Home");
      break;
    case "profilePage":
      gaTagSend("Atualize seus dados para contato", "/cadastro-dados-para-contato", "Solicitação de nome, email e telefone");
      break;
    case "finishUpdateData":
      gaTagSend("Cadastro atualizado com sucesso", "/cadastro-atualizado", "Atualização de cadastro realizada com sucesso");
      break;
    case "notAuth":
      gaTagSend("Acesso não autenticado", "/acesso-nao-autenticado", "Acessou ao Portal do Cliente através de um link não autenticado");
      break;
    case "/cadastro-nome":
      gaTagSend("Informe seu nome", "/cadastro-nome", "Solicitação do nome.")
      break
    case "/cadastro-email":
      gaTagSend("Informe seu e-mail", "/cadastro-email", "Solicitação do e-mail de contato.")
      break
    case "/cadastro-celular":
      gaTagSend("Informe seu celular", "/cadastro-celular", "Solicitação do celular de contato.")
      break
    case "/cadastro-aceite-webpush":
      gaTagSend("Aceita receber notificações web", "/cadastro-aceite-webpush", "Solicitação para envio de notificações web.")
      break
    case "/cadastro-endereco":
      gaTagSend("Informe seu endereço", "/cadastro-endereco", "Solicitação do endereço.")
      break
    case "/cadastro-data-nascimento":
      gaTagSend("Informe sua data de nascimento", "/cadastro-data-nascimento", "Solicitação da data de nascimento.")
      break
    case "/cadastro-genero":
      gaTagSend("Informe o gênero que se identifica", "/cadastro-genero", "Solicitação do gênero.")
      break
    case "/cadastro-atualizado":
      gaTagSend("Cadastro atualizado com sucesso", "/cadastro-atualizado", "Atualização de cadastro realiza com sucesso.")
      break
    case "/cadastro-completo":
      gaTagSend("Cadastro completo com sucesso", "/cadastro-completo", "Cadastro concluído realizado com sucesso.")
      break
    case "/cadastro":
      gaTagSend("Dados cadastrado", "/cadastro", "Acesso ao cadastro do usuário.")
      break
    default:
      break;
  }
}