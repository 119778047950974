import { getApiRoot } from '@assets/functions/getApiRoot'

import {
  sessionParameter,
  trackingIdsParameter,
} from '@assets/utils'

export const activateAll = async () => {
  try {
    return await fetch(`${getApiRoot()}/v1/user/promotion-activate-all?${sessionParameter()}${trackingIdsParameter()}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
    })
  } catch (e) {
    console.log('Error /v1/user/promotion-activate-all : ', e)
  }
}