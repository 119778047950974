import React, { FC, useEffect, useMemo, useCallback, useState } from "react"

import {
  useParams,
} from "react-router-dom"

import { ReactComponent as PuzzleSVG } from '@assets/icons/puzzle.svg'

import { useAsyncMemo } from 'use-async-memo'


import "./style.sass"

import { benefits } from '@assets/API/v2/benefits'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

import type { Benefits as BenefitsType } from '@assets/types'
import { LoadingCircle } from "@assets/components/LoadingCircle"


export const Benefits: FC = () => {

  let { id } = useParams<{
    id: BenefitsType
  }>()

  const [loading, set_loading] = useState(false)

  const benefit = useAsyncMemo(async () => {

    set_loading(true)

    const response = await benefits(id)

    //console.log('response : ', response)

    switch (response.status) {
      case 200:
        const text = await response.text()
        //console.log('text : ', text)
        set_loading(false)
        return { text }
        break;

      default:
        set_loading(false)
        return { error: 422 }
        break;
    }

  }, [id])

  return (
    <div className="benefits_page">

      {
        loading ?
          <LoadingCircle />
          :
          benefit ?
            benefit.text ?
              <iframe src={benefit.text}></iframe>
              :

              <div className="benefit_page_error">

                <PuzzleSVG />

                <div className="header_text">
                  Ops! Alguma coisa deu errado...
                </div>

                <div className="text">
                  {
                    benefit.error == 422 ?
                      'Esta funcionalidade está disponível apenas para clientes Pessoa Física. Se precisar de ajuda, entre em contato com nosso suporte.'
                      :
                      'Tente acessar esta página novamente mais tarde.'
                  }
                </div>

                <div className="bottom_logo" style={{ backgroundImage: `url(${window.parameters.glob_retailerLogo})` }}></div>

              </div>
            : <></>
      }

    </div>
  )
}
