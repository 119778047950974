import { FC, useEffect, useCallback, useState, } from "react"
import { useHistory, useLocation } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import {
  updateCustomer,
} from "@/features/propzMedia/propzMediaSlice"

import { Button, Modal } from "react-materialize"

import { orderedPopupTimings } from '@assets/constants/orderedPopupTimings'

import './style.sass'

import {
  Store
} from "@assets/types"

import {
  TextInputWithSearchDropdown
} from '@/parts'

import {
  useTrackedStore,
  useStore,
} from '@assets/store'
import { pdcStore } from "@assets/store/pdcStore"

export const StoreSelectModal: FC = () => {

  const { pathname } = useLocation()

  const dispatch = useAppDispatch()

  const StoreSelectModalOpened = pdcStore.use.StoreSelectModalOpened()

  const showSplashScreenPopup = pdcStore.use.showSplashScreenPopup()

  const mainPromotions = pdcStore.use.mainPromotions()

  const lastClosedPopup = pdcStore.use.lastClosedPopup()

  const stores = useStore().app.stores()

  const customer = useTrackedStore().app.customer()

  const [local_selectedStore, setLocal_selectedStore] = useState<Store | null>(null)

  const [selectedStore, setSelectedStore] = useState<Store | null | undefined>(null)

  const store_item_content = (store: Store) => store ? `${store.businessName ? `${store.businessName} - ` : ''}${store.city ? `${store.city}` : ''}${store.street ? `, ${store.street}` : ''}${store.num ? `, ${store.num}` : ''}${store.state ? `, ${store.state}` : ''}${store.zip ? `, ${store.zip}` : ''}${store.localeType ? `, ${store.localeType}` : ''}` : ''

  useEffect(() => {
    if (customer.preferredStore && stores) {
      setSelectedStore(stores.find(store => store.storeId == customer?.preferredStore))
    }
  }, [customer?.preferredStore, stores])

  useEffect(() => {
    (async () => {
      if (selectedStore && selectedStore.storeId != customer?.preferredStore) {

        await dispatch(updateCustomer({ preferredStore: selectedStore.storeId }))

        window.location.reload()
      }
    })()
  }, [selectedStore])

  const closePopup = useCallback((wait?: boolean) => {

    pdcStore.set.StoreSelectModalOpened(false)

    setTimeout(() => {
      if (
        pdcStore.get.lastClosedPopup() == 'haveNotStarted'
        && pdcStore.get.showed_popups_count() < window.parameters.allowed_popus_count
      ) {
        pdcStore.set.lastClosedPopup('StoreSelectModal')
      }
    }, wait ? orderedPopupTimings.StoreSelectModal : 0)
  }, [])

  useEffect(() => {
    if (customer?.customerId && mainPromotions) {
      //mainPromotions is null until it's loaded first time

      if (
        !customer?.preferredStore
        && !customer?.registrationStoreId
        && !mainPromotions.length
        //&& lastClosedPopup.name == 'haveNotStarted'
        && lastClosedPopup == 'haveNotStarted'
        && !window.parameters.disableStoreSelector
      ) {

        pdcStore.set.StoreSelectModalOpened(true)
        pdcStore.set.increment_showed_popups_count()

      } else {
        //if it wasn't opened manually yet
        if (!StoreSelectModalOpened) {
          closePopup()
        }
      }
    }
  }, [customer, mainPromotions])

  return (
    <Modal actions={[
    ]}

      header="Escolha a loja mais próxima de você para ver as ofertas disponíveis"
      open={
        StoreSelectModalOpened
        && !showSplashScreenPopup
        && !pathname.includes('cadastro')
        && !pathname.includes('webpush-pagina')
        && pathname == '/'
      }

      id="StoreSelectModal"
      options={{
        dismissible: true,
        preventScrolling: true,
        startingTop: "30%",
        onCloseEnd: () => closePopup(true),
      }}
    >

      <TextInputWithSearchDropdown
        placeholder="Busque por endereço ou cidade da loja"
        options={stores ?? []}
        optionRepresentation={store_item_content}
        setSelectedItem={setLocal_selectedStore}
        classnames='PreferredStoreSelect_container'
      />

      <Button flat node="button" className="primary_background text_primary_background"
        onClick={() => {
          setSelectedStore(local_selectedStore)
          closePopup(true)
        }}
      >
        Ver ofertas desta loja
      </Button>
    </Modal>
  )
}