import * as yup from 'yup'

import { error_messages } from '@assets/constants/error_messages'

import { customerIdMasks } from '@assets/constants/input_masks'

import { cpf as cpf_validator, cnpj as cnpj_validator } from 'cpf-cnpj-validator'

import {
  preparePhone,
} from '@assets/utils'

export const firstName = (fisicalPerson: boolean, messages?: {
  min: string;
  required: {
    fisical: string;
    nonfisical: string;
  }
}) => yup
  .string()
  .min(3, messages?.min ?? error_messages.firstName.min)
  .required(fisicalPerson ? messages?.required.fisical ?? error_messages.firstName.required.fisical : messages?.required.nonfisical ?? error_messages.firstName.required.nonfisical)

export const phone = (messages?: {
  required: string;
  test: string;
}) => yup
  .string()
  .required(messages?.required ?? error_messages.phone.default)
  .test({
    message: messages?.test ?? error_messages.phone.default,
    test: value => {
      let phone = preparePhone(value)
      if (phone.length != 11 || [
        "00000000000",
        "11111111111",
        "22222222222",
        "33333333333",
        "44444444444",
        "55555555555",
        "66666666666",
        "77777777777",
        "88888888888",
        "99999999999"
      ].includes(phone)) return false;

      return true
    }
  })

export const email = (messages?: {
  email: string;
  required: string;
}) => yup
  .string()
  .email(messages?.email ?? error_messages.email.default)
  .required(messages?.required ?? error_messages.email.default)

export const customerId = (setCustomerIdMask: (value: "cpf" | "cnpj") => void, messages?: {
  min: string;
  required: string;
  test: string;
}) => yup
  .string()
  .min(11, messages?.min ?? error_messages.customerId.default)
  .required(messages?.required ?? error_messages.customerId.default)
  .test({
    message: messages?.test ?? error_messages.customerId.default,
    test(value) {
      let v = value.replace(/\D/g, '')

      setCustomerIdMask(v.length > 11 ? 'cnpj' : 'cpf')

      return cpf_validator.isValid(v) || cnpj_validator.isValid(v)
    }
  })