import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"

import { QueryLink } from '@/parts'

import { Modal, Button } from "react-materialize"

import "./style.sass"

import { ReactComponent as BackShadow } from './back_shadow.svg'

import { humanImg } from "@/assets/Splashscreen people"

import { useMediaQuery } from 'react-responsive'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

import {
  checkStorageAndStore,
  formatPercent,
  itemIsStored,
} from '@assets/utils'

import { pdcStore } from "@assets/store/pdcStore"

const formatPrice = (price: number | null) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price || 0)

export const SplashScreenPopup: FC = () => {

  const { pathname, search } = useLocation()

  const trackingIds = useMemo(() => new URLSearchParams(search).get("trackingIds"), [search])

  const showSplashScreenPopup = pdcStore.use.showSplashScreenPopup()

  const showcaseList = pdcStore.use.showcaseList()

  const customer = useTrackedStore().app.customer()

  const showOfferConditions = useTrackedStore().app.OfferConditionsModalOpened()

  const item = useMemo(() => {
    if (customer.customerId && showcaseList) {

      return showcaseList
        .find(item =>
          item?.mechanic

          && item?.id

          && [
            'cashback_percent',
            'cashback_money',
            'cashback',
            'spend_and_get',
            'spend_and_get_percent',
          ]
            .includes(item?.mechanic)

          && (
            item.requiresRegisterComplete ?
              customer.isRegisterComplete : true
          )

          && !itemIsStored(localStorage, 'wasInSplashScreenPopup', item?.id)
        )
    }
  }, [customer, showcaseList])

  const percentMech = useMemo(() => item?.mechanic?.includes('percent'), [item])

  const spendAndGetMech = useMemo(() => item?.mechanic?.includes('spend_and_get'), [item])

  const diffMech = useMemo(() => {
    return [
      'cashback_percent',
      'spend_and_get',
      'spend_and_get_percent',
    ].includes(item?.mechanic ?? '')
  }, [item])

  useEffect(() => {
    if (
      item
      && item?.id
      && !pathname.includes('/cadastro')
      && !pathname.includes('webpush-pagina')
      && !checkStorageAndStore(sessionStorage, 'showedSplashScreen', 'true')
    ) {
      pdcStore.set.showSplashScreenPopup(true)
      pdcStore.set.increment_showed_popups_count()
    }
  }, [item, trackingIds, pathname])

  const mainButton = useMemo(() =>
    showcaseList ?
      <QueryLink to='/' className='main_button_container'>
        <Button flat modal="close" node="button" className="white common_text_button common_button_shadow main_button primary_color_text">
          {
            showcaseList.filter(i => !i.activated).length ?
              'Desbloquear minhas ofertas'
              :
              'Ver minhas ofertas'
          }
        </Button>
      </QueryLink> : <></>
    , [showcaseList])

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const [discountFontSize, setDiscountFontSize] = useState(0)

  const discountRef = useCallback((node) => {
    if (node != null && node?.firstChild) {
      setTimeout(() => {
        const { width } = node.getBoundingClientRect()

        let range = document.createRange()

        range.selectNodeContents(node?.firstChild)

        let textRect = range.getBoundingClientRect()

        if (textRect.width > width) {
          const length = node.textContent.length

          setDiscountFontSize(Math.min(width / length, window.innerWidth * .11))
        }
      }, 100)
    }
  }, [item])

  const buttons = useMemo(() => {
    return <>
      {mainButton}

      <Button
        flat
        waves="light"
        className="conditions_button text_primary_background"

        onClick={() => {
          actions.app.OfferConditionsModalItem(item)
          actions.app.OfferConditionsModalOpened(true)
        }}
      >
        Confira as condições da oferta
      </Button>
    </>
  }, [item])

  const human_img = useMemo(() => humanImg(), [])

  return (
    <Modal
      id="splash_screen_popup"
      open={
        showSplashScreenPopup
      }
      options={{
        dismissible: true,
        opacity: 0.5,
        onCloseEnd: () => pdcStore.set.showSplashScreenPopup(false)
      }}
      //@ts-ignore
      className={`${'ticket'}`}
      onClick={() => {
        if (showOfferConditions) {
          actions.app.OfferConditionsModalOpened(false)
        }
      }}
    >

      <Button
        flat
        className="close-button text_primary_background"

        onClick={() => pdcStore.set.showSplashScreenPopup(false)}
      >
        <i className="material-icons">close</i>
      </Button>

      <>

        <BackShadow className="human_background" />

        {
          isMobile ? '' : <img src={human_img} alt="human" className="desktop_human" />
        }

        <div className="popup_content text_primary_background">

          <div className="popup_content_header">
            <div className={`small_text ${spendAndGetMech && isMobile ? 'spend_and_get' : ''}`}>
              {
                spendAndGetMech && isMobile ?
                  'Ganhe agora'
                  :
                  'Seu dinheiro'
              }
            </div>

            <div className={`big_text ${item?.mechanic}`}>
              {
                spendAndGetMech && isMobile ?

                  item?.mechanic == 'spend_and_get_percent' ?

                    `${formatPercent(item?.discountPercent || 0)}% OFF`
                    :
                    formatPrice(item?.discountAmount || 0)

                  :
                  'DE VOLTA!'
              }
            </div>

          </div>

          <div className="main_content">

            {
              isMobile ? <img src={human_img} alt="human" className="mobile_human" /> : ''
            }

            <div className="small_header">
              {'Compre acima de '}
              {formatPrice(item?.minAmount || 0)}
              {' e '}<span className="enlarged">RECEBA</span>
            </div>

            <div
              className={`discount_size ${item?.mechanic} ${percentMech ? 'percent' : ''}`}
              ref={discountRef}
              style={discountFontSize ? {
                fontSize: `${discountFontSize}px`,
                lineHeight: `${discountFontSize}px`,
              } : {}}
            >
              {
                item?.discountPercent ?
                  `${formatPercent(item?.discountPercent || 0)}%`
                  : item?.discountAmount ?
                    formatPrice(item?.discountAmount || 0) : ''
              }
            </div>

            <div className={`subheader ${item?.mechanic}`}>
              do seu dinheiro de volta para usar em {diffMech ? 'sua' : ''} {item?.mechanic == 'cashback_percent' ? 'próxima' : ''} compra{diffMech ? '' : 's'} no <span className="enlarged">{window.parameters.glob_programName}</span>
            </div>

          </div>

          <div className="bottom_content">
            <div className="header">
              Aproveite a oferta imperdível que separamos para você!
            </div>

            {
              isMobile ?
                buttons
                :
                <div className="buttons">
                  {buttons}
                </div>
            }

          </div>
        </div>

        <div className="footer">
          <img src={window.parameters.glob_programLogo} alt={window.parameters.glob_programName} className="logo" />
        </div>
      </>
      {/*  } */}

    </Modal>
  );
};