import React, { FC, useMemo } from "react"

import { Helmet } from "react-helmet"

export const ColorStyles: FC = () => {
  const { glob_color1, glob_color2 } = window.parameters

  //primary color to rgb string like `255, 255, 255`
  const color1RGB = useMemo(() => (
    glob_color1.includes('#')
      ?
      glob_color1.length == 7
        ?
        `${parseInt(glob_color1.substring(1, 3), 16)}, ${parseInt(glob_color1.substring(3, 5), 16)}, ${parseInt(glob_color1.substring(5, 7), 16)}`
        :
        `${parseInt(glob_color1[1], 16)}, ${parseInt(glob_color1[2], 16)}, ${parseInt(glob_color1[3], 16)}`
      :
      glob_color1.substring(4).slice(0, -1)
  )
    , [glob_color1])

  //text color with background-color: color-primary
  const textColor1 = useMemo(() => {
    let rgb = color1RGB.slice(0, -1).split(', ')

    //text color
    return ( //brightness
      Math.round(((parseInt(rgb[0]) * 299) +
        (parseInt(rgb[1]) * 587) +
        (parseInt(rgb[2]) * 114)) / 1000) > 125
    ) ? '#393939' : 'white'
  }, [color1RGB])

  //secondary color to rgb string like `255, 255, 255`
  const color2RGB = useMemo(() => (
    glob_color2.includes('#')
      ?
      glob_color2.length == 7
        ?
        `${parseInt(glob_color2.substring(1, 3), 16)}, ${parseInt(glob_color2.substring(3, 5), 16)}, ${parseInt(glob_color2.substring(5, 7), 16)}`
        :
        `${parseInt(glob_color2[1], 16)}, ${parseInt(glob_color2[2], 16)}, ${parseInt(glob_color2[3], 16)}`
      :
      glob_color2.substring(4).slice(0, -1)
  )
    , [glob_color2])

  //text color with background-color: color-secondary
  const textColor2 = useMemo(() => {
    let rgb = color2RGB.slice(0, -1).split(', ')

    //text color
    return ( //brightness
      Math.round(((parseInt(rgb[0]) * 299) +
        (parseInt(rgb[1]) * 587) +
        (parseInt(rgb[2]) * 114)) / 1000) > 125
    ) ? '#393939' : 'white'
  }, [color2RGB])

  return (
    <Helmet>
      <style>
        {`
            :root {
                --oposite-color: #2c2c2c;
                --card-holder: #ffffff50;
                --bg-body: #eaeaea;
                --bg-loader: #fcfcfc;
                --line-row-logo: #cccccc;
                --color-footer: #2c2c2c;
                --bg-end-date: #d34040;
                --bg-end-date-2: #ffcece;
                --color-primary: ${glob_color1};
                --color-primary-rgb: ${color1RGB};
                --color-text-primary: ${textColor1};
                --color-secondary: ${glob_color2};
                --color-secondary-rgb: ${color2RGB};
                --color-text-secondary: ${textColor2};
                --color-tertiary: rgb(255, 0, 25);
                --color-quaternary: rgba(255, 255, 255);
                --color-quinquennial: rgb(55, 56, 150);
                --color-success: #5bb174;
                --shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
                --accent-color: #FF4663;
                --base-color: rgb(0, 0, 0);
                --base-color-secondary: rgb(100, 100, 100);
                --color-black: #393939;
                --color-call-to-action: rgb(255, 113, 113);
                --inner-height: ${window.innerHeight}px;
                }
        
                .cusWeight01.center-align {
                    color: var(--color-quinquennial);
                }
        
                p.cusWeight01,
                h5.cusWeight01 {
                    color: var(--color-quinquennial);
                    font-size: 24px;
                }
            `}
      </style>
    </Helmet>
  );
};
