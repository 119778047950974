import React, { useState, useLayoutEffect, useEffect, useCallback, } from 'react'

//@ts-ignore
import debounce from 'lodash.debounce'
import { useLog } from './useLog'

/* 
displayed - condition of wherether the element is displayed
dimension - what size to get from component

returns [ref, specified dimension size]
*/
type Size = { height: number, width: number }

export function useComponentSize(displayed = true): [(e: HTMLDivElement | null) => void, size: null | Size] {

  const [element, set_element] = useState<HTMLElement | null>(null)
  const [element_size, set_element_size] = useState<null | Size>(null)

  const recalculateSize = useCallback(debounce(() => {
    if (element) {
      const { height, width } = element.getBoundingClientRect()
      set_element_size({ height: Math.round(height), width: Math.round(width) })
    }
  }, 50), [element])

  useLayoutEffect(() => {
    if (element && displayed) {
      setTimeout(recalculateSize,
        200
      )

      window.addEventListener('resize', recalculateSize, false)
    }
    return () => window.removeEventListener("resize", recalculateSize)
  }, [element, displayed])

  return [(e: HTMLDivElement | null) => set_element(e), element_size]
}