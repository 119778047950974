
export const error_messages = {
  phone: {
    default: 'Insira um número de celular válido',
  },
  customerId: {
    default: "Insira um CPF ou CNPJ válido",
  },
  firstName: {
    min: 'não deve ser inferior a 3',
    required: {
      fisical: 'O nome é obrigatório.',
      nonfisical: 'Razão social é obrigatório ',
    }
  },
  email: {
    default: "Insira um endereço de e-mail válido",
  },
} as const