import { FC, useEffect, useMemo, useState, useRef } from "react"

import no_offers from "./no_offers.png"
import no_offers_2x from "./no_offers_2x.png"

import "./style.sass"

export const NoOffers: FC = () => {
  return (
    <div className="no_offers">

      <div className="no_offers_header">
        Identificamos que acabou de se cadastrar e ainda estamos preparando suas ofertas.
      </div>

      <img
        srcset={`${no_offers_2x} 448w, ${no_offers} 224w`}
        sizes="(min-width: 528px) 448px, (max-width: 527px) 224px"
        src={no_offers}
        alt="no offers image"
      />

      <div className="no_offers_footer">
        Informe seu CPF no caixa no momento das compras para podermos selecionar os melhores descontos para você!
      </div>
    </div>
  )
}