import { store } from '@/app/store'

import {
  updateCustomer,
} from "@/features/propzMedia/propzMediaSlice"

export const denyNotifications = () => {

  const { dispatch } = store

  dispatch(updateCustomer(
    {
      webPushContactFlag: false,
      pdcDeviceIds: [],
      pushDeviceIds: []
    }
  ))
}