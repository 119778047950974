export const parametersObjectToQueryString = (parameters: any)=>{
  return Object.getOwnPropertyNames(parameters)
  .filter(key=>!!parameters[key])
  .map(key => `${key}=${parameters[key]}`)
  .join('&')
}
//todo: use encodeURIComponent()
/* 
{
  promotion: promotionId,
  someOtherProperty: someOtherPropertyValue
} => 'promotion=promotionId&someOtherProperty=someOtherPropertyValue'
*/