import React, { FC, useMemo } from "react"

import { useAppSelector } from "@/app/hooks"

import { ReactComponent as IconSVG } from './icon.svg'

export const MarketplaceCallToAction: FC = () => {

  const {
    advertiser,
    lastVisitedMarketplace,
    lastVisitCustomerCashbackMaxPercent
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const visible = useMemo(() => {

    return (
      (
        Number(advertiser[0]?.customerCashbackMaxPercent || 0) -
        Number(lastVisitCustomerCashbackMaxPercent || localStorage.getItem('lastVisitCustomerCashbackMaxPercent'))
      ) > 0 ||
      (
        (
          new Date().getTime() -
          Number(lastVisitedMarketplace || localStorage.getItem('lastVisitedMarketplace'))
        ) > 604800000 //7days
      )
    ) ? 'visible' : ''
  }, [advertiser, lastVisitedMarketplace, lastVisitCustomerCashbackMaxPercent])

  return (
    <IconSVG className={`CallToAction ${visible}`} />
  );
};
