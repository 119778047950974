import { FC, useState, useEffect, useMemo, useCallback, } from "react"
import { useLocation, useHistory } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import {
  setPropzMediaStateParameter,
} from "@/features/propzMedia/propzMediaSlice"

import {
  NewRemindersCount,
  MarketplaceCallToAction,
} from '@/components'

import {
  LinkCountedPosition,
  QueryLink,
} from '@/parts'

/* SVG */
import { ReactComponent as CashbackSVG } from './svg/cashback.svg'
import { ReactComponent as OfertasSVG } from './svg/oferta.svg'
import { ReactComponent as PersonSVG } from './svg/person.svg'

import ReminderIcon from '@/assets/icons/reminder.png'

import { ReactComponent as IzioSVG } from '@assets/icons/izio.svg'

import { ReactComponent as CupomSVG } from '@assets/icons/cupom.svg'

import "./style.sass"
import { pdcStore } from "@assets/store/pdcStore"
import { useDebounceCallback } from "usehooks-ts"

export const FooterMobile: FC<{ icons: string[] }> = ({ icons }) => {

  let history = useHistory()

  let location = useLocation()
  let { pathname } = location

  const dispatch = useAppDispatch()

  const {
    reminders,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const CashbackSidenavOpened = pdcStore.use.CashbackSidenavOpened()
  const TermsSidenavOpened = pdcStore.use.TermsSidenavOpened()
  const ContaSidenavOpened = pdcStore.use.ContaSidenavOpened()
  const marketplaceEnable = pdcStore.use.marketplaceEnable()

  const toggleContaSidenav = useDebounceCallback(() => {
    pdcStore.set.ContaSidenavOpened(!pdcStore.get.ContaSidenavOpened())

    pdcStore.set.CashbackSidenavOpened(false)
  }, 50)

  const toggleCashbackSidenav = useDebounceCallback(() => {
    pdcStore.set.ContaSidenavOpened(false)
  }, 50)

  const remindersClick = useDebounceCallback(() => {
    pdcStore.set.CashbackSidenavOpened(false)
    pdcStore.set.ContaSidenavOpened(false)
  }, 50)

  const ofertasClick = useDebounceCallback(() => {
    remindersClick()
    history.push('/')
  }, 50)

  const footerRef = useCallback(node => {
    if (node !== null) {
      dispatch(setPropzMediaStateParameter({ FooterMobileY: node.getBoundingClientRect().y }))
    }
  }, [])

  return (
    <>
      <div className="mobile-footer" ref={footerRef}>

        <QueryLink to="/"
          className={`icon-link ${pathname == '/' && !(ContaSidenavOpened || TermsSidenavOpened || CashbackSidenavOpened) ? "icon-link-active" : ''}`}
          onClick={ofertasClick}
        >
          <OfertasSVG />
          <span className="name">
            Ofertas
          </span>
        </QueryLink>

        {marketplaceEnable ?
          <LinkCountedPosition
            link="/shopping" name="Cashback"
            setPosition={'CashbackLinkPosition'}
            callToAction={<MarketplaceCallToAction />}
            icon={<CashbackSVG />}
            onClickAction={toggleCashbackSidenav}
            place='footer'
          />
          : ''
        }

        {reminders?.length ?
          <LinkCountedPosition
            link="/lembretes" name="Lembretes"
            setPosition={'RemindersLinkPosition'}
            callToAction={<NewRemindersCount />}
            icon={<img src={ReminderIcon} />}
            onClickAction={remindersClick}
            place='footer'
          />
          : ''
        }

        {
          icons.indexOf('izio') < 2 && icons.indexOf('izio') > -1 ?
            <QueryLink to="/benefits/izio"
              className={`icon-link ${pathname.includes("/benefits/izio") && !(ContaSidenavOpened || TermsSidenavOpened || CashbackSidenavOpened) ? "icon-link-active" : ''}`}
              onClick={remindersClick}
            >
              <IzioSVG />
              <span className="name">
                Vantagens
              </span>
            </QueryLink>
            : <></>
        }

        {
          icons.indexOf('cv') < 2 && icons.indexOf('cv') > -1 ?
            <QueryLink to="/benefits/cv"
              className={`icon-link ${pathname.includes("/benefits/cv") && !(ContaSidenavOpened || TermsSidenavOpened || CashbackSidenavOpened) ? "icon-link-active" : ''}`}
              onClick={remindersClick}
            >
              <CupomSVG />
              <span className="name">
                Cupom Verde
              </span>
            </QueryLink>
            : <></>
        }

        <a
          className={`icon-link ${(ContaSidenavOpened || TermsSidenavOpened) ? 'icon-link-active' : ''}`}
          onClick={toggleContaSidenav}
        >
          <PersonSVG />
          <span className="name">
            Conta
          </span>
        </a>

      </div>
    </>
  );
};
