import { FC } from "react"

import './style.sass'

interface Props {
  image: any,
  className?: any,
  [x: string]: any
}

export const Image: FC<Props> = ({ image, className, ...rest }) => (
  <div className={`image_component ${className}`} style={{ backgroundImage: `url(${image})` }}
    {...rest} />
)
