import { Offer } from "@assets/types"
import { promotionEvent } from "@assets/utils"

type StorageFunc = (storage: Storage, key: string, value: string) => void

type StorageFuncParams = Parameters<StorageFunc>

type StorageFuncWithReturn = (...a: StorageFuncParams) => boolean

type Head<T extends any[]> = T extends [...infer Head, any] ? Head : any[];

export const getStoredItem: (...args: Head<StorageFuncParams>) => string = function () {
  const [storage, key] = arguments

  return storage.getItem(key) ?? ''
}

export const itemIsStored: StorageFuncWithReturn = function () {
  const [storage, key, value] = arguments;

  return getStoredItem(storage, key).includes(value)

}

export const storeItem: StorageFunc = function () {
  const [storage, key, value] = arguments;

  storage.setItem(key, value)
}

export const storeItemInList: StorageFunc = function () {

  const [storage, key, value] = arguments

  const stored = getStoredItem(storage, key)

  if (!itemIsStored(...arguments as unknown as StorageFuncParams)) {
    storage.setItem(key, `${stored}${stored ? ',' : ''}${value}`)
  }
}

export const checkStorageAndStore: StorageFuncWithReturn = function () {

  const stored = itemIsStored(...arguments as unknown as StorageFuncParams)

  if (!stored) {
    storeItem(...arguments as unknown as StorageFuncParams)
  }

  return stored
}

export const checkStorageListAndStore: StorageFuncWithReturn = function () {

  const stored = itemIsStored(...arguments as unknown as StorageFuncParams)

  storeItemInList(...arguments as unknown as StorageFuncParams)

  return stored
}

export const checkStorageListAndStoreAndSendPromEvent: (...a: [...StorageFuncParams,
  event: string,
  promotion: Offer | undefined,
  eventLocation?: string,
]) => void = function () {

  const [storage, key, value, event, promotion, eventLocation] = arguments

  if (!checkStorageListAndStore(storage, key, value)) {
    promotionEvent({
      event,
      ...eventLocation ? { eventLocation } : {},
    }, promotion)
  }
}

export const removeItem: (...args: Head<StorageFuncParams>) => void = function () {

  const [storage, key] = arguments

  storage.removeItem(key)
}