import { FC } from "react"

import { Button } from "react-materialize"

import { useMediaQuery } from 'react-responsive'

import {
  Image,
  Icon,
} from '@/parts'

import './style.sass'

import closeSVG from '@/assets/icons/close.svg'

interface Props {
  icon?: any,
  header: string,
  mobile_header: string,
  subheader: string,
  button_text: string,
  button_mobile_text: string,
  className?: any,
  close: any,
  [x: string]: any,
  button_action: any,
  opened: any,
  image?: any,
}

export const TopSlidingPopup: FC<Props> = ({ icon, header, mobile_header, subheader, button_text, button_mobile_text, button_action, opened, close, image, className }) => {

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <div
      id="TopSlidingPopup"
      className={`${opened} ${className ?? ''}`}
    >
      <div className="icon_wrapper">
        {
          icon ?
            <Icon className="modal_main_icon" image={icon} />
            : ''
        }
        {
          image ?
            <Image className="modal_main_icon" image={image} />
            : ''
        }
      </div>

      <div className="text">
        <div className="head_text">
          {
            isMobile
              ?
              mobile_header
              :
              header
          }
        </div>
        {
          isMobile
            ?
            <div className="subhead_text">
              {subheader}
            </div>
            :
            <></>
        }
      </div>

      <Button flat node="button" waves="light"
        onClick={button_action}
      >
        {
          isMobile
            ?
            button_mobile_text
            :
            button_text
        }
      </Button>

      <Icon className="close" image={closeSVG}
        onClick={(e: Event)=>{
          e?.preventDefault()
          e?.stopPropagation()

          close()
          }} />

    </div>
  );
};