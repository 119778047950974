import { FC } from "react"

import { RecentlyActivatedPromotionsModal } from './RecentlyActivatedPromotionsModal'
import { SplashscreenAppCTA } from './SplashscreenAppCTA'
import { ContinueRegistrationModal } from './ContinueRegistrationModal'
import { RemindersPopup } from './RemindersPopup'
import { MarketplacePopup } from './MarketplacePopup'
import { StoreSelectModal } from "./StoreSelectModal"

export const OrderedPopups: FC = () => {

  return (
    <>
      {/* popups are placed in order of appearence */}
      <StoreSelectModal />

      <RecentlyActivatedPromotionsModal />

      <SplashscreenAppCTA />

      <ContinueRegistrationModal />

      <RemindersPopup />

      <MarketplacePopup />
    </>
  );
};