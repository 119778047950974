export const formatPrice = (price: any) => Number(price || 0).toFixed(2).replace(".", ",")

function formatType(params: any) {
  let status = "";
  if (params === "USAGE") {
    status = "utilizado";
  } else if (params === "EXPIRED" || params === "EXPIRATION") {
    status = "expirado";
  }

  return status;
}

export const generateUuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const formatPriceToBr = (price: any) => {
  return (Number(price) || 0).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  })
}

export const getCookie = (name:string)=>{
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined
}