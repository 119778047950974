import {
  Offer,
} from '@assets/types'

import {
  promotionCardTopTagContent,
} from '@assets/functions'

export const promotionEvent = (eventProps: {
  event: string;
  [x: string]: any;
}, promotion: Offer | undefined) => {

  const topTag = promotionCardTopTagContent(promotion)

  window.dataLayer.push({
    ...eventProps,
    promoId: promotion?.id || promotion?.uuid,

    promoName: promotion?.description || promotion?.propertiesMap?.description,

    promoFinalPrice: promotion?.finalPrice || promotion?.propertiesMap?.finalPrice,

    promoOriginalPrice: promotion?.originalPrice || promotion?.propertiesMap?.originalPrice,

    promoDiscountAmount: promotion?.discountAmount || promotion?.propertiesMap?.discountAmount,

    promoDiscountPercent: promotion?.discountPercent || promotion?.propertiesMap?.discountPercent,

    promoMechanic: promotion?.mechanic || promotion?.propertiesMap?.mechanic,

    ...promotion?.type ? { promoPromotionType: promotion?.type } : {},

    ...promotion?.partnerId ? { promoPartnerPromotionId: promotion?.partnerId } : {},

    ...promotion?.requiresRegisterComplete ? { promoRequiresRegisterComplete: promotion?.requiresRegisterComplete } : {},

    ...promotion?.requiresIdentification ? { promoRequiresIdentification: promotion?.requiresIdentification } : {},

    ...promotion?.requiresActivation ? { promoRequiresActivation: promotion?.requiresActivation } : {},

    ...topTag ? { discountTag: topTag } : {},

    ...promotion?.storeIds ? {storeIds: promotion.storeIds} : {},
  })
}