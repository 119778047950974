import {
  Offer,
} from '@assets/types'

import { formatFloatPrice } from '@assets/utils/formatFloatPrice'

import { pdcStore } from "@assets/store/pdcStore"

export const promotionEcomData = (item: Offer, index: number, quantity: number) => {

  const props = item.propertiesMap ? item.propertiesMap : item

  const is_offer = Boolean(item.propertiesMap)

  const current_promotion_sku = pdcStore.get.get_current_promotion_sku(item?.id)

  return {
    item_id: item.id ?? item.uuid,

    item_name: item.description || item.name,

    price: formatFloatPrice(props.finalPrice),

    discount: formatFloatPrice(props.originalPrice) - formatFloatPrice(props.finalPrice),

    index,

    promotion_id: is_offer ? item.propertiesMap?.sku : item.properties?.PRODUCT_ID_INCLUSION,

    promotion_name: item.description || item.name,

    quantity,

    item_mechanic: (item.mechanic || item.propertiesMap.mechanic) ?? null,

    item_type: is_offer ? (item.propertiesMap.promotionType || item.group) ?? "no-type" : item.type,

    item_requires_activation: item.requiresActivation || false,

    item_requires_register_complete: item.requiresRegisterComplete || false,

    item_requires_identification: item.requiresIdentification || false,

    item_allocation: is_offer ? (item.category ?? 'no-allocation') : item.allocation,

    item_themes: item.themes?.join(",") || "no-themes",

    image_url: is_offer ? `${window.assetsCdnPrefix}${item?.propertiesMap?.image}` : (current_promotion_sku ? current_promotion_sku.urlImage1 : item.image),
  }
}