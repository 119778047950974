import { getApiRoot } from '@assets/functions/getApiRoot'

import { Store } from '@assets/types'

export const user_retail_stores = async (storesIds: string): Promise<Store[]> => {
  const resp = await fetch(`${getApiRoot()}/v1/user/retail-stores?storeIds=${storesIds}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
  })

  if (resp.status == 200) {
    const json = await resp.json()
    return json.items
  }

  console.log('[getStores] error response : ', resp)

  return []
}