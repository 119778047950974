import { FC, useMemo, useState, useEffect, useCallback } from "react"
import { useLocation } from "react-router-dom"

import localforage from 'localforage'

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import {
  BaloonPopup
} from '@/parts'

import {
  setPropzMediaStateParameter,
  updateCustomer,
} from "@/features/propzMedia/propzMediaSlice"

import { orderedPopupTimings } from '@assets/constants/orderedPopupTimings'

import {
  notificationsNotAvailable,
  showed_later_days_diff,
} from '@/utils/internal functions'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

import bellSVG from '@/assets/icons/bell.svg'

import { pdcStore } from "@assets/store/pdcStore"

import { useApp } from "@assets/hooks/useApp"

export const WebpushBaloonPopup: FC = () => {

  const { pathname } = useLocation()

  const dispatch = useAppDispatch()

  const {
    showWebpushBaloonPopup,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const TopBarAppCTAOpened = pdcStore.use.TopBarAppCTAOpened()

  const customer = useTrackedStore().app.customer()

  const isMobile = 'ontouchstart' in window

  const [wasShown, setWasShown] = useState(false)

  //used to disable popup flickering
  const [popupOpen, setPopupOpen] = useState(false)

  const [show_pwa_ctas] = useApp()

  useEffect(() => {
    if (
      showWebpushBaloonPopup
    ) {
      (async () => {

        const lastShowedWebpushBaloonPopup = await showed_later_days_diff('lastShowedWebpushBaloonPopup', window.parameters.baloon_webpush_period)

        if (
          !show_pwa_ctas
          //window.rawUser.webPushContactFlag is a string "0" || "1", so it's converted to number to properly convert to boolean
          && !Number(window.rawUser.webPushContactFlag)
          && !notificationsNotAvailable()
          && lastShowedWebpushBaloonPopup
          && !TopBarAppCTAOpened
        ) {

          setPopupOpen(true)
          pdcStore.set.increment_showed_popups_count()

          window.dataLayer.push({
            event: 'push_notification_footerbar_view',
          })

          localforage.setItem('lastShowedWebpushBaloonPopup', new Date().getTime())

        }
        else {
          closePopup()
        }
      })()
    }
  }, [showWebpushBaloonPopup,])

  const action = useCallback(() => {

    dispatch(updateCustomer(
      {
        webPushContactFlag: true
      }
    ))

    dispatch(setPropzMediaStateParameter({ checkNotificationsPermission: 'push_notification_footerbar_click' }))

    setWasShown(true)

    closePopup(true)

  }, [customer])

  const opened = useMemo(() =>
    !pathname.includes('cadastro')
      && !pathname.includes('webpush-pagina')
      && popupOpen
      && showWebpushBaloonPopup
      ? 'opened' : (wasShown ? 'closed' : '')
    , [pathname, showWebpushBaloonPopup, popupOpen])

  useEffect(() => { setTimeout(() => setWasShown(false), 500) }, [wasShown])

  const closePopup = useCallback((wait?: boolean) => {

    dispatch(setPropzMediaStateParameter({ showWebpushBaloonPopup: false }))

    setTimeout(() => {
      if (pdcStore.get.showed_popups_count() < window.parameters.allowed_popus_count) {
        //if can show one more -> switch last closed popup
        pdcStore.set.lastClosedPopup('ContinueRegistrationModal')
      }
    }, wait ? orderedPopupTimings.ContinueRegistrationModal : 0)
  }, [])

  return (
    <BaloonPopup
      icon={bellSVG}
      header={`Quer receber ofertas exclusivas para você? ${isMobile ? '' : 'Ative as notificações e não perca estas vantagens!'}`}
      subheader={isMobile ? `Ative as notificações e não perca estas vantagens!` : ''}
      action={action}
      opened={opened}
      close={() => {

        setWasShown(true)
        closePopup(true)
        //denyNotifications()
      }}
      className={``}
    />
  );
};