import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { useLocation } from "react-router-dom"

import { Modal, TextInput } from "react-materialize"

import { useAppSelector, useAppDispatch } from "@/app/hooks"
import { Advertiser } from "@assets/types"

import {
  setPropzMediaStateParameter,
} from "@/features/propzMedia/propzMediaSlice"

import './style.sass'

import { TopBanner } from "@/components"

import { brazilian_text_to_searchable } from "@assets/functions"

export const Cashback: FC = () => {

  const { hash } = useLocation()

  const dispatch = useAppDispatch()

  //console.log('shopping location : ', location)

  const { advertiser, transactions } = useAppSelector(
    (state) => state.propzMedia
  )

  const topBannerItem = useMemo(() => {
    //console.log('advertiser : ', advertiser)

    return {
      mechanic: 'shopping_page',
      discountPercent:
        advertiser && advertiser[0] && advertiser[0].customerCashbackMaxPercent || 10
    }
  }, [advertiser])

  const [search_query, setSearch_query] = useState('')

  let shops = useMemo(() =>
    search_query ?
      (
        advertiser.filter(shop => brazilian_text_to_searchable(shop.name).includes(search_query))
        || advertiser
      ) : advertiser
    , [search_query, advertiser])

  let [shopModal, setShopModal] = useState<Advertiser | null>(null)

  /* let shopModal = useMemo(() => {
    let shopId = localStorage.getItem('shopModal')

    localStorage.removeItem('shopModal')

    return shopId ? advertiser.find(shop => shop.advertiserId == Number(shopId)) : null

  }, [advertiser]) */

  useEffect(() => {

    if (shopModal) {

      setTimeout(() => {
        window.dataLayer.push({
          event: `Acessou ${shopModal?.name}`,
          storeName: shopModal?.name
        })

        if (shopModal?.clickUrl) {
          window.location.assign(shopModal?.clickUrl)
        }

        //setShopModal(null)

      }, 1500)
    }
  }, [shopModal])

  const searchRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (hash == '#shops_search' && searchRef.current) {
      setTimeout(() => {
        searchRef.current?.scrollIntoView({ behavior: "smooth", block: "start" })
      }, 200)

    }
  }, [hash, searchRef])

  useEffect(() => {
    //store customerCashbackMaxPercent to check in MarketplaceCallToAction
    if (advertiser[0]) {
      localStorage.setItem('lastVisitCustomerCashbackMaxPercent', `${Number(advertiser[0]?.customerCashbackMaxPercent)}`)

      dispatch(setPropzMediaStateParameter({ lastVisitCustomerCashbackMaxPercent: Number(advertiser[0]?.customerCashbackMaxPercent) }))
    }

  }, [advertiser])

  useEffect(() => {
    //store date to check in MarketplaceCallToAction

    let date = new Date().getTime()

    localStorage.setItem('lastVisitedMarketplace', `${date}`)

    dispatch(setPropzMediaStateParameter({ lastVisitedMarketplace: date }))

  }, [])

  return (
    <div className="cashback_page">

      <TopBanner item={topBannerItem} />

      <div className="search_n_shops">
        <TextInput
          id="shops_search"
          name="shops_search"
          className={`input-outlined ${!shops?.length && 'invalid' || ''}`}
          /* icon="search" */
          placeholder="Busque aqui sua marca"
          value={search_query}
          error={!shops?.length && "Nenhuma marca encontrada" || ''}
          onChange={e => setSearch_query(e.target?.value?.toLocaleLowerCase() || '')}
          ref={searchRef}
        />

        {
          !!shops?.length &&
          (
            <div className="shops">
              {
                shops?.map((shop, index) => (
                  <div className="shop card-panel z-depth-2">
                    <div className="shop_logo"
                      style={{
                        backgroundImage: `url(${shop.logoUrl || ''})`
                      }}></div>

                    <div className="cashback_percent">
                      {shop.customerCashbackMaxPercent}% de volta
                    </div>

                    <a
                      //href={window.location.href}
                      className="white-text waves-effect shop_modal_link"
                      //target="_blank"
                      rel="opener"
                      onClick={() => {
                        /* localStorage.setItem('shopModal', `${shop.advertiserId}`) */
                        setShopModal(shop)
                      }}
                    >
                      Quero aproveitar!
                    </a>

                  </div>
                ))
              }
            </div>
          )
        }
      </div>



      <Modal
        id="shop-modal"
        open={!!shopModal}
        options={{
          dismissible: false,
        }}
      >
        <div className="progress_bar"></div>

        <div className="modal_shop_logo"
          style={{
            backgroundImage: `url(${shopModal?.logoUrl})`
          }}></div>

        <div className="text_content">

          <div className="header">
            Estamos ativando seu dinheiro de volta!
          </div>

          <div className="percentage">
            <span className="percent">
              {shopModal?.customerCashbackMaxPercent}%
            </span>

            <span className="text">de volta</span>
          </div>

          <div className="line">{'Faça sua compra normalmente em '}{shopModal?.name}</div>

          <div className="subline">Em caso de dúvidas, consulte nosso FAQ</div>

        </div>

      </Modal>

    </div>
  );
};
