import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { Redirect, Route, Switch, useLocation, useHistory } from "react-router-dom"

import * as Sentry from "@sentry/react"

/* Components */
import {
  Head,
  GlobalLoader,
  Header,
  FooterMobile,
  WebPushNotification,
  FaleConoscoModal,
  CashbackModal,
  ContaSidenav,
  //TermsSidenav,
  CashbackSidenav,
  PhoneFlip,
  WebpushModal,
  DeniedNotificationsModal,
  TopBarAppCTA,
  OrderedPopups,
  EcommerceCartErrorPopup,
  SplashScreenPopup,
  WebpushTopPopup,
  ContactService,
  FullsizeWebpushPopup,
  WebpushBaloonPopup,
  StoresSearchPopup,
  DynamicStyles,
  EmailSkippedPopup,
  PhoneSkippedPopup,
} from "@/components"

import { PDFPopup } from '@assets/components/PDFPopup'

import { ColorStyles } from '@assets/components/ColorStyles'

import { OfferConditionsModal } from '@assets/components/OfferConditionsModal'

import { CurrentOfferStoresModal } from '@assets/components/CurrentOfferStoresModal'

import { TopOverlapMessagePopup } from '@assets/components/TopOverlapMessagePopup'

/* Pages */
import {
  Cashback,
  DeleteRequestPage,
  Offers,
  RegistrationCard,
  UserInfo,
  FAQ,
  WebPushPage,
  Reminders,
  Ajuda,
  Benefits,
} from "@/pages"

/* Data */
import { useAppDispatch, useAppSelector } from "@/app/hooks"

import {
  getTransactions,
  trackEvent,
  getReminders,
  setPropzMediaStateParameter,
  updateCustomer,
  fetchCustomerAdvertiser,
  activatePromotion,
  deleteCustomerProps,
} from "@/features/propzMedia/propzMediaSlice"

import {
  activateAll,
} from '@assets/API/promotion'

import { v2_cxp_get_customer } from '@assets/API/v2/v2_cxp_get_customer'

import { NotAuthenticatePage } from "@/pages/NotAuthenticate"

import { gaTagSendRoutes, gaTagSendEvent } from "@/utils/analytic"

import { useMediaQuery } from 'react-responsive'

import {
  propIsPresent,
  registrationProps,
} from '@/utils/internal functions'

import {
  isTicketPromotion,
  isCommonPromotion,
} from '@assets/functions'

import {
  actions,
  useTrackedStore,
  store,
  useStore,
} from '@assets/store'

import { animations_constants } from '@assets/constants/animations'

import '@/assets/sass/sidenavs.sass'

import {
  googleSignInSuccess,
} from '@assets/constants/events'

import {
  checkStorageAndStore,
  getStoredItem,
  storeItem,
} from "@assets/utils"

import { iOSVersion } from "@assets/utils"

import { user_retail_stores } from '@assets/API/v1/user_retail_stores'

import {
  v2_cxp_promotions,
} from '@assets/API/v2'

import { pdcStore } from "@assets/store/pdcStore"

import { Reminder } from "@assets/types"

import { useQuery } from '@assets/hooks/useQuery'
import { useIsApp } from "@assets/hooks/useIsApp"

function App() {

  const location = useLocation();
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const { search, pathname } = useLocation()

  const history = useHistory()

  const query = useQuery()

  const [download_data_link, set_download_data_link] = useState<HTMLAnchorElement | null>(null)

  useEffect(() => {

    const googleSucceeded = store.app.query()?.get(googleSignInSuccess)?.split(",")[0]

    if (googleSucceeded) {

      window.dataLayer.push({
        event: googleSignInSuccess,
      })

      query.delete(googleSignInSuccess)

      let search = query.toString()

      const { pathname } = location

      history.replace({ pathname, search })

    }
  }, [query])

  const download_data_link_ref = useCallback(node => {
    if (
      node !== null
    ) set_download_data_link(node)
  }, [search])

  useEffect(() => {
    if (
      download_data_link
      && (
        search.includes('download_data')
        || search.includes('lgpd_zipLink')
      )
      && window.rawUser.lgpd_zipLink
    )
      download_data_link.click()
  }, [download_data_link])

  const {
    rawReminders,
    reminders,
  } = useAppSelector(state => state.propzMedia)

  const ContaSidenavOpened = pdcStore.use.ContaSidenavOpened()
  const TermsSidenavOpened = pdcStore.use.TermsSidenavOpened()
  const CashbackSidenavOpened = pdcStore.use.CashbackSidenavOpened()
  const marketplaceEnable = pdcStore.use.marketplaceEnable()

  const showcaseList = pdcStore.use.showcaseList()

  const customer = useStore().app.customer()

  const dataLoading = useStore().app.dataLoading()

  const registrationRedirectLink = useTrackedStore().app.registrationRedirectLink()

  useEffect(() => {
    pdcStore.set.marketplaceEnable(Boolean(window.parameters.marketplaceEnable == 'true'
      ||
      (
        customer?.customerId
        &&
        window.parameters.marketplaceEnable.includes(
          customer?.customerId
        ))
    ))
  }, [customer])

  const dataIsLoading = useMemo(() => {

    return window.notAuth ? false :
      dataLoading.customerBalance
      || dataLoading.transactions
      || dataLoading.advertising
      || dataLoading.reminders
      || dataLoading.customer

  }, [dataLoading])

  useEffect(() => {
    if (!dataIsLoading && !window.pdcIsLoaded && window.pdcStartedLoadingTimestamp) {
      window.pdcIsLoaded = true
      window.dataLayer.push({
        event: 'finish_loading_pdc',
        eventTimestamp: new Date(),
        loadTime: (new Date().getTime() - window.pdcStartedLoadingTimestamp) / 1000
      })
      window.pdcStartedLoadingTimestamp = undefined
    }
  }, [dataIsLoading])

  useEffect(() => {

    if (!window.notAuth) {

      (async () => {

        const storeId = window.rawUser.preferredStore || window.rawUser.registrationStoreId;

        const showcaseList = await v2_cxp_promotions(window.inIframe ? {
          mechanics: window.parameters.offers_mechanics_ecommerce,
          types: window.parameters.ecommerce_offers_types,
          options: 'storeIds',
          theme: 'none',
        } : {
          mechanics: window.parameters.offers_mechanics,
          types: window.parameters.offers_types,
          options: 'storeIds',
          ...storeId ? { storeId } : {},
          theme: 'none',
        })

        window.showcaseList = window.inIframe ? showcaseList.map(i => {
          // remaining == -1 means unlimited
          i.remaining = (i.remaining < 0 ? 10000 : i.remaining);
          i.maxPerCustomer = (i.maxPerCustomer < 0 ? 10000 : i.maxPerCustomer)
          //to test Comprar button
          //i.url = 'https://google.com'

          return i
        }) : showcaseList

        console.log('showcaseList : ', window.showcaseList)

        pdcStore.set.showcaseList(window.showcaseList)

        pdcStore.set.mainPromotions(window.showcaseList.filter(item => isCommonPromotion(item?.mechanic)) || [])

        pdcStore.set.bannerPromotions(window.showcaseList?.filter(item =>
          isTicketPromotion(item?.mechanic || '')
        ))

      })();

      v2_cxp_get_customer()

      dispatch(fetchCustomerAdvertiser());

      dispatch(getTransactions())

      const reminderState = (window.rawUser?.reminderState ?? '').replace(/\s/g, '')

      if (reminderState) {
        dispatch(setPropzMediaStateParameter({
          rawReminders: reminderState
            .split(',').map(

              (reminder: string) => {
                let r = reminder.split(':')

                return {
                  productId: r[0],
                  uuid: r[1],
                  date: r[2],
                  remainingDays: Number(r[3])
                }
              }
            ).filter((r: Reminder) => r.remainingDays <= 10 && r.remainingDays >= -7)
        }))

      }

      user_retail_stores(window.storesIds.join(',')).then(stores => actions.app.stores(stores.slice().filter(store => store.active).sort((a, b) => a.businessName?.localeCompare(b.businessName))))

    }

  }, []);

  useEffect(() => {
    /* if (customer.customerId) {

     showcaseList.forEach(promotion => {
        if (
          promotion.requiresActivation
          && !promotion.activated
          && (
            promotion.requiresRegisterComplete ?
              customer.isRegisterComplete : true
          )
        ) {
          dispatch(activatePromotion({
            promotionId: promotion.id,
            ...trackingIds ? { trackingIds } : {},
          }))
        }
      })
    } */

    activateAll()
  }, [
    //customer,
    //showcaseList
  ])

  const reminderUUIDs = useMemo(() => query.get("reminder")?.split(',') || [], [])

  useEffect(() => {
    if (rawReminders.length) {

      dispatch(getReminders(
        reminderUUIDs?.length ?
          rawReminders.filter(r => reminderUUIDs.includes(r.uuid))
          : rawReminders
      ))
    }
  }, [rawReminders, reminderUUIDs])

  useEffect(() => {
    switch (pathname) {
      case "/":
        gaTagSendRoutes("offersPage");
        break;
      case "/shopping":
        console.log('sent event to shopping : ', pathname)
        gaTagSendRoutes("cashbackPage");
        break;
      case "/ofertas":
        gaTagSendRoutes("offersPage");
        break;
      case "/configuration":
        gaTagSendRoutes("lgpdPage");
        break;
      case "/cadastro-dados-para-contato":
        gaTagSendRoutes("profilePage");
        break;
      case "/cadastro-nome":
        gaTagSendRoutes("/cadastro-nome")
        break
      case "/cadastro-email":
        gaTagSendRoutes("/cadastro-email")
        break
      case "/cadastro-celular":
        gaTagSendRoutes("/cadastro-celular")
        break
      case "/cadastro-aceite-webpush":
        gaTagSendRoutes("/cadastro-aceite-webpush")
        break
      case "/cadastro-endereco":
        gaTagSendRoutes("/cadastro-endereco")
        break
      case "/cadastro-data-nascimento":
        gaTagSendRoutes("/cadastro-data-nascimento")
        break
      case "/cadastro-genero":
        gaTagSendRoutes("/cadastro-genero")
        break
      case "/cadastro-completo":
        gaTagSendRoutes("/cadastro-completo")
        break
      case "/cadastro-atualizado":
        gaTagSendRoutes("/cadastro-atualizado")
        break
      case "/cadastro":
        gaTagSendRoutes("/cadastro")
        break
      default:
        break;
    }
  }, [pathname])

  let showNavBars = useMemo(() => {
    return !pathname.includes('/cadastro-')
  }, [pathname])

  useEffect(() => {
    if (customer?.customerId) {
      Sentry.setUser({ username: customer?.customerId || undefined })
    }
  }, [customer])

  useEffect(() => {
    Sentry.setTag('ci.deploy.version', window.CI_deploy_version)
  }, [])

  const PWA_Installed_Listner = useCallback((e: Event) => {
    window.dataLayer.push({
      event: `pwa_install_${getStoredItem(sessionStorage, 'InstallPWAFromCTA') ?? ''}_success`,
      pwaTrackingId: window.pwaTrackingId,
    })
  }, [])

  const pwa_event = pdcStore.use.pwa_event()

  useEffect(() => {
    if (window.PWAInstallEvent) {
      pdcStore.set.pwa_event(window.PWAInstallEvent)
    } else {
      //@ts-ignore
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        //console.log('[PWA] onbeforeinstallprompt in App');

        window.PWAInstallEvent = e
        pdcStore.set.pwa_event(e)
      }, { once: true })
    }
  }, [])

  useEffect(() => {
    //console.log('pwa open check : ', pwa_event)
    if (
      !window.inIframe
      && pwa_event
      && !checkStorageAndStore(sessionStorage, 'TopBarAppCTAOpened', 'true')
    ) {

      //console.log('[PWA] set TopBarAppCTAOpened: true')

      pdcStore.set.TopBarAppCTAOpened(!pdcStore.get.showSplashscreenAppCTA())

      window.addEventListener('appinstalled', PWA_Installed_Listner, { once: true })
    }
  }, [pwa_event])

  useEffect(() => {
    document.addEventListener('propz_PWAEvent', (e) => {
      console.log('propz_PWAEvent : ', e)

      pdcStore.set.pwa_event(window.PWAInstallEvent)

    }, { once: true })
  }, [])

  useEffect(() => {
    if (showcaseList) {
      const event = 'total_offers'

      const total = {
        total_offers: showcaseList.length || 0 + window.offersGroup.length,
        total_common_offers: 0,
        total_ticket_offers: 0,
        total_group_offers: window.offersGroup.length,
        total_requires_register_complete: 0,
        total_requires_activation: 0,
        total_requires_identification: 0,
        total_themed_offers: 0,
      }

      showcaseList.forEach(promotion => {
        if (isCommonPromotion(promotion?.mechanic)) {
          total.total_common_offers++
        }

        if (isTicketPromotion(promotion?.mechanic)) {
          total.total_ticket_offers++
        }

        if (promotion.requiresRegisterComplete) {
          total.total_requires_register_complete++
        }

        if (promotion.requiresActivation) {
          total.total_requires_activation++
        }

        if (promotion.requiresIdentification) {
          total.total_requires_identification++
        }

        if (promotion.themes?.length) {
          total.total_themed_offers++
        }

      })

      if (!checkStorageAndStore(sessionStorage, event, JSON.stringify(total))) {
        window.dataLayer.push({
          event,
          ...total
        })
      }
    }

  }, [showcaseList])

  useEffect(() => {
    if (showcaseList) {
      if (!window.notAuth && customer?.customerId && !showcaseList.length && !window.offersGroup?.length) {
        let ss = sessionStorage.getItem('no-offers-allocated-event-sent') || ''
        if (!ss?.includes(customer.customerId)) {
          sessionStorage.setItem('no-offers-allocated-event-sent', `${ss}${ss ? ',' : ''}${customer.customerId}`)

          window.dataLayer.push({
            event: 'no-offers-allocated',
            customerId: customer.customerId
          })
        }
      }
    }
  }, [customer, showcaseList])

  useEffect(() => {
    if (customer?.customerId) {
      console.log('[App] customer: ', customer)

      console.log('[App] customerId: ', customer?.customerId)
    }
  }, [customer?.customerId])

  const registrationFlowLinks = useMemo(() => ({
    'firstName': 'nome',
    'lastName': 'nome',
    'homeEmail': 'email',
    'mobilePhone': 'celular',
    'businessCategory': 'category',
    'profession': 'profession',
    'homeZip': 'endereco',
    'homeStreet': 'endereco',
    'homeNum': 'endereco',
    'homeDistrict': 'endereco',
    'homeCity': 'endereco',
    'homeState': 'endereco',
    'dateOfBirth': 'data-nascimento',
    'gender': 'genero'
  }), [])

  useEffect(() => {
    //set registration redirect link to first missing prop
    if (customer?.customerId) {

      let firstMissingRegProp = registrationProps().find(prop => !propIsPresent(prop))

      let page = firstMissingRegProp ? registrationFlowLinks[firstMissingRegProp] : 'nome'

      actions.app.registrationRedirectLink(`/cadastro-${page}`)

    }
  }, [customer])

  useEffect(() => {

    if (customer?.customerId && !customer.isRegisterComplete && registrationRedirectLink) {

      const mktCampaign = localStorage.getItem('mktCampaign')
      if (mktCampaign && mktCampaign.slice(0, mktCampaign.indexOf(':')) == 'offerbait') {
        history.replace(`${registrationRedirectLink}${search}`)
      }

    }

  }, [customer, registrationRedirectLink])

  const is_app = useIsApp()

  const on_pwa_start = useCallback(() => {
    try {
      if (!window.rawUser?.registeredIn) {
        dispatch(updateCustomer({ registeredIn: "SITE" }))
      }
    } catch (e) {
      console.log('failed to update registeredIn=SITE : ', e)
    }

    dispatch(trackEvent({ category: 'passive_behavior', action: 'pwa-start', label: 'pwa' }))
  }, [])

  useEffect(() => {
    if (is_app) {
      on_pwa_start()
      //PWA is loaded event
      window.dataLayer.push({
        event: "pwa - is open",
        pwaTrackingId: window.pwaTrackingId
      })

      Sentry.setTag('is.pwa', window.CI_deploy_version)
    }
  }, [is_app])

  useEffect(() => {
    //messages sent from service worker with postMessage
    navigator?.serviceWorker?.addEventListener("message", (e) => {

      //console.log('message from [sw] : ', e)

      if (e.data == 'notification click - focus') {
        //console.log('[sw] notification click event')

        window.dataLayer.push({
          event: "push focus",
          webPushDeviceId: localStorage.getItem('webPushDeviceId') || ''
        })
      }

      if (e.data == 'notification click - open') {
        //console.log('[sw] notification click event')

        window.dataLayer.push({
          event: "push open",
          webPushDeviceId: localStorage.getItem('webPushDeviceId') || ''
        })
      }

      if (e.data?.includes && e.data?.includes('notificationclickurl')) {
        window.location = (JSON.parse(e.data)).notificationclickurl
      }

    })
  }, [])

  useEffect(() => {
    const mktCampaign = localStorage.getItem('mktCampaign')
    //console.log('mktCampaign : ', mktCampaign)

    if (mktCampaign) {

      switch (mktCampaign.slice(0, mktCampaign.indexOf(':'))) {

        case 'offerbait':

          try {
            dispatch(updateCustomer({ registeredIn: "HIGHLIGHTEDOFFER" }))
          } catch (e) {
            console.log('failed to update registeredIn=HIGHLIGHTEDOFFER : ', e)
          }

          let promotionIds = mktCampaign.slice(mktCampaign.indexOf(':') + 1).split(',') || [];

          promotionIds.forEach(promotionId => {
            if (promotionId) {
              dispatch(trackEvent({ category: 'passive_behavior', action: 'bait-offer', property: promotionId, label: 'bait' }))
            }
          })

          break;

        case 'qrcode':

          try {
            if (!window.rawUser?.registeredIn) {
              dispatch(updateCustomer({ registeredIn: "SITE" }))
            }
          } catch (e) {
            console.log('failed to update registeredIn=SITE : ', e)
          }

          dispatch(trackEvent({ category: 'passive_behavior', action: 'page-print', property: 'qr-code', label: 'digital' }))
          break;

        case 'pwa-start':
          on_pwa_start()
          break;

        case 'organic':

          try {
            if (!window.rawUser?.registeredIn) {
              dispatch(updateCustomer({ registeredIn: "SITE" }))
            }
          } catch (e) {
            console.log('failed to update registeredIn=SITE : ', e)
          }

          dispatch(trackEvent({ category: 'passive_behavior', action: 'url-open', label: 'organic' }))
          break;

        default:
          break;

      }
      localStorage.removeItem('mktCampaign')
    }
  }, [])

  useEffect(() => {
    //to update emailContactFlag for new user from auth-do-cliente
    const emailContactFlag = localStorage.getItem('authEmailContactFlag')

    if (emailContactFlag != null && ['true', 'false'].includes(emailContactFlag)) {

      dispatch(updateCustomer({
        emailContactFlag: emailContactFlag == 'true'
      }))

      localStorage.removeItem('authEmailContactFlag')
    }
  }, [])

  useLayoutEffect(() => {
    if (window.inIframe && window.innerHeight > window.outerHeight) {
      //fix for ecommerce overflow

      const d = document.querySelector('html')

      if (d) {
        d.style.maxHeight = `${window.outerHeight * (iOSVersion() ? 0.754 : 0.928)}px`
      }
    }
  }, [])

  const [top_popup_opened, set_top_popup_opened] = useState(false)

  useEffect(() => {
    if (customer.customerId && customer.marketingCampaign?.includes('lostPhoneAccess')) {
      history.replace('/cadastro/alterar')

      set_top_popup_opened(true)

      const upd = customer.marketingCampaign.filter(c => c != 'lostPhoneAccess')

      dispatch(deleteCustomerProps(['marketingCampaign']))

      window.dataLayer.push({
        event: 'wifi_flow_phone_update_alert_view',
        eventLocation: 'screen-offers-page-4',
      })

    }
  }, [customer.marketingCampaign])

  const edit_user_page = useMemo(() => pathname.includes('/cadastro/alterar'), [pathname])

  const footer_icons = useMemo(() => {
    return [
      ...marketplaceEnable ? ['marketplace'] : [],
      ...reminders?.length ? ['reminders'] : [],
      ...window.settings['template.accessible.integration.benefits.izio.enable'] ? ['izio'] : [],
      ...window.settings['template.accessible.integration.benefits.cv.enable'] ? ['cv'] : [],
    ]
  }, [marketplaceEnable, reminders])

  return (
    window.notAuth
      ?
      <>
        <Head />
        <DynamicStyles />
        <ColorStyles />
        <ContactService />
        <Switch>
          <Route exact path="/acesso-nao-autorizado" component={NotAuthenticatePage} />
          <Route exact path="*" render={() => <Redirect to={{
            ...location,
            pathname: "/acesso-nao-autorizado"
          }} />} />
        </Switch>


        {
          !window.inIframe ? <FaleConoscoModal /> : ''
        }

      </>
      :
      <>
        <Head />
        <DynamicStyles />
        <ColorStyles />
        <ContactService />
        {
          dataIsLoading ? <GlobalLoader />
            :
            (
              <>

                {
                  !window.inIframe ?
                    <>
                      <WebpushTopPopup />
                      <TopBarAppCTA />
                    </>
                    : ''
                }

                {
                  edit_user_page ?
                    <TopOverlapMessagePopup
                      text={isMobile ? 'Atualize seu número de celular' : 'Não perca nenhuma vantagem! Atualize seus dados e fique por dentro de nossas ofertas!'}
                      close={() => { set_top_popup_opened(false) }}
                      opened={top_popup_opened}
                      subtext={isMobile ? 'Notamos que seu celular estava desatualizado na última vez que tentou acessar nosso Wi-Fi. Atualize seu número e não perca nenhuma vantagem Propz Club!' : ''}
                      popup_class="higher"
                      text_container_class="bigger"
                    />
                    : <></>
                }

                {
                  (
                    showNavBars
                    ||
                    isMobile
                  )
                    //hide menu if in iframe and only ofertas page is available
                    && (window.inIframe ? marketplaceEnable || !window.parameters.cashbackDisabled : true)
                    ?
                    <Header />
                    : ''
                }

                <div
                  id="content"
                  className={`${!showNavBars && 'without_nav' || ''} ${(ContaSidenavOpened || TermsSidenavOpened || CashbackSidenavOpened) && 'prevent_scroll' || ''} `}
                >

                  <a href={
                    //window.parameters.glob_marketplaceRules
                    window.rawUser.lgpd_zipLink
                  } target="_blank" download hidden ref={download_data_link_ref}></a>

                  {
                    !customer?.deleteRequestDate
                      ?
                      (
                        <Switch>

                          <Route exact path="/" component={Offers} />

                          <Route exact path="/lembretes">
                            {
                              window.inIframe ?
                                <Redirect to={{
                                  ...location,
                                  pathname: "/"
                                }} />
                                :
                                <Reminders />
                            }
                          </Route>

                          <Route exact path="/shopping" >
                            {
                              marketplaceEnable ?
                                <Cashback />
                                :
                                <Redirect to={{
                                  ...location,
                                  pathname: "/"
                                }} />
                            }
                          </Route>

                          <Route exact path='/shopping/faq'>
                            {/* {
                              marketplaceEnable ?
                                <FAQ />
                                :
                                <Redirect to={{
                                  ...location,
                                  pathname: "/"
                                }} />
                            } */}
                            <FAQ />
                          </Route>


                          <Route exact path='/ajuda'>
                            <Ajuda />
                          </Route>

                          <Route exact path='/cadastro'>
                            {
                              window.inIframe ?
                                <Redirect to={{
                                  ...location,
                                  pathname: "/"
                                }} />
                                :
                                <UserInfo />
                            }
                          </Route>

                          <Route exact path='/cadastro/:phase'>
                            {
                              window.inIframe ?
                                <Redirect to={{
                                  ...location,
                                  pathname: "/"
                                }} />
                                :
                                <UserInfo />
                            }
                          </Route>

                          <Route exact path='/benefits/:id'>
                            {
                              window.inIframe ?
                                <Redirect to={{
                                  ...location,
                                  pathname: "/"
                                }} />
                                :
                                <Benefits />
                            }
                          </Route>

                          <Route exact path='/webpush-pagina'>
                            {
                              window.inIframe ?
                                <Redirect to={{
                                  ...location,
                                  pathname: "/"
                                }} />
                                :
                                <WebPushPage />
                            }
                          </Route>

                          <Route path='/cadastro-:phase'>
                            {
                              !window.inIframe
                                && window.parameters.registrationDisable != 'true'
                                ?
                                <RegistrationCard />
                                :
                                <Redirect to={{
                                  ...location,
                                  pathname: "/"
                                }} />
                            }
                          </Route>

                          <Route path="*" render={() => <Redirect to={{
                            ...location,
                            pathname: "/"
                          }} />} />

                        </Switch>
                      )
                      :
                      (
                        <Switch>

                          <Route
                            exact
                            path="/unsubscription"
                            component={DeleteRequestPage}
                          />

                          <Route
                            path="*"
                            render={() => <Redirect to={{
                              ...location,
                              pathname: "/unsubscription"
                            }} />}
                          />

                        </Switch>
                      )
                  }

                  {
                    marketplaceEnable &&
                    <CashbackSidenav />
                  }

                  {
                    !window.inIframe ?
                      <>
                        <ContaSidenav icons={footer_icons} />

                        {/* <TermsSidenav /> */}
                      </>
                      : ''
                  }

                </div>

                {
                  !window.inIframe ?
                    <>

                      <EmailSkippedPopup />

                      <PhoneSkippedPopup />

                      <OrderedPopups />

                      <WebpushBaloonPopup />

                      <WebPushNotification />

                      <WebpushModal />

                      <FullsizeWebpushPopup />

                      <DeniedNotificationsModal />

                      <FaleConoscoModal />

                      <SplashScreenPopup />

                      <PDFPopup />

                      {
                        showNavBars
                        &&
                        pathname != '/unsubscription'
                        &&
                        <FooterMobile icons={footer_icons} />
                      }
                    </>
                    : ''
                }

                <StoresSearchPopup />

                <OfferConditionsModal />
                <CurrentOfferStoresModal />

                {
                  window.parameters.cashbackDisabled ? '' :
                    <CashbackModal />
                }
              </>
            )}

        <PhoneFlip />

        <div id={`${animations_constants.full_page_animation}`} style={{
          position: 'fixed',
          inset: 0,
          zIndex: 1,
          pointerEvents: 'none',
        }}></div>

        {
          window.inIframe ?
            <EcommerceCartErrorPopup />
            : ''
        }

      </>
  )
}

export default App
