import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import {
  useLocation,
  useParams,
  useHistory
} from "react-router-dom"

import { Helmet } from "react-helmet"

/* Form components */
import { useFormik } from 'formik'
import * as yup from 'yup'

import {
  TextInput,
  Button,
  Checkbox,
} from "react-materialize"

//@ts-ignore
import mailcheck from 'mailcheck'

import { DisableOverflowScrolling } from '@assets/components/DisableOverflowScrolling'

import { useMediaQuery } from 'react-responsive'

/* Data */
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import {
  updateCustomer,
  deleteCustomerProps,
} from "@/features/propzMedia/propzMediaSlice"

import {
  getNextRegStep,
} from '@/utils/internal functions'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

import {
  setupABTest,
} from "@assets/utils"

import {
  pdc_local_storage,
} from '@assets/constants/localStorage'

import './index.sass'

const domains = [
  'gmail.com',
  'hotmail.com',
  'yahoo.com.br',
  'icloud.com',
  'outlook.com',
  'bol.com.br',
  'uol.com.br',
  'terra.com.br',
]

mailcheck.domainThreshold = 12
mailcheck.topLevelThreshold = 6
mailcheck.secondLevelThreshold = 7

//console.log('mailcheck : ', mailcheck)

export const EmailForm: FC = () => {

  const history = useHistory()

  const dispatch = useAppDispatch()

  const [checkbox, setCheckbox] = useState(false)

  const {
    search,
    pathname,
  } = useLocation()

  const isNotMobile = useMediaQuery({ query: '(min-width: 768.1px)' }) ? 'input-outlined' : ''

  const customer = useTrackedStore().app.customer()

  useEffect(() => {
    setCheckbox(Boolean(customer.emailContactFlag))
  }, [customer])

  let validationSchema = yup.object({
    email: yup
      .string()
      .email("Insira um endereço de e-mail válido")
    //.required("Insira um endereço de e-mail válido"),
  })

  const formik = useFormik({
    initialValues: {
      email: customer?.homeEmail || '',
    },
    validationSchema: validationSchema,
    //validateOnChange: false,
    onSubmit: async (value) => {

      document.body.style.cursor = 'wait';

      if (value.email) {

        let props = {
          homeEmail: value.email,
          emailContactFlag: true,
          pmUnsubscribedEmail: false
        }

        await dispatch(updateCustomer(props))

      } else {

        if (customer.homeEmail) {

          await dispatch(deleteCustomerProps(['homeEmail']))

          await dispatch(deleteCustomerProps(['emailContactFlag']))

          await dispatch(deleteCustomerProps(['pmUnsubscribedEmail']))
        }
      }

      document.body.style.cursor = 'default'
      formik.setSubmitting(false);
      history.push(`/cadastro-${getNextRegStep(pathname)}${search}`)
    },
  })

  const [inputNode, setInputNode] = useState<HTMLInputElement | null>()

  useEffect(() => {
    if (inputNode) {
      //@ts-ignore
      inputNode.addEventListener('input', function (e: InputEvent) {

        formik.setFieldValue("email", (e?.target as HTMLInputElement)?.value, false)
      })
    }
  }, [inputNode])

  const [input, setInput] = useState('')

  const [correction, set_correction] = useState()

  useEffect(() => {

    set_correction(mailcheck.run({
      email: input,
      domains,
      secondLevelDomains: [],
      topLevelDomains: [],
    }))

    const inputed_domain = input.split('@')[1]

    const s = inputed_domain ? domains.filter(domain =>
      domain.length != inputed_domain.length && domain.slice(0, inputed_domain.length) == inputed_domain
    ) : domains

  }, [input])

  const clue_click = useCallback((e, correction?: boolean) => {

    let t = (correction ? e.target.querySelector('.address') || e.target.closest('.address') || e.target : e.target).textContent

    setInput(t)

    formik.setFieldValue('email', t)

  }, [])

  return (

    <form onSubmit={formik.handleSubmit} className='roll_in_from_bottom_animation full_page_form' >
      <Helmet>
        <title>Informe seu e-mail</title>
        <meta name="description" content="Solicitação do e-mail de contato." />
      </Helmet>

      <div className="input_with_clue">

        {/* email */}
        <TextInput
          id="email"
          name="email"
          type='email'
          label="E-mail"

          //@ts-ignore
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          autofocus="true"

          //@ts-ignore
          ref={(e) => {
            setInputNode(e)
          }}

          className={`${isNotMobile} ${formik.touched.email && Boolean(formik.errors.email) && 'invalid' || ''}`}

          value={formik.values.email}

          onBlur={formik.handleBlur}

          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setInput(e.target.value)
            formik.handleChange(e)
          }}

          error={formik.touched.email && formik.errors.email || ' '}

          disabled={formik.isSubmitting}
        />

        {
          input && (
            correction
            //@ts-ignore
            && input != correction.full
          ) ?
            <div className="clue">
              <div className="clue_line" onClick={(e) => clue_click(e, true)}>
                {
                  `Você quis dizer: `
                }
                <span className="address">
                  {
                    //@ts-ignore
                    correction.address
                  }
                  @
                  <b>
                    {
                      //@ts-ignore
                      correction.domain.split('.')[0]
                    }
                  </b>
                  .
                  {
                    //@ts-ignore
                    correction.domain.split('.').splice(1).join('.')
                  }
                </span>
              </div>
            </div>
            : <></>
        }
      </div>

      <div className="info" style={{
        textAlign: window.parameters.glob_LGPDFlow ? 'start' : 'center'
      }}>
        {
          window.parameters.glob_LGPDFlow ?
            <Checkbox
              className="rounded_checkbox"
              checked={Boolean(customer.emailContactFlag)}
              filledIn
              label=""
              //@ts-ignore
              value={true}
              //@ts-ignore
              onChange={e => setCheckbox(e.target.checked)}
            />
            : ''
        }

        <span>
          {
            window.parameters.glob_LGPDFlow ?
              'Aceito receber comunicações sobre oferta e descontos exclusivos no e-mail informado.'
              :
              'Ao continuar, você aceita receber nossas comunicações sobre oferta e descontos exclusivos no e-mail informado.'
          }
        </span>
      </div>

      {/* Submit */}
      <Button
        flat
        //@ts-ignore
        type="submit"
        disabled={
          formik.isSubmitting
          || !formik.isValid
          || Boolean(
            window.parameters.glob_LGPDFlow
            && formik.values.email
            && !checkbox
          )
        }
        waves="light" className="white-text"
      >
        <span>
          Quero receber ofertas
        </span>
        <span className="desktop_only">exclusivas</span>
        <span>por e-mail</span>
        <DisableOverflowScrolling />
      </Button>

    </form>
  )
}