/* 
  This component is a link that counts his place in navigation bars(Top navigation, footer) and sets redux state with its left position for popups that need to appear and point to this link
 */
import React, { FC, useState, useEffect, useMemo, useCallback, ReactComponentElement, } from "react"
import { useLocation, useHistory } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import { QueryLink } from '@/parts'

import { useMediaQuery } from 'react-responsive'

//@ts-ignore
import debounce from 'lodash.debounce'

import "./style.sass"

import { setPropzMediaStateParameter } from "@/features/propzMedia/propzMediaSlice"
import { pdcStore } from "@assets/store/pdcStore"

interface Props {
  icon?: React.ReactNode;
  callToAction?: React.ReactNode;
  name: string;
  setPosition: string;
  link: string;
  onClickAction?: () => void;
  place?: string;
}

export const LinkCountedPosition: FC<Props> = ({ icon, callToAction, name, setPosition, link, onClickAction, place }) => {

  let history = useHistory()

  let location = useLocation()
  let { pathname } = location

  //console.log('location: ', location)

  const dispatch = useAppDispatch()

  const TermsSidenavOpened = pdcStore.use.TermsSidenavOpened()

  const CashbackSidenavOpened = pdcStore.use.CashbackSidenavOpened()

  const ContaSidenavOpened = pdcStore.use.ContaSidenavOpened()

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const [linkNode, setLinkNode] = useState<HTMLAnchorElement | null>(null)

  const LinkRef = useCallback(node => {

    if (node !== null) {
      setLinkNode(node)
    }
  }, [])

  const recalculatePosition = useCallback(debounce(() => {
    //console.log('resize listner : ', e)
    let node: HTMLAnchorElement | undefined

    setLinkNode(n => { if (n) node = n; return n })

    ///console.log('[recalculatePosition] node : ', node)

    if (node) {
      const { x, y, bottom, height, width } = node.getBoundingClientRect()

      switch (place) {
        case 'header':
          if (window.innerWidth > 768) {

            dispatch(setPropzMediaStateParameter({ [setPosition]: { x, y: bottom - height / 4 } }))
          }
          break
        case 'footer':
          if (window.innerWidth < 768.1) {

            dispatch(setPropzMediaStateParameter({ [setPosition]: { x: x + width / 2, y } }))
          }
          break
        default: break
      }
    }
  }, 100), [])

  useEffect(() => {
    if (linkNode) {
      setTimeout(recalculatePosition, 500)
      recalculatePosition()
      window.addEventListener("resize", recalculatePosition, false)
      return () => window.removeEventListener("resize", recalculatePosition)
    }
  }, [linkNode])

  const linkClass = useMemo(() => `${isMobile ? 'icon' : 'menu'}-link`, [isMobile])

  return (
    <QueryLink to={link}
      className={`${linkClass} ${(
        isMobile ?
          !(ContaSidenavOpened || TermsSidenavOpened)
          : true
      )
        && (
          CashbackSidenavOpened || pathname.includes(link)
        )
        ? `${linkClass}-active` : ''}`
      }
      /* TODO: add onClick if exists */
      {...(onClickAction ? { onClick: onClickAction } : {})}
      //@ts-ignore
      ref={LinkRef}
    >
      {
        isMobile
          ?
          <>
            <div className="block_with_badge">
              {icon || ''}
              {callToAction}
            </div>

            <span className="name">
              {name}
            </span>
          </>
          :
          <>
            {name}
            {callToAction}
          </>
      }

    </QueryLink>
  );
};
