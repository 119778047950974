import React, { FC, useEffect, useMemo, useState } from "react"

import {
  useParams,
} from "react-router-dom"

/* Materialize components */
import { CardPanel } from "react-materialize"

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

/* Data */
import { useAppDispatch, useAppSelector } from "@/app/hooks"

import { View } from './View'
import { Edit } from './Edit'

import "./style.sass"
import './modal.sass'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

export const UserInfo: FC = () => {

  const {
    glob_color1,
  } = window.parameters

  let { phase } = useParams<{
    phase: 'alterar' | ''
  }>()

  const customer = useTrackedStore().app.customer()

  const formComponent = useMemo(() => {
    return phase == 'alterar' ? <Edit /> : <View />
  }, [phase])

  const progress = useMemo(() =>
    ((
      //countRegPresentProps() * 100 / registrationProps().length
      Number(customer?.percentRegistrationCompletion)
    ).toFixed() || 0) as number

    , [customer])

  return (
    <div className="full-registration-wrapper">
      <div className="registration-card">

        <div className="card-header">
          Informações pessoais
        </div>

        <div className="underscore"></div>

        {
          phase == 'alterar'
            ?
            ''
            :
            <>
              <div className="question roll_in_from_right_animation">

                <div className="registration_progress">
                  <CircularProgressbar
                    value={progress}
                    text={`${progress}%`}
                    counterClockwise
                    strokeWidth={11}
                    styles={
                      {
                        ...buildStyles({
                          // Text size
                          textSize: '23px',
                          // Colors
                          pathColor: glob_color1,
                          textColor: glob_color1,
                          trailColor: '#E1E1E1',
                        }),
                        ...{
                          text: {
                            fontWeight: 600
                          }
                        }
                      }
                    } />
                </div>

                <div className="text">
                  {
                    progress == 100
                      ?
                      `Seu cadastro está completo!`
                      :
                      `Complete seu cadastro`
                  }
                </div>
              </div>

              <div className="secondary">
                Mantenha seus dados sempre atualizados para receber as melhores ofertas para o seu perfil!
              </div>
            </>
        }

        <div className="form">
          {formComponent}
        </div>

      </div>
    </div>
  );
};
