import React, { FC } from "react";

import { Offer } from "@assets/types"

import { useAsyncMemo } from 'use-async-memo'

import './style.sass'

import {
  getApiRoot,
} from '@/utils/internal functions'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

interface Props {
  item: Offer;
  bannerCount: number;
  bannerRef: (e: HTMLDivElement) => void;
  onclick_handler: React.MouseEventHandler<HTMLDivElement>;
}

export const ImageBanner: FC<Props> = ({ item, bannerCount, bannerRef, onclick_handler }) => {

  const customer = useTrackedStore().app.customer()

  const bannerImage = useAsyncMemo(async () => {

    const response = await fetch(`${getApiRoot()}/v2/cxp/promotions/${item.id}.png`, {
      cache: 'no-cache',
      method: 'GET',
    })

    const blob = await response.blob()

    const url = URL.createObjectURL(blob)
    
    return url
  }, [customer])

  return (
    <div
      className={`carousel-item banner_item auto_banner`}

      style={{
        backgroundImage: item?.requiresRegisterComplete ? `url('${bannerImage}')` : `url('${getApiRoot()}/v2/cxp/promotions/${item.id}.png')`,
        cursor: 'pointer',
      }}

      data-banner-position={bannerCount}

      data-banner-name={`banner-offer-${item.id || ''}`}

      data-id={item.id}

      ref={bannerRef}

      onClick={onclick_handler}></div>
  );
};
