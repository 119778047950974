import {
  Range,
} from '@assets/types/generics'

/* cases = [{name: sampleName1, weight: sampleWeight1}, {name: sampleName2, weight: sampleWeight2}] */

export const setupABTest = ({
  name,
  cases,
}: {
  name: string,
  cases: {
    name: string,
    weight: Range<0, 100>
  }[],
}) => {

  if (!getABTest(name)) {

    let target = Math.floor(Math.random() * cases.reduce((acc, { weight }) => acc + weight, 0))

    let i = 0;
    while (i < cases.length) {

      target -= cases[i].weight

      if (i == cases.length - 1 || target <= 0) {
        localStorage.setItem(name, cases[i].name);
        break;
      }
      i++;
    }
  }
  return getABTest(name)
}

export const getABTest = (name: string) => localStorage.getItem(name)