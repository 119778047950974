import React, { useMemo, useEffect, } from 'react'

import { useLocation, } from "react-router-dom"

import {
  appStore,
} from '@assets/store/appStore'

// saves a query object to store for reuse
// to be used at start of application
export function useQuery() {

  const { search } = useLocation()

  const query = useMemo(() => new URLSearchParams(search), [search])

  useEffect(() => {
    appStore.set.query(new URLSearchParams(search))
  }, [query])

  return query
}