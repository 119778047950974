import React, { useEffect, FC, useState, useMemo, } from 'react'

import {
  useHistory,
  useLocation
} from "react-router-dom"

import { Helmet } from "react-helmet"

import { tsParticles } from '@tsparticles/engine'

/* Data */
import { useAppDispatch } from "@/app/hooks"

import {
  setupABTest,
} from "@assets/utils"

import {
  pdc_local_storage,
} from '@assets/constants/localStorage'

import {
  updateCustomer,
} from "@/features/propzMedia/propzMediaSlice"

import { useMediaQuery } from 'react-responsive'

import { ReactComponent as Star } from '@assets/icons/star.svg'
import { ReactComponent as CompleteSVG } from './complete.svg'

import Trophy from "@assets/images/trofeu.png"

import "./style.sass"

import { useConfetti } from '@assets/hooks/useConfetti'

import { animations_constants } from '@assets/constants/animations'

export const RegistrationComplete: FC = () => {

  const history = useHistory()

  const dispatch = useAppDispatch()

  const { search } = useLocation()

  const [timer, setTimer] = useState(3)

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => {

    document.body.style.cursor = 'wait';

    let props = {
      pmRegisteredInPartnerProgram: true,
      registeredInPartnerProgram: true,
      lastRegistrationUpdate: new Date().toISOString(),
    }

    dispatch(updateCustomer(props))

      .finally(() => {
        document.body.style.cursor = 'default'

        let int = setInterval(() => {
          let t;
          setTimer(time => { t = --time; return t })
          if (!t) {
            clearInterval(int)
            history.push(`/${search}`)
          }
        }, 1000)
      })
  }, [])

  useEffect(() => {
    window.dataLayer.push({
      event: 'accountCreation',
      userId: window.rawUser?.uuid,
      userRegistrationSource: window.rawUser?.registeredIn
    })
  }, [])

  const p = useConfetti({
    id: animations_constants.full_page_animation,
    options: {
      emitters: [
        {
          life: {
            duration: 2.5,
            count: 1,
          },
          position: {
            x: isMobile ? 10 : 30,
            y: isMobile ? 22 : 24,
          },
          particles: {
            move: {
              direction: "none",
            },
          },
          rate: {
            quantity: isMobile ? 3 : 10
          },
        },
        {
          life: {
            duration: 2.5,
            count: 1,
          },
          position: {
            x: isMobile ? 90 : 70,
            y: isMobile ? 22 : 24,
          },
          particles: {
            move: {
              direction: "none",
            },
          },
          rate: {
            quantity: isMobile ? 3 : 10
          },
        },
        {
          life: {
            duration: 2.5,
            count: 1,
          },
          position: {
            x: 50,
            y: isMobile ? 22 : 24,
          },
          particles: {
            move: {
              direction: "none",
            },
          },
          rate: {
            quantity: isMobile ? 7 : 20
          },
        },
      ],
      preset: "confetti",
    },
  }, true)

  return (
    <div className={`complete_registration roll_in_from_bottom_animation full_page`}>
      <Helmet>
        <title>Cadastro atualizado com sucesso</title>
        <meta name="description" content="Atualização de cadastro realiza com sucesso." />
      </Helmet>

      <div className="trophy_img">
        <img
          src={Trophy}
          alt="trophy image"
        />
        <Star />
      </div>

      <div className="page_header">
        Você conseguiu!
      </div>

      <div className="page_text">
        Seu cadastro está completo!
      </div>

      <div className="page_counter_text primary_color_text">
        {`Você será redirecionado para suas ofertas em `}{timer}...
      </div>

    </div>
  )
}