import { FC, useMemo, useEffect, createRef, useCallback, useState, useRef } from "react"

import { TextInput } from "react-materialize"

import { generateUuid } from '@/utils'

import './style.sass'
import styles from './style.sass'

import {
  brazilian_text_to_searchable,
} from "@assets/functions"

import { ReactComponent as CancelSVG } from '@/assets/icons/cancel.svg'

interface Props {
  placeholder: string;
  options: any[];
  optionRepresentation?: (x: any) => string;
  setSelectedItem: (...args: any[]) => void;
  classnames?: string;
  initialValue?: string;
  saveEmpty?: boolean;
}

export const TextInputWithSearchDropdown: FC<Props> = ({ placeholder, options, optionRepresentation, setSelectedItem, classnames, initialValue, saveEmpty }) => {

  const representation = (item: any) => {
    return optionRepresentation ? optionRepresentation(item) : item
  }

  const options_ref = useRef<HTMLDivElement>(null)

  const [input, setInput] = useState(representation(initialValue))

  const [search, set_search] = useState('')

  useEffect(() => set_search(input), [input])

  useEffect(() => {
    if (initialValue) {
      //console.log('initialValue : ', initialValue)
      setSelectedItem(initialValue)
    }
  }, [initialValue])

  const [inputFocused, setInputFocused] = useState(false)

  const searched_items = useMemo(() => {
    return search ?
      options.filter(item =>
        search.replace('-', '').split(' ').every((i: string) =>
          brazilian_text_to_searchable(representation(item).replace('-', '')).includes(brazilian_text_to_searchable(i))
        )
      )
      :
      options
  }, [search, options])

  useEffect(() => {
    if (!input.length) {
      setSelectedItem('')
    }
  }, [input])

  return (
    <>
      <div className={`TextInputWithSearchDropdown_container ${classnames || ''}`} id='TextInputWithSearchDropdown_container'>

        <TextInput
          name={generateUuid()}
          placeholder={placeholder}
          //@ts-ignore
          className={`input-outlined`}
          autocomplete="off"

          value={input}
          onChange={(e) => {
            setInput(e.target.value)
          }}

          onBlur={(e: Event & { target: HTMLElement }) => {
            if (options_ref.current) {
              options_ref.current.scrollTop = 0
            }

            if (e.target.parentElement?.classList.contains('active')) {
              e.target.parentElement?.classList.remove('active')
            }

            setInputFocused(false)

          }}

          onClick={(e: Event & { target: HTMLElement }) => {
            e.target.parentElement?.classList.toggle('active')
            setInputFocused(f => {
              if (f) {
                e.target.blur()
                set_search(input)
              } else {
                set_search('')
              }
              return !f
            })
          }}

          icon={
            <CancelSVG onClick={() => setInput('')} onMouseDown={(e) => e.preventDefault()} aria-label="clear input" />
          }
        />

        <div
          className="search_dropdown"
          style={{
            display: inputFocused ? 'flex' : 'none'
          }}
          ref={options_ref}
        >
          {
            searched_items.map((item, key) =>
              <div key={key} className="search_dropdown_item" onMouseDown={() => {
                setSelectedItem(item)
                setInput(representation(item))
                return
              }}>
                <p>{representation(item)}</p>
              </div>
            )
          }
        </div>

      </div>
    </>
  )
}