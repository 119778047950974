import React, { FC, useState, useEffect, } from 'react'

import { useMediaQuery } from 'react-responsive'

import {
  TextInput,
} from "react-materialize"

import {
  preparePhone,
} from '@assets/utils'
import { useTestidPrefix } from '@assets/hooks/useTestidPrefix'

import InputMask from "react-input-mask"
import { useDebounceCallback } from 'usehooks-ts'

interface Props {
  formik: any;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  text_input_props?: any;
}

export const Phone: FC<Props> = ({ formik, onBlur, text_input_props, }) => {

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const [inputNode, setInputNode] = useState<HTMLInputElement | null>()
  const prefix = useTestidPrefix()

  useEffect(() => {
    if (inputNode) {
      //@ts-ignore
      inputNode.addEventListener('input', function (e: InputEvent) {

        if (!e.inputType?.includes('delete') && (e.data?.length ?? 2 > 1)) {

          let input = e.target as HTMLInputElement

          let v = preparePhone(input.value)

          formik
            .setFieldValue("phone", v)
            //this will update cursor position to the end of input (fix for cursor not changing position)
            .finally(() => {
              setTimeout(() => {
                let l = inputNode.value.length
                inputNode.setSelectionRange(l, l)
              }, 20)
            })
        }
      })
    }
  }, [inputNode])

  const onBlur_handler = useDebounceCallback((e) => {
    if (onBlur) {
      onBlur(e)
    }

    formik.handleBlur(e)
  })

  return (

    <InputMask mask="(99) 99999-9999"
      value={formik.values.phone}
      onBlur={onBlur_handler}
      onChange={formik.handleChange}
      disabled={formik.isSubmitting}
      maskChar={null}
    >
      {(inputProps: any) =>
        <TextInput
          id="phone"
          name='phone'
          placeholder='Celular'
          type="tel"
          inputMode="numeric"
          //@ts-ignore
          className={
            `${isMobile ? '' : "input-outlined"} ${formik?.touched.phone && Boolean(formik?.errors.phone) ? 'invalid' : ''}`
          }

          //@ts-ignore
          ref={(e) => {
            setInputNode(e)
          }}

          {...inputProps}

          {...text_input_props}

          data-testid={`${prefix}_input_phone`}

          value={formik.values.phone}

          disabled={formik.isSubmitting}
        >
          <span className="helper-text"

            data-testid={`${prefix}_input_phone_error_message`}

            data-error={formik?.touched.phone && formik?.errors.phone || ' '} />
        </TextInput>
      }
    </InputMask>
  )
}