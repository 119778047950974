import Img0 from './0.png'
import Img1 from './1.png'
import Img2 from './2.png'
import Img3 from './3.png'
import Img4 from './4.png'
import Img5 from './5.png'
import Img6 from './6.png'
import Img7 from './7.png'
import Img8 from './8.png'
import Img9 from './9.png'
import Img10 from './10.png'
import Img11 from './11.png'
import Img12 from './12.png'
import Img13 from './13.png'
import Img14 from './14.png'
import Img15 from './15.png'
import Img16 from './16.png'

export const people_images = [
  Img0,
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img15,
  Img16,
]

export const humanImg = () => people_images[
  Math.floor(Math.random() * people_images.length)
]