import {
  Offer,
} from '@assets/types'

import { checkStorageListAndStore } from '@assets/utils/storage'

export const promotionEcomEvent = (eventProps: {
  event: string;
  [x: string]: any;
}, promotion?: Offer, ecommerce?: { [x: string]: any }) => {

  if (
    promotion
    && (
      promotion.id
      ||
      promotion?.uuid
    )
    && !checkStorageListAndStore(sessionStorage, eventProps.event, promotion.id || (promotion?.uuid ?? ''))
  ) {

    window.dataLayer.push({ ecommerce: null })

    window.dataLayer.push({
      ...eventProps,
      ecommerce: Object.assign({
          currency: "BRL",
          items: [
            {
              item_id: promotion?.id || promotion?.uuid
            }
          ]
      }, ecommerce ?? {}),
    })
  }
}