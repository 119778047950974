import { pdcStore } from "@assets/store/pdcStore"
import { storeItem } from "@assets/utils"
import { isAndroid } from "@assets/utils/isAndroid"
import { isIOS } from "@assets/utils/isIOS"
import { useDebounceCallback } from "usehooks-ts"
import { useIsApp } from "@assets/hooks/useIsApp"

/* returns the condition to show App CTA and action that should be made on click */
export function useApp(cta?: 'splashscreen' | 'banner' | 'headerbar' | 'promotionbar', callback?: () => void): [show: boolean, action: () => void] {

  const iOS = isIOS()
  const android = isAndroid()

  const is_app = useIsApp()
  const pwa_event = pdcStore.use.pwa_event()

  const action = useDebounceCallback(() => {

    window.dataLayer.push({
      event: `pwa_install_${cta ?? ''}_click`,
    })

    callback?.()

    if ((iOS && window.parameters.app_link_ios) || (android && window.parameters.app_link_google)) {
      
      const href = new URL((iOS ? window.parameters.app_link_ios : window.parameters.app_link_google) || "about:blank")

      href.searchParams.set('utm_source', 'pdc')

      href.searchParams.set('utm_medium', `app_${cta}`)

      href.searchParams.set('utm_campaign', `app_install_${cta}_cta`)

      Object.assign(document.createElement('a'), {
        target: '_blank',
        rel: 'noopener noreferrer',
        href,
      }).click()
    } else {
      //PWA flow
      pdcStore.set.WebpushModalOpened(true)

      pdcStore.set.UserWantsPWA(true)

      if (cta) {
        storeItem(sessionStorage, 'InstallPWAFromCTA', cta)
      }
    }
  }, 50)

  return [
    !window.parameters.app_ctas_disabled
    && !is_app
    && (
      (iOS && window.parameters.app_link_ios)
      || (android && window.parameters.app_link_google)
      || pwa_event
    ),
    action
  ]
}