import { FC } from "react"

import './style.sass'

//@ts-ignore
import { ReactComponent as CloseSVG } from '@assets/icons/white_close.svg'
//@ts-ignore
import { ReactComponent as AttentionSVG } from '@assets/icons/attention.svg'

interface Props {
  text?: string | React.ReactNode,
  close: () => void,
  opened: any,
  text_container_class?: string,
  subtext?: string | React.ReactNode,
  popup_class?: string,
}

export const TopOverlapMessagePopup: FC<Props> = ({ text, opened, close, text_container_class, subtext, popup_class, }) => {

  return (
    <div
      id="TopPopup"
      className={`${opened ? 'opened' : ''} ${popup_class ?? ''}`}
    >
      <AttentionSVG className="TopPopup_icon" />

      <div className={`TopPopup_text_container ${text_container_class ?? ''}`}>
        {
          text ?
            <span className={`TopPopup_text`}>
              {text}
            </span> : <></>
        }

        {
          subtext ?
            <span className="TopPopup_subtext">
              {subtext}
            </span> : <></>
        }
      </div>

      <CloseSVG className="TopPopup_close_icon"
        onClick={(e: React.MouseEvent) => {
          e?.preventDefault()
          e?.stopPropagation()

          close()
        }} />

    </div>
  );
};