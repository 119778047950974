import { parametersObjectToQueryString } from '@assets/functions/parametersObjectToQueryString'

import {
  sessionParameter,
} from '@assets/utils'

import { getApiRoot } from '@assets/functions/getApiRoot'

import { Offer } from '@assets/types'

type Props = {
  mechanics?: string[];
  types?: string[];
  limit?: number;
  offset?: number;
  storeId?: string;
  theme?: string;
  options?: any; //expand options - ['storeIds']
}

type Q = Omit<Props, 'mechanics' | 'types'> & {
  mechanics?: string[] | string;
  types?: string[] | string;
}

export const v2_cxp_promotions = async (properties: Props): Promise<Offer[]> => {

  const q: Q = properties

  if (properties.mechanics) {
    q.mechanics = properties.mechanics.join(',')
  }

  if (properties.types?.length && properties.mechanics?.length) {
    q.types = properties.types.join(',')

    const resp = await fetch(`${getApiRoot()}/v2/cxp/promotions/?${parametersObjectToQueryString(q)}${sessionParameter()}`, {
      method: "GET",
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    if (resp.status == 200) {
      return resp.json()
    }

  }
  return []

}