import React, { FC, useState, useEffect, useRef, useCallback } from 'react'

import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { getAnalytics } from "firebase/analytics"

import localforage from 'localforage'

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import {
  updateCustomer,
} from "@/features/propzMedia/propzMediaSlice"

import './style.sass'

import {
  removeTrailingSlash
} from '@/utils/internal functions'

import { notificationsNotAvailable } from '@/utils/internal functions'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

import {
  pdcStore
} from '@assets/store/pdcStore'

export const WebPushNotification: FC = () => {

  const dispatch = useAppDispatch()

  const customer = useTrackedStore().app.customer()

  const updateFirebaseRegistration = pdcStore.use.updateFirebaseRegistration()

  //for test ; in JSON
  /* window.settings["template.accessible.firebase.config.json"] = `${window.settings["template.accessible.firebase.config.json"]};` */

  /* window.settings["template.accessible.firebase.config.json"] = undefined */

  useEffect(() => {
    if (
      window.settings["template.accessible.firebase.config.json"]
      && window.settings["template.accessible.firebase.config.json"].slice(-1) === ';'
    ) {

      window.settings["template.accessible.firebase.config.json"] = window.settings["template.accessible.firebase.config.json"].slice(0, -1)
    }
  }, [])

  const parseJSON = useCallback((inputString: string | undefined, fallback: any) => {
    if (inputString) {
      try {
        return JSON.parse(inputString)
      } catch (e) {
        console.log('[WebPushNotification] something wrong with firebase config : ', e)
        return fallback
      }
    }
    return ''
  }, [])

  useEffect(() => {

    try {
      (async () => {

        const firebaseConfig = parseJSON(window.settings["template.accessible.firebase.config.json"], '')

        if ('serviceWorker' in navigator && !notificationsNotAvailable()) {
          let messaging

          //previous service workers
          const prevSWs = await navigator.serviceWorker.getRegistrations()

          for (const sw of prevSWs) {
            //console.log('[prev sw] : ', sw)
            await sw.unregister()
          }

          try {

            const serviceWorkerRegistration = await navigator.serviceWorker.register(`${removeTrailingSlash(window.PropzmediaConfig.apiRoot)}/v1/apps/portal-do-cliente/service-worker${window.CI_deploy_version ? `-${window.CI_deploy_version}` : ''}.js`, {
              scope: '/'
            })

            await serviceWorkerRegistration.update()

            //console.log('serviceWorkerRegistration : ', serviceWorkerRegistration)

            //window.addEventListener("beforeunload", SWUnreg)

            if (customer?.webPushContactFlag && firebaseConfig) {

              const firebaseApp = await initializeApp(firebaseConfig)

              const analytics = await getAnalytics(firebaseApp)

              messaging = await getMessaging(firebaseApp)

              navigator.serviceWorker.startMessages()

              await new Promise((resolve) => {
                let i = setInterval(() => {
                  if (serviceWorkerRegistration.active) {
                    serviceWorkerRegistration.active.postMessage(firebaseConfig)
                    clearInterval(i)
                    resolve(0)
                  }
                }, 10)
              })

              let getTokenError

              const token = await getToken(messaging, {
                vapidKey: window.settings["template.accessible.firebase.vapid.key"],
                //`BEiHW6M-ihGY0jKX808lmsLfMyY_rAb_9-khu3FuirWZHGN45zQ8sBR911LivsVr5dLipy3ZQTk_w5vzo9wbsms`,
                serviceWorkerRegistration
              }).catch(e => getTokenError = e)

              if (getTokenError) {
                console.log('[WebPushNotification] getToken error : ', getTokenError)
                return
              }

              console.log('current webpush token for client: ', token);

              let localToken = await localforage.getItem("webPushDeviceId")

              await localforage.setItem('webPushDeviceId', token)
              //save to pdcDeviceIds

              dispatch(updateCustomer({
                pdcDeviceIds: [token, ...(customer?.pdcDeviceIds ?? [])].filter(id => id && id != localToken),
                pushDeviceIds: [token, ...(customer?.pushDeviceIds ?? [])].filter(id => id && id != localToken),
              }))
            }
          } catch (e) {
            //console.log('[WebPushNotification] update sw error : ', e)
          }
        }
      })()
    } catch (e) {
      console.log('[WebPushNotification] error : ', e)
    }


  }, [customer?.webPushContactFlag, updateFirebaseRegistration])

  return (<></>)
}