import { FC, useEffect, useMemo, useCallback, useState } from "react"
import { useLocation } from "react-router-dom"

import { QueryLink } from '@/parts'

import { Modal, Button } from "react-materialize"

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import {
  setPropzMediaStateParameter,
} from "@/features/propzMedia/propzMediaSlice"

import {
  setupABTest,
} from "@assets/utils"

import {
  pdc_local_storage,
} from '@assets/constants/localStorage'

import {
  actions,
  useTrackedStore,
} from '@assets/store'

import "./style.sass"

import { pdcStore } from "@assets/store/pdcStore"
import { orderedPopupTimings } from '@assets/constants/orderedPopupTimings'

export const ContinueRegistrationModal: FC = () => {

  const dispatch = useAppDispatch()

  const { pathname } = useLocation()

  const customer = useTrackedStore().app.customer()

  const lastClosedPopup = pdcStore.use.lastClosedPopup()

  const registrationRedirectLink = useTrackedStore().app.registrationRedirectLink()

  const showContinueRegistrationModal = useTrackedStore().app.openRegistrationModal()

  const { registrationDisable } = useMemo(() => window.parameters, [])

  //to ensure conditions for render was checked only once
  const [was_checked, set_was_checked] = useState(false)

  const closePopup = useCallback((wait?: boolean) => {
    actions.app.openRegistrationModal(false)

    setTimeout(() => {
      if (pdcStore.get.showed_popups_count() < window.parameters.allowed_popus_count) {
        //if can show one more -> switch last closed popup
        pdcStore.set.lastClosedPopup('ContinueRegistrationModal')
      }
    }, wait ? orderedPopupTimings.ContinueRegistrationModal : 0)

  }, [])

  const regEnabled = useMemo(() => {
    return (
      window.parameters.registrationPopupDisable != 'true'
      &&
      window.parameters.registrationDisable != 'true'
    )
  }, [])

  useEffect(() => {
    //console.log('[ContinueRegistrationModal] lastClosedPopup : ', lastClosedPopup)

    if (customer?.customerId && lastClosedPopup == 'SplashscreenAppCTA' && !was_checked) {
      set_was_checked(true)

      if (customer?.isRegisterComplete
        || !regEnabled) {

        actions.app.openRegistrationModal(false)

        //open popup from ab test
        const ab_test = setupABTest(pdc_local_storage.ab_tests.baloon_topbar_popup)

        if (ab_test) {

          dispatch(setPropzMediaStateParameter({ [ab_test]: true }))
        }

      } else {
        //open continue registration modal if user has not all fields filled and it was not opened
        actions.app.openRegistrationModal(true)
        pdcStore.set.increment_showed_popups_count()
      }
    }

  }, [customer, lastClosedPopup, was_checked])

  const popupOpened = useMemo(() => regEnabled
    && showContinueRegistrationModal
    && !pathname.includes('/cadastro')
    && !pathname.includes('webpush-pagina')
    , [regEnabled, showContinueRegistrationModal, pathname])

  useEffect(() => {
    if (popupOpened) {
      window.dataLayer.push({
        event: 'signup_completion_popup_view'
      })
    }
  }, [popupOpened])

  const sendClickEvent = useCallback(() => {
    window.dataLayer.push({
      event: 'signup_completion_popup_click'
    })
  }, [])

  return (
    <Modal
      actions={[
        (registrationDisable == 'false' || registrationDisable == '')
          ?
          <QueryLink
            to={registrationRedirectLink || "/cadastro-nome"}
            onClick={sendClickEvent}
          >
            <Button flat modal="close" node="button" waves="light" className="white-text">
              Completar perfil
            </Button>
          </QueryLink>
          :
          <a href={registrationDisable} target="_blank"
            onClick={sendClickEvent} >
            <Button flat modal="close" node="button" waves="light" className="white-text">
              Completar perfil
            </Button>
          </a>
      ]}
      id="continue-registration-modal"
      open={popupOpened}
      header="Falta pouco..."
      options={{
        dismissible: true,
        onCloseEnd: () => closePopup(true)
      }}
    >
      <p>
        Complete seu cadastro e receba ofertas ainda mais personalizadas.
      </p>
    </Modal >
  );
};