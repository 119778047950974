import { FC } from "react";
import { useAppDispatch } from "@/app/hooks";
import { setPropzMediaStateParameter } from "@/features/propzMedia/propzMediaSlice";
import { ProgramLogo } from "@/components/ProgramLogo"

import "./style.css";

export const HeaderNew: FC = () => {
  const dispatch = useAppDispatch();

  const handleShowContact = () => {
    dispatch(setPropzMediaStateParameter({ showContact: true }));
  };

  return (
    <div className="headerNew">
      <ProgramLogo />
      <div className="hide-on-med-and-down">
        <p className="contact" onClick={handleShowContact}>
          Contato
        </p>
      </div>
    </div>
  );
};
