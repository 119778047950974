import React, { FC, useCallback, useMemo, useState, } from "react"

import { TextInput } from "react-materialize"

import './style.sass'

import { brazilian_text_to_searchable } from "@assets/functions"

import '&/sass/components/input-field.sass'

import {
  Store,
} from '@assets/types'

import {
  useTrackedStore,
  store as appStore,
} from "@assets/store"

import {
  promotionEvent,
} from '@assets/utils'

import { promotionEcomEvent } from "@assets/utils/promotionEcomEvent"

import { ReactComponent as StarSvg } from '@assets/icons/yellow_star.svg'
import { ReactComponent as MapPointSvg } from '@assets/icons/map_point.svg'
import { useDebounceCallback } from "usehooks-ts"

interface Props {
  header: string;
  stores: Store[];
  placeholder?: string;
  favoriteStoreLine?: string;
  place?: string;
  [x: string]: any,
}

export const StoresSearch: FC<Props> = ({ header, stores, className, placeholder, favoriteStoreLine, place, ...rest }) => {

  const customer = useTrackedStore().app.customer()

  const [search, setSearch] = useState('')

  const filteredStores = useMemo(() =>
    search ?
      stores?.filter(store =>
        search.replace('-', '').split(' ').every((i: string) =>
          brazilian_text_to_searchable(
            `${store.businessName ?? ''} ${store.city ?? ''} ${store.street ?? ''} ${store.num ?? ''} ${store.state ?? ''} ${store.zip ?? ''} ${store.localeType ?? ''}`.replace('-', '')
          ).includes(brazilian_text_to_searchable(i))
        ))
      : stores
    , [stores, search])

  const preferredStore = useCallback((store) => customer?.preferredStore
    && store.storeId == customer?.preferredStore, [
    customer
  ])

  const buttonText = useMemo(() => 'Ver mapa', [])

  const store_link_click = useDebounceCallback(e => {
    if (place == 'CurrentOfferStoresModal') {
      promotionEcomEvent({
        event: 'offer_detail_map_button_click',
      }, appStore.app.CurrentOfferStoresModalPromotion())
    }
  }, 100)

  const stores_search_focus = useDebounceCallback(() => {
    if (place == 'CurrentOfferStoresModal') {
      promotionEcomEvent({
        event: 'offer_detail_search_store_field_click',
      }, appStore.app.CurrentOfferStoresModalPromotion())
    }
  }, 100)

  return (
    <div
      className={`store_search_container ${className ?? ''}`}
    >
      <div
        className={`header`}
      >
        <span>
          {header}
        </span>

        <TextInput
          id="stores_search"
          name="stores_search"
          //@ts-ignore
          className={`input-outlined`}
          /* icon="search" */
          placeholder={placeholder || 'Busque por CEP, endereço ou cidade'}
          value={search}

          onChange={e => setSearch(e.target?.value?.toLocaleLowerCase() || '')}
          onFocus={stores_search_focus}
        />

      </div>

      <ul
        className="collection"
      >
        {filteredStores?.map((store: any) => (
          <li key={store.uuid} className="collection-item roll_in_from_right_animation"
            onClick={(e) => {
              (e.target as HTMLLIElement)?.querySelector('a')?.click()
            }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://www.google.com/maps/place/${store.street}, ${store.num}, ${store.city} - ${store.state}`}

              onClick={store_link_click}
            >
              <div className="map_point">
                <MapPointSvg />
                <span className="primary_color_text">{buttonText}</span>
              </div>

              <div className={`text_info ${preferredStore(store) ? 'increased_height' : ''}`}>
                {
                  preferredStore(store)
                    ?
                    <span className="line primary_color_text preferred_store">
                      {
                        favoriteStoreLine
                        || 'Sua loja favorita!'
                      }
                    </span>
                    : <></>
                }

                <span className="headline line">
                  {store.businessName || ''}
                  {
                    preferredStore(store) ?
                      <StarSvg className="star" />
                      : <></>
                  }
                </span>

                <span className="line">
                  {store.street}
                  {', '}
                  {store.num}
                </span>

                <span className="line">
                  {store.city}
                  {' - '}
                  {store.state}
                  {' - CEP - '}
                  {
                    store?.zip || ''
                  }
                </span>

              </div>

            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}