import { formatFloatPrice } from '@assets/utils/formatFloatPrice'

//formats price to int if no decimals and to 2 decimals if decimal

export const formatPrice = (price: number | null) => {
  const v = formatFloatPrice(price)

  const digits = (v - Math.floor(v)) != 0 ? 2 : 0

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }).format(v)
}