import { FC, useState, useEffect, useMemo, useCallback, } from "react"

import { useAppDispatch, useAppSelector } from "@/app/hooks"

export const NewRemindersCount: FC = () => {

  const {
    reminders,
    lastViewedReminders,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const newReminders: number = useMemo(() => {

    return reminders?.filter(r => !lastViewedReminders?.includes(`${r.productId}:${r.remainingDays}`)).length || 0

  }, [reminders, lastViewedReminders])

  return (
    <div className={`CallToAction number ${newReminders > 0 ? 'visible' : ''}`}>
      {newReminders}
    </div>
  )
};
