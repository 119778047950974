import { FC, useCallback, useEffect, useMemo, useState, useRef } from "react"

import { useLocation } from "react-router-dom"
//@ts-ignore
import debounce from 'lodash.debounce'

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import { PromotionCard } from '@assets/components/PromotionCard'

import {
  trackEvent,
  trackOfferView
} from "@/features/propzMedia/propzMediaSlice"

import { promotionEvent } from "@assets/utils"

import { promotionEcomEvent } from "@assets/utils/promotionEcomEvent"

import { itemIsStored } from '@assets/utils/storage'

import './style.sass'

import {
  Offer,
} from "@assets/types"

interface Props {
  list: Offer[];
  webpushCard?: boolean;
}

import { promotionWithQRCode } from '@assets/utils'

import { WebpushCard } from "@/components"
import { useTrackedStore } from "@assets/store"
import { pdcStore } from "@assets/store/pdcStore"
import { useMediaQuery } from "usehooks-ts"

export const OffersCarousel: FC<Props> = ({ list, webpushCard }) => {

  const dispatch = useAppDispatch()

  const showSplashScreenPopup = pdcStore.use.showSplashScreenPopup()

  const customer = useTrackedStore().app.customer()

  const showRecentlyActivatedPromotionsModal = pdcStore.use.openRecentlyActivatedPromotionsModal()

  const { search } = useLocation()

  const query = useMemo(() => new URLSearchParams(search), [search])

  const isMobile = 'ontouchstart' in window

  const screen_mobile = useMediaQuery('(max-width: 768px)')

  const [carouselHeight, setCarouselHeight] = useState(0)

  const [cardWidth, setCardWidth] = useState(0)

  const [carousel_node, set_carousel_node] = useState<HTMLDivElement | null>(null)

  const [cardObserver, setCardObserver] = useState<IntersectionObserver | null>(null)

  useEffect(() => {
    if (carousel_node && window.IntersectionObserver) {
      const cardObserver = new window.IntersectionObserver(
        entries => {

          entries.forEach((entry, index) => {

            let storedLockedOffersViewed = sessionStorage.getItem('lockedOffersViewedInPopup') || ''

            const { promotion_id } = (entry.target as HTMLElement).dataset

            if (entry.isIntersecting) {

              //console.log('entry.target isIntersecting : ', entry.target)

              if (promotion_id) {

                let storedOffersViewed = itemIsStored(sessionStorage, 'offersViewed', promotion_id)

                const promotion = list?.find(p => p.id == promotion_id || promotion_id == p.uuid)

                let trackingIds = query.get('trackingIds')

                if (
                  promotion?.requiresRegisterComplete
                  && !customer.isRegisterComplete
                ) {
                  let storedLockedOffersViewed = sessionStorage.getItem('lockedOffersViewedInPopup') || ''

                  if (
                    storedLockedOffersViewed != 'true'
                    //!storedLockedOffersViewed.includes(promotion_id)
                  ) {

                    const ctaText = (entry.target as HTMLElement)?.querySelector('.locked_subheader')?.textContent

                    window.dataLayer.push({
                      event: `signup_completion_modal_card_view`,
                      ctaText,
                    })

                    /* promotionEvent({
                      event: 'signup_completion_modal_card_view'
                    }, promotion) */

                    sessionStorage.setItem('lockedOffersViewedInPopup', `true`)
                    //sessionStorage.setItem('lockedOffersViewedInPopup', `${storedLockedOffersViewed}${storedLockedOffersViewed ? ',' : ''}${promotion_id || ''}`)
                  }

                } else {
                  promotionEcomEvent({
                    event: promotion?.propertiesMap ? 'offer-group-view' : 'offer-view-pdc'
                  }, promotion)
                }

                if (!storedOffersViewed) {
                  if (
                    promotionWithQRCode(promotion)
                  ) {
                    dispatch(trackEvent({ category: 'passive_behavior', action: 'offer-view', property: promotion_id, label: 'digital' }))
                  } else {
                    dispatch(trackOfferView({ promotionIds: promotion_id, uid: window.rawUser?.uuid, trackingIds }))
                  }
                }
              }

              if (entry.target != carousel_node.lastChild) {
                cardObserver.unobserve(entry.target)
              }
            }

            if (entry.target == carousel_node.lastChild) {
              //control card width and right button appearance
              setCardWidth(entry?.target?.clientWidth ?? 0)
              setRightButton(!entry?.isIntersecting)
            }

          })
        }
        , {
          root: carousel_node,
          threshold: 0.9
        }
      )

      setCardObserver(cardObserver)

      if (carousel_node.hasChildNodes()) {
        for (const card of carousel_node.childNodes) {
          //console.log('cardObserver.observe : ', card)
          cardObserver.observe(card as Element)
        }
      }
    }
  }, [carousel_node])

  useEffect(() => {
    if (isMobile && carousel_node && !showSplashScreenPopup && showRecentlyActivatedPromotionsModal && document.getElementById('recently-activated-promotions-modal')?.contains(carousel_node)) {

      //in modal
      setTimeout(() => {
        setCarouselHeight(carousel_node.getBoundingClientRect().height * 0.9 || 466)
      }, 150)

    }
  }, [carousel_node, showSplashScreenPopup, showRecentlyActivatedPromotionsModal, list])

  const style = useMemo(() => {
    if (carouselHeight) {
      return { "--mobile-main-card-height": `${carouselHeight}px` } as React.CSSProperties
    }
    return false
  }, [carouselHeight])

  const [childIndex, setChildIndex] = useState(0)

  let scrollIntMobile: any = null
  const [scrollInt, setScrollInt] = useState<any>(null)

  const stopCarousel = () => {

    if (scrollIntMobile || scrollInt) {
      clearInterval(scrollIntMobile || scrollInt)
    }

    carousel_node?.removeEventListener('mousedown', stopCarousel)

    carousel_node?.removeEventListener('touchstart', stopCarousel)
    carousel_node?.removeEventListener('touchend', stopCarousel)
    carousel_node?.removeEventListener('touchcancel', stopCarousel)
    carousel_node?.removeEventListener('touchmove', stopCarousel)
  }

  const [rightButton, setRightButton] = useState(false)

  useEffect(() => {

    if (carousel_node) {

      setTimeout(() => {

        let rb = false

        setRightButton(rightButton => { rb = rightButton; return rightButton })

        if (rb) {

          scrollIntMobile = setInterval(() => {

            let rb = false

            setRightButton(rightButton => { rb = rightButton; return rightButton })

            setChildIndex(childIndex => rb ? childIndex + 1 : 0)
          }, window.parameters.carousel_interval || 1200)

          setScrollInt(scrollIntMobile)

          carousel_node?.addEventListener('mousedown', stopCarousel)

          carousel_node?.addEventListener('touchstart', stopCarousel)
          carousel_node?.addEventListener('touchend', stopCarousel)
          carousel_node?.addEventListener('touchcancel', stopCarousel)
          carousel_node?.addEventListener('touchmove', stopCarousel)
        }
      }, window.parameters.carousel_interval || 1200)
    }
    //cleanup
    return stopCarousel
  }, [carousel_node])


  useEffect(() => {

    if (carousel_node) {

      let scrollWidth = (carouselHeight ? carouselHeight * 0.577 : carousel_node?.children[0].getBoundingClientRect().width) + Number(window.getComputedStyle(carousel_node).getPropertyValue('column-gap').replace('px', ''))

      carousel_node?.scroll({
        top: 0,
        left: childIndex * scrollWidth,
        behavior: "smooth"
      })

    }

  }, [childIndex, carousel_node])


  const nextSlide = () => {
    setChildIndex(childIndex => childIndex + 1)
  }

  const debouncednextSlide = useMemo(
    () => debounce(nextSlide, 200)
    , [childIndex])

  const prevSlide = () => {
    stopCarousel()
    setChildIndex(childIndex => childIndex - 1)
  }

  const debouncedprevSlide = useMemo(
    () => debounce(prevSlide, 200)
    , [childIndex])

  useEffect(() => {
    //cleanup debounce functions
    return () => {
      debouncednextSlide.cancel()
      debouncedprevSlide.cancel()
    }
  }, [])

  useEffect(() => {
    return function cleanup() {
      cardObserver?.disconnect()
    }
  }, [cardObserver])

  return (
    <div className="carousel-container">

      {
        !isMobile && childIndex
        &&
        <a
          className='btn-flat left'
          onClick={debouncedprevSlide}
        >
          <i className="material-icons">chevron_left</i>
        </a>
        || ''
      }

      <div
        className={`mobile_main_carousel`}
        ref={(e: HTMLDivElement) => {
          set_carousel_node(e)
        }}
        style={{
          ...(Number(list?.length) + Number(webpushCard)) < 3
          && !screen_mobile ? {
            justifyItems: 'center',
            justifyContent: 'center',
            ...cardWidth ? {
              gridTemplateColumns: `repeat(auto-fit, ${cardWidth})`
            } : {},
          } : {}
        }}
      >
        {
          webpushCard ? <WebpushCard style={style} /> : <></>
        }

        {
          list?.map((item, i) =>
            <PromotionCard
              item={item}
              key={`mobile-${item.id}`}
              style={style}
              numberInList={i}
            />
          )
        }
      </div>

      {
        !isMobile && rightButton
        &&
        <a
          className='btn-flat right'
          onClick={() => {
            stopCarousel()
            debouncednextSlide()
          }}
        >
          <i className="material-icons">chevron_right</i>
        </a> || ''
      }

    </div>
  );
};
