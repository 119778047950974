import React, { FC, useState, useEffect, useRef, useMemo, useCallback, } from "react"

import {
  promotionHasSeals,
  promotionHasPaymentMethod,
  promotionHasMassiveSeal,
} from '@assets/utils'

import {
  Offer,
} from "@assets/types"

import './index.sass'

interface Props {
  promotion?: Offer;
  [x: string]: any,
}

export const PromotionSeals: FC<Props> = ({ promotion, children, ...rest }) => {

  const hasSeals = useMemo(() => {
    return promotionHasSeals(promotion) || children
  }, [
    promotion,
    children,
  ])

  const hasPaymentMethod = useMemo(() => promotionHasPaymentMethod(promotion), [promotion])

  const hasMassiveSeal = useMemo(() => promotionHasMassiveSeal(promotion), [promotion])

  return (
    hasSeals
      ?
      <div className="seals">

        {
          children ?
            React.Children.map(children, child => <div className="seal">{child}</div>)
            : <></>
        }

        {
          hasPaymentMethod
            ?
            <div className="seal" style={{ backgroundImage: `url(${window.parameters.glob_sealImage})` }}></div>
            : <></>
        }

        {
          hasMassiveSeal
            ?
            <div className="seal" style={{ backgroundImage: `url(${window.parameters.glob_massive_id_seal})` }}></div>
            : <></>
        }

      </div>
      : <></>
  )
}
