import React, { FC, useMemo } from "react";

import { Button } from "react-materialize"

import { useAppDispatch, useAppSelector } from "@/app/hooks";

/* SVG */
import { ReactComponent as LogoShadow } from './logo_shadow.svg'

import basket from './basket.png'
import clock from '@/assets/icons/clock.png'

import './style.sass'

export const RemindersBanner: FC = () => {

  const {
    glob_programName,
    reminders_supermarket,
    glob_programLogo,
  } = window.parameters

  const {
    reminders,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const singleReminder = useMemo(() => (reminders?.length ?? 0) > 1, [reminders])

  return (
    <div
      className={`banner reminders_banner primary_background text_primary_background ${reminders_supermarket ? '' : 'pharma'}`}
    >

      <div className="first">

        <div className="pointer secondary_background">
          {
            reminders_supermarket ?
              <span className="header">
                REABASTEÇA
              </span>
              :
              <span className="header pharma">
                VOCÊ ENCONTRA
              </span>
          }
          <span className="subheader">
            AQUI!
          </span>
        </div>

        <Button
          flat
          className="primary_color_text white"
        >
          Saiba mais
        </Button>

      </div>

      <img className="second" src={basket} alt="basket" />

      <div className={`third main_content_text ${reminders_supermarket ? '' : 'pharma'}`}>

        {
          reminders_supermarket ? '' :
            <div className="pharma text">
              {
                singleReminder ?
                  'Seu medicamento está no final!'
                  :
                  'Seus medicamentos estão no final!'
              }
            </div>
        }

        {
          reminders_supermarket ?
            <div className="supermarket header">
              {
                singleReminder ?
                  'Seu produto preferido está'
                  :
                  'Seus produtos preferidos estão'
              }
            </div>
            :
            <div className="pharma header">
              CONTINUE AGORA SEU
            </div>
        }


        {
          reminders_supermarket ?
            <div className="supermarket subheader">
              QUASE ACABANDO!
            </div>
            :
            <div className="pharma subheader">
              TRATAMENTO
            </div>
        }

        {
          reminders_supermarket ?
            <div className="supermarket text">
              {'Renove agora no '}
              <span className="program_name">{glob_programName}</span>
              {' para não ficar sem!'}
            </div>
            :
            <div className="pharma text">
              {'Compre com as vantagens '}
              <span className="program_name">{glob_programName}</span>
            </div>
        }

        <div className={`caption ${reminders_supermarket ? '' : 'pharma'}`}>
          <img src={clock} />
          {
            reminders_supermarket ?
              'NÃO DEIXE PARA DEPOIS!'
              :
              'NÃO PERCA TEMPO!'
          }
        </div>
      </div>

      <div className="logo_container">
        <LogoShadow className="background" />

        <img
          className="logo_img"
          src={glob_programLogo}
        />
      </div>

    </div >
  );
};
