import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

import { regStepEnabled } from "@/utils/internal functions"

type registrationPropsType = ('firstName' |
  'lastName' |
  'homeEmail' |
  'mobilePhone' |
  'businessCategory' |
  'profession' |
  'homeZip' |
  'homeStreet' |
  'homeNum' |
  'homeDistrict' |
  'homeCity' |
  'homeState' |
  'dateOfBirth' |
  'gender')[]

export const registrationProps = () =>{

  const customer = store.app.customer()

  return (
    [
      'firstName',
      'lastName',
      'homeEmail',
      'mobilePhone',
      ...regStepEnabled('category').length ? ['businessCategory'] : [],
      ...regStepEnabled('profession').length ? ['profession'] : [],
      ...regStepEnabled('endereco').length ? [
        'homeZip',
        'homeStreet',
        'homeNum',
        'homeDistrict',
        'homeCity',
        'homeState',
      ] : [],

      ...customer?.customerId && customer?.customerId.length == 14 ? [] : [
        ...regStepEnabled('data-nascimento').length ? [
          'dateOfBirth',
        ] : [],

        ...regStepEnabled('genero').length ? [
          'gender',
        ] : [],
      ]

    ]
  ) as registrationPropsType
}