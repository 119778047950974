import React, { FC, useEffect, useState } from "react"
import { Modal } from "react-materialize"
import { useAppDispatch, useAppSelector } from "@/app/hooks"

import { HeaderNew } from "@/components"
import {
  setPropzMediaStateParameter,
} from "@/features/propzMedia/propzMediaSlice"

import { gaTagSendRoutes } from "@/utils/analytic"

import contactImage from './contact-image.jpg'

import { ReactComponent as PuzzleSVG } from '@assets/icons/puzzle.svg'

import "./style.css"

export const NotAuthenticatePage: FC = () => {
  const dispatch = useAppDispatch()
  const {
    glob_programName,
    glob_retailerLogo,
    glob_retailerEmail,
    glob_retailerPhone,
  } = window.parameters

  const {
    showContact,
  } = useAppSelector((state) => state.propzMedia)

  const [width, setWidth] = useState<number>(window.innerWidth)
  const [height, setHeight] = useState<number>(0)

  const handleShowContact = () => {
    dispatch(setPropzMediaStateParameter({ showContact: true }))
    setTimeout(() => {
      const heightModal = document.getElementById("newContact")?.offsetHeight;
      if (heightModal) {
        setHeight(heightModal)
      }
    }, 100)
  }

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    gaTagSendRoutes("notAuth");
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 992;

  return (
    <div className="root">
      <HeaderNew />
      <div className="center wrapper">
        <div className="icon hide-on-med-and-down">
          <PuzzleSVG />
        </div>
        <div className="content">
          <p className="labelTitle">Não conseguimos localizar seu cadastro.</p>
          <div className="icon hide-on-large-only">
            <PuzzleSVG />
          </div>
          <p className="labelText">
            Se você já faz parte do programa {glob_programName}, acesse o Portal do
            Cliente através do link que enviamos quando você se cadastrou ou
            procure uma loja física para atualizar seus dados.
          </p>

          {
            !window.inIframe
              ?
              <div className="not_auth_fc_link" onClick={() => dispatch(setPropzMediaStateParameter({ showFaleConoscoModal: true }))}>Fale Conosco</div>
              : ''
          }

          <div className="footer">
            {glob_retailerLogo && (
              <img
                src={glob_retailerLogo}
                alt="Retailer logo"
                className="retailerLogo"
              />
            )}
            <p
              className="hide-on-large-only contactFooter"
              onClick={handleShowContact}
            >
              Contato
            </p>
          </div>
        </div>
      </div>
      <Modal
        id="newContact"
        actions={[]}
        open={showContact}
        options={{
          dismissible: true,
          endingTop: "15%",
          onCloseEnd: () => dispatch(setPropzMediaStateParameter({ showContact: false })),
        }}
        bottomSheet={isMobile}
      >
        <div className="hide-on-med-and-down lgModalContact">
          {/* <div className="contactImage" /> */}
          <div>
            <img src={contactImage} alt="contact-image" />
          </div>
          <div className="lgModalContact_content">
            <i
              className="material-icons right"
              onClick={() =>
                dispatch(setPropzMediaStateParameter({ showContact: false }))
              }
              style={{
                cursor: "pointer",
              }}
            >
              close
            </i>
            <div className="lgModalContact_data">
              <p className="lgModalContact_title">Contato</p>
              {glob_retailerEmail && (
                <a
                  href={`mailto:${glob_retailerEmail}`}
                  className="lgModalContact_value"
                >
                  {glob_retailerEmail}
                </a>
              )}
              {glob_retailerPhone && (
                <a
                  href={`tel:${glob_retailerPhone}`}
                  className="lgModalContact_value"
                >
                  {glob_retailerPhone}
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="hide-on-large-only mdModalContact">
          <div className="center mdModalContactHeader modalHeader">
            <p className="mdModalContactTitle">Contato</p>
            <i
              className="material-icons right"
              onClick={() =>
                dispatch(setPropzMediaStateParameter({ showContact: false }))
              }
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "20px",
                top: "14px",
                fontSize: "22px",
              }}
            >
              close
            </i>
          </div>
          <div
            className="mdModalContactContent"
            style={{ height: isMobile ? height - 54 : "auto" }}
          >
            {glob_retailerEmail && (
              <a
                href={`mailto:${glob_retailerEmail}`}
                className="lgModalContact_value"
              >
                {glob_retailerEmail}
              </a>
            )}
            {glob_retailerPhone && (
              <a
                href={`tel:${glob_retailerPhone}`}
                className="lgModalContact_value"
              >
                {glob_retailerPhone}
              </a>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
