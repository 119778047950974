import { FC, useEffect, useMemo, useState, useCallback, } from "react"

import { useLocation } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import { promotionEvent } from "@assets/utils/promotionEvent"

import { promotionEcomEvent } from "@assets/utils/promotionEcomEvent"

import { promotionListEcomEvent } from '@assets/utils/promotionListEcomEvent'

import {
  trackEvent,
  trackOfferView,
} from "@/features/propzMedia/propzMediaSlice"

import {
  actions,
  useTrackedStore,
  useStore,
} from '@assets/store'

import {
  OffersCarousel,
  PromotionBarAppCTA,
  TopCarousel,
} from '@/components'

import { PromotionCard } from '@assets/components/PromotionCard'

import "./style.sass"

import { NoOffers } from "./components/NoOffers"

import { ReactComponent as CartSVG } from './assets/cart.svg'

import { promotionWithQRCode } from '@assets/utils/promotionWithQRCode'

import { itemIsStored } from '@assets/utils/storage'

import { pdcStore } from "@assets/store/pdcStore"
import { LoadingCircle } from "@assets/components/LoadingCircle"
import { useMediaQuery } from "usehooks-ts"
import { useApp } from "@assets/hooks/useApp"
import { add_to_cart } from '@assets/API/v1/add_to_cart'

import { useDebounceCallback } from "usehooks-ts"

const observedTargets: string[] = []

export const Offers: FC = () => {

  const dispatch = useAppDispatch()

  const { search, pathname } = useLocation()

  const {
    reminders,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const showEcommerceCartSuccess = pdcStore.use.showEcommerceCartSuccess()

  const [show_promotion_bar_app_cta, _] = useApp('promotionbar')

  const two_promotions_in_row = useMediaQuery('(max-width: 874px)')

  const four_promotions_in_row = useMediaQuery('(min-width: 1193px)')

  const three_promotions_in_row = useMediaQuery('(min-width: 875px) and (max-width: 1192px)')

  const quantity_of_promotions_in_two_rows = useMemo(() => {
    if (two_promotions_in_row) return 4
    if (three_promotions_in_row) return 6
    if (four_promotions_in_row) return 8
    return 8
  }, [
    two_promotions_in_row,
    three_promotions_in_row,
    four_promotions_in_row,
  ])

  const marketplaceEnable = pdcStore.use.marketplaceEnable()

  const mainPromotions = pdcStore.use.mainPromotions()

  const bannerPromotions = pdcStore.use.bannerPromotions()

  const showcaseList = pdcStore.use.showcaseList()

  const stores = useStore().app.stores()

  const customer = useTrackedStore().app.customer()

  const ecommerceCart = pdcStore.use.ecommerceCart()

  const showRecentlyActivatedPromotionsModal = pdcStore.use.openRecentlyActivatedPromotionsModal()

  const query = useMemo(() => new URLSearchParams(search), [search])

  //open promotion stores modal if there is promotionId in search

  const promotionId = useMemo(() => query.get("promotionId"), [])

  useEffect(() => {
    if (promotionId && showcaseList) {
      const promo = showcaseList.find((item: any) => item.id == promotionId || item.partnerId == promotionId);
      if (promo) {
        actions.app.CurrentOfferStoresModalPromotion(promo)

        actions.app.currentOfferStoresModalOpened(true)
      }
    }
  }, [promotionId, showcaseList])

  //open promotion conditions modal if there is promotionCondition in search

  const promotionCondition = useMemo(() => query.get('promotionCondition'), [])

  useEffect(() => {
    if (promotionCondition && showcaseList) {
      const item = showcaseList.find((item: any) => item.id == promotionCondition || item.partnerId == promotionCondition);
      if (item) {
        actions.app.OfferConditionsModalItem(item)
        actions.app.OfferConditionsModalOpened(true)
      }
    }
  }, [promotionCondition, showcaseList])

  const itemsToRenderAtOnce = 10

  //track last loaded Promotion number to render +10 of them
  const [lastLoadedPromotion, setLastLoadedPromotion] = useState(0)

  const renderedMainPromotions = useMemo(() => {

    return mainPromotions?.slice(0, lastLoadedPromotion + itemsToRenderAtOnce)
  }, [lastLoadedPromotion, mainPromotions])

  const [viewedItems, setViewedItems] = useState<HTMLElement[]>([])

  //IntersectionObserver instance
  const observer = useMemo(() => {

    return window.IntersectionObserver ? new window.IntersectionObserver((entries) => {

      entries.forEach(entry => {

        if (entry.isIntersecting) {
          const { numberin_list, promotion_id } = (entry.target as HTMLElement).dataset

          //watch last loaded number to add more offers to render
          setLastLoadedPromotion(lastLoadedPromotion => {
            return Math.max(Number(numberin_list), lastLoadedPromotion)
          })

          //viewed event track
          if (promotion_id) {

            let storedOffersViewed = itemIsStored(sessionStorage, 'offersViewed', promotion_id)

            let trackingIds = query.get('trackingIds')

            let promotion = pdcStore.get.showcaseList()?.find(p => p.id == promotion_id)

            if (
              promotion?.requiresRegisterComplete
              && !customer.isRegisterComplete
            ) {
              setTimeout(() => {
                //setViewedItems(vi => [...vi, promotion_id])
                setViewedItems(vi => [...vi, entry.target as HTMLElement])
              }, 300)

            } else {
              promotionEcomEvent({
                event: 'offer-view-pdc',
              }, promotion)
            }

            if (!storedOffersViewed) {
              if (
                promotionWithQRCode(promotion)
              ) {
                dispatch(trackEvent({ category: 'passive_behavior', action: 'offer-view', property: promotion_id, label: 'digital' }))
              } else {
                dispatch(trackOfferView({ promotionIds: promotion_id, uid: window.rawUser?.uuid, trackingIds }))
              }
            }
          }
          observer?.unobserve(entry.target)
        }
      })
    }, {
      root: document.body,
      threshold: 0.9
    }) : null
  }, [])

  useEffect(() => {
    //to trigger event only after popup closes
    if (
      !showRecentlyActivatedPromotionsModal
      && viewedItems.length
      && !pathname.includes('/cadastro')
      && !pathname.includes('webpush-pagina')
    ) {
      viewedItems.forEach(card => {
        let storedLockedOffersViewed = sessionStorage.getItem('lockedOffersViewed') || ''

        const { promotion_id } = card.dataset

        if (promotion_id && !storedLockedOffersViewed.includes(promotion_id)) {

          let promotion = mainPromotions?.find(promo => promo.id == promotion_id)

          const ctaText = card.querySelector('.locked_subheader')?.textContent

          promotionEvent({
            event: `signup_completion_offer_card_view`,
            ctaText,
          }, promotion)

          sessionStorage.setItem('lockedOffersViewed', `${storedLockedOffersViewed}${storedLockedOffersViewed ? ',' : ''}${promotion_id || ''}`)

          setViewedItems(vi =>
            vi.filter(i => card.dataset.promotion_id != promotion_id)
          )

        }
      })
    }
  }, [viewedItems, showRecentlyActivatedPromotionsModal])

  //refs to main cards price to track observation
  const [mainCardsRefs, setMainCardsRefs] = useState<Array<HTMLDivElement | null>>([])

  useEffect(() => {
    if (observer) {
      mainCardsRefs.forEach((el, i, a) => {
        if (el) {
          const { promotion_id } = el.dataset

          if (promotion_id && !observedTargets.includes(promotion_id)) {

            //add to observer only once per render for each el

            observedTargets.push(promotion_id)

            observer.observe(el)
          }
        }
      })
    }
  }, [mainCardsRefs, observer])

  useEffect(() => {
    return function cleanup() {
      observer?.disconnect()
      observedTargets.length = 0
    }
  }, [])

  const noPromotions = useMemo(() => {
    return (
      !showcaseList?.length
      && !window.offersGroup?.length
      && !window.parameters.bannersList?.length
      && !window.parameters.bannersList?.length
      && !marketplaceEnable
      && !reminders?.length
    )
  }, [showcaseList])

  //store to show at top line for favorite store
  const store_selected = useMemo(() => {
    if (customer?.preferredStore) {
      return stores?.find(store => store.storeId == customer?.preferredStore)
    }
    if (customer?.registrationStoreId) {
      return stores?.find(store => store.storeId == customer?.registrationStoreId)
    }
    return null
  }, [customer, stores])

  const ecom_button_disabled = useMemo(() => ecommerceCart.reduce((a, b) => a + b.quantity, 0) == 0, [ecommerceCart])

  useEffect(() => {
    if (mainPromotions) {
      const chunkSize = 15
      for (let i = 0; i < mainPromotions.length; i += chunkSize) {
        promotionListEcomEvent({
          event: 'offer-allocation'
        }, mainPromotions.slice(i, i + chunkSize), i)
      }
    }
  }, [mainPromotions])

  useEffect(() => {
    const chunkSize = 15
    for (let i = 0; i < window.offersGroup.length; i += chunkSize) {
      promotionListEcomEvent({
        event: 'offer-group-allocation'
      }, window.offersGroup.slice(i, i + chunkSize), i)
    }
  }, [])

  const add_to_cart_button_click = useCallback(useDebounceCallback(() => {
    if (!ecom_button_disabled) {
      add_to_cart()
    }
  }), [ecom_button_disabled])

  const add_to_cart_button = useMemo(() => showEcommerceCartSuccess ? <></> : <div
    className={`primary_background text_primary_background common_text_button common_button_font_size common_button_border_radius common_button_shadow sendEcommerceCart_button ${!window.parameters.show_virtual_view_offers_as_carousel ? 'floating_button' : ''} ${ecom_button_disabled ? 'disabled' : ''}`}

    onClick={add_to_cart_button_click}
  >
    Adicionar item ao carrinho
  </div>, [
    ecom_button_disabled,
    add_to_cart_button_click,
    showEcommerceCartSuccess,
  ])

  return (
    <div className={`offers_page`}>

      {
        window.inIframe ?
          <div className="disclaimer primary_background text_primary_background">
            O desconto será aplicado ao finalizar sua compra, na etapa de pagamento.
          </div> : <></>
      }

      {
        window.inIframe
          && mainPromotions?.length
          && !window.parameters.show_virtual_view_offers_as_carousel
          ?
          add_to_cart_button
          : <></>
      }

      <div className={`offers_scrollable_content ${window.inIframe ? 'inIframe' : ''}`}>

        {
          !window.parameters.disableStoreSelector
            && !showEcommerceCartSuccess
            ?
            <div className="preferred_store_line" onClick={(e) => {
              e.preventDefault()
              pdcStore.set.StoreSelectModalOpened(true)
            }}
            >
              {
                store_selected ?
                  <>
                    {"Sua loja favorita: "}
                    <span className="primary_color_text" style={{ fontWeight: 500 }}>
                      {store_selected?.businessName} ({store_selected?.city} - {store_selected?.state})
                    </span>
                  </>
                  :
                  <>
                    <span className="primary_color_text" style={{ fontWeight: 500 }}>
                      {"Selecione aqui "}</span>
                    sua loja favorita para ver suas ofertas
                  </>

              }
            </div>
            : ''
        }

        {
          window.inIframe ?
            <>
              {
                window.parameters.enable_virtual_view_banners
                  && !showEcommerceCartSuccess
                  ?
                  <TopCarousel />
                  : ''
              }

              {
                mainPromotions?.length ?
                  (
                    showEcommerceCartSuccess ?
                      <div className="addedToEcommerceCart">
                        <picture>
                          <CartSVG />
                        </picture>
                        <h5>Seus itens lhe aguardam em seu carrinho.</h5>

                        <h5>Boas compras!</h5>

                        <div
                          className={`primary_background text_primary_background common_text_button common_button_font_size common_button_border_radius common_button_shadow sendEcommerceCart_button `}

                          onClick={() => pdcStore.set.showEcommerceCartSuccess(false)}
                        >
                          Voltar
                        </div>
                      </div>
                      :
                      <>
                        {
                          window.parameters.show_virtual_view_offers_as_carousel ?
                            <>
                              <div className={`bottom-carousel-container inIframe`}>
                                <OffersCarousel
                                  list={mainPromotions}
                                />
                              </div>

                              {add_to_cart_button}
                            </>
                            :
                            <div className={`vertical_promotions_container inIframe`}>
                              <div className="vertical_promotions"
                                style={{
                                  padding: '24px 0',
                                }}>
                                {
                                  renderedMainPromotions?.map((item, i) =>
                                    <PromotionCard
                                      item={item}
                                      key={`vertical-${item.id}`}
                                      numberInList={i}
                                      observerRef={(e: HTMLDivElement) => {
                                        setMainCardsRefs(mainCardsRefs => [...mainCardsRefs, e])
                                      }}
                                    />
                                  )
                                }
                              </div>
                            </div>
                        }
                      </>
                  )
                  : (showcaseList ? <NoOffers /> : <LoadingCircle />)
              }
            </>
            :

            showcaseList ? (
              noPromotions ?
                <NoOffers />

                :

                <>

                  <TopCarousel list={bannerPromotions} />

                  {
                    window.offersGroup?.length
                      ?
                      <div className="bottom-carousel-container ">
                        <h3>
                          Ofertas em destaque
                        </h3>

                        <OffersCarousel
                          list={window.offersGroup}
                        />
                      </div>
                      : ''
                  }

                  {
                    renderedMainPromotions?.length ?
                      <div className="vertical_promotions_container">
                        <h3>
                          Ofertas que você pode gostar
                        </h3>
                        <div className="vertical_promotions">
                          {
                            renderedMainPromotions.slice(0, quantity_of_promotions_in_two_rows).map((item, i) =>
                              <PromotionCard
                                item={item}
                                key={`vertical-${item.id}`}
                                numberInList={i}
                                observerRef={(e: HTMLDivElement) => {
                                  setMainCardsRefs(mainCardsRefs => [...mainCardsRefs, e])
                                }}
                              />
                            )
                          }
                          {
                            renderedMainPromotions.length > quantity_of_promotions_in_two_rows ?
                              <>
                                {
                                  window.parameters.glob_promotion_bar_app_cta
                                    && show_promotion_bar_app_cta ?
                                    <PromotionBarAppCTA />
                                    : <></>
                                }
                                {
                                  renderedMainPromotions.slice(quantity_of_promotions_in_two_rows).map((item, i) =>
                                    <PromotionCard
                                      item={item}
                                      key={`vertical-${item.id}`}
                                      numberInList={i + quantity_of_promotions_in_two_rows}
                                      observerRef={(e: HTMLDivElement) => {
                                        setMainCardsRefs(mainCardsRefs => [...mainCardsRefs, e])
                                      }}
                                    />
                                  )
                                }
                              </>
                              : <></>
                          }
                        </div>
                      </div>
                      : ''
                  }

                </>)
              :
              <LoadingCircle />

        }

      </div>
    </div >
  );
};
