import { FC, useEffect, useState, useLayoutEffect } from 'react'

import {
  useHistory,
  useLocation
} from "react-router-dom"

import { Helmet } from "react-helmet"

//@ts-ignore
import StringMask from 'string-mask'

import { format } from 'date-fns'

import { Button, Modal } from "react-materialize"

import { useAppDispatch, useAppSelector } from "@/app/hooks"
import {
  requestExportData,
} from "@/features/propzMedia/propzMediaSlice"

import { ReactComponent as CheckSVG } from '../check.svg'
import { ReactComponent as CheckFilledSVG } from '../check_filled.svg'

import { regStepEnabled } from '@/utils/internal functions'

import { parseDate } from '@assets/utils'

import { DisableOverflowScrolling } from '@assets/components/DisableOverflowScrolling'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

export const View: FC = () => {

  const missingInfo = <span className='missing_info'>Informação pendente</span>

  const history = useHistory()

  const dispatch = useAppDispatch()

  const { search } = useLocation()

  const customer = useTrackedStore().app.customer()

  const [openMissingEmail, setOpenMissingEmail] = useState(false)
  const [openExtractPopup, setOpenExtractPopup] = useState(false)

  const export_data = () => {
    if (!customer?.homeEmail) {
      setOpenMissingEmail(true)
    }
    else {
      setOpenExtractPopup(true)
      dispatch(requestExportData())
    }

  }

  return (
    <>
      <div className="info_wrapper view_form roll_in_from_bottom_animation">

        <Helmet>
          <title>Dados cadastrado</title>
          <meta name="description" content="Acesso ao cadastro do usuário." />
        </Helmet>

        <div className="info">
          {/* Left column */}
          <div className='info_column'>

            {/* CPF/CNPJ & Name */}
            <div className="info_block">

              <div className="info_block_header filled">
                {
                  (
                    !!customer?.customerId
                    &&
                    !!(
                      window.userIsFisicalPerson ?
                        customer?.firstName
                        :
                        customer?.firstName || customer?.lastName
                    )
                  ) ? <CheckFilledSVG /> : <CheckSVG />
                }
                Identificação
              </div>
              <div className="info_block_content">
                <div className="info_block_content_header">
                  {
                    window.userIsFisicalPerson ? 'CPF' : 'CNPJ'
                  }
                </div>
                <span className='info_block_content_data'>
                  {
                    customer?.customerId
                      ?
                      (
                        window.userIsFisicalPerson
                          ?
                          StringMask.apply(customer?.customerId, '000.000.000-00')
                          :
                          StringMask.apply(customer?.customerId, '00.000.000/0000-00')
                      )
                      :
                      missingInfo
                  }
                </span>

                <div className="info_block_content_header">
                  {
                    window.userIsFisicalPerson ? 'Nome' : 'Razão social'
                  }
                </div>
                <span className='info_block_content_data'>
                  {
                    `${window.userIsFisicalPerson
                      ?
                      (
                        `${customer?.firstName
                          ?
                          `${customer?.firstName} ` : ''}${customer?.lastName || ''}`
                      )
                      :
                      customer?.firstName
                    }`
                    ||
                    missingInfo
                  }
                </span>
              </div>

            </div>

            {/* Phone & Email */}
            <div className="info_block">

              <div className="info_block_header">
                {
                  (
                    !!customer?.mobilePhone
                    &&
                    customer?.homeEmail
                  )
                    ? <CheckFilledSVG /> : <CheckSVG />
                }
                Contato
              </div>
              <div className="info_block_content">
                <div className="info_block_content_header">
                  Celular
                </div>
                <span className='info_block_content_data'>
                  {
                    customer?.mobilePhone
                      ?
                      StringMask.apply(customer.mobilePhone.replace('+55', '')?.replace(/\D/g, ''), '(00) 00000-0000')
                      :
                      missingInfo
                  }
                </span>

                <div className="info_block_content_header">
                  E-mail
                </div>
                <span className='info_block_content_data'>
                  {
                    customer?.homeEmail
                    ||
                    missingInfo
                  }
                </span>
              </div>

            </div>

            {/* Category */}
            {
              regStepEnabled('category').length ?
                <div className="info_block">
                  <div className="info_block_header">
                    {
                      !!customer?.businessCategory
                        ? <CheckFilledSVG /> : <CheckSVG />
                    }
                    Categoria
                  </div>
                  <div className="info_block_content">
                    <span className='info_block_content_data'>
                      {
                        customer?.businessCategory
                        || missingInfo
                      }
                    </span>
                  </div>
                </div>

                : ''
            }

          </div>


          {/* Right column */}
          <div className='info_column'>
            {/* Profession */}
            {
              regStepEnabled('profession').length ?
                <div className="info_block">
                  <div className="info_block_header">
                    {!!customer?.profession ? <CheckFilledSVG /> : <CheckSVG />}
                    Profissão
                  </div>
                  <div className="info_block_content">
                    <span className='info_block_content_data'>
                      {customer?.profession || missingInfo}
                    </span>
                  </div>
                </div>
                : ''
            }

            {/* CEP */}
            {
              regStepEnabled('endereco').length ?
                <div className="info_block">

                  <div className="info_block_header">
                    {
                      customer?.homeZip
                        ?
                        <CheckFilledSVG />
                        :
                        <CheckSVG />
                    }
                    Endereço
                  </div>
                  <div className="info_block_content">

                    {
                      !!customer?.homeZip
                        ?
                        <>
                          <span className='info_block_content_data'>
                            CEP {
                              StringMask.apply(customer.homeZip.replace('+55', '')?.replace(/\D/g, ''), '00000-000')
                            }
                          </span>
                          <span className='info_block_content_data'>
                            {
                              customer?.homeStreet || ''
                            } {
                              customer?.homeNum?.replace(/\D/g, '') || ''
                            } {
                              customer?.homeComplement || ''
                            }
                          </span>
                          <span className='info_block_content_data'>
                            {
                              customer?.homeDistrict
                            }
                          </span>
                          <span className='info_block_content_data'>
                            {
                              customer?.homeCity || ''
                            } - {
                              customer?.homeState || ''
                            }
                          </span>
                        </>
                        :
                        <span className='info_block_content_data'>
                          {missingInfo}
                        </span>
                    }

                  </div>

                </div>
                : ''
            }

            {
              window.userIsFisicalPerson
                ?
                <>
                  {/* Birthday */}
                  {
                    regStepEnabled('data-nascimento').length ?
                      <div className="info_block">
                        <div className="info_block_header">
                          {
                            customer?.dateOfBirth
                              ?
                              <CheckFilledSVG />
                              :
                              <CheckSVG />
                          }
                          Nascimento
                        </div>
                        <div className="info_block_content">

                          <span className='info_block_content_data'>
                            {
                              customer?.dateOfBirth
                                ?
                                format(parseDate(customer?.dateOfBirth), 'dd/MM/yyyy')
                                :
                                missingInfo
                            }
                          </span>
                        </div>
                      </div>
                      : ''
                  }

                  {/* Gender */}
                  {
                    regStepEnabled('genero').length ?
                      <div className="info_block">
                        <div className="info_block_header">
                          {
                            customer?.gender
                              ?
                              <CheckFilledSVG />
                              :
                              <CheckSVG />
                          }
                          Gênero
                        </div>
                        <div className="info_block_content">

                          <span className='info_block_content_data'>
                            {
                              customer?.gender
                                ?
                                {
                                  'M': 'Masculino',
                                  'F': "Feminino",
                                  'O': 'Outro',
                                  'P': 'Prefiro não dizer'
                                }[customer?.gender]
                                :
                                missingInfo
                            }
                          </span>
                        </div>
                      </div>
                      : ''
                  }
                </>
                : ''
            }

          </div>

        </div>

        <form>
          <div className="buttons">

            <Button
              flat
              waves="light"
              className="primary_color_text common_button_border_radius common_button_font_size_desktop primary_border left_button"
              onClick={(e) => {
                e?.preventDefault()
                e?.stopPropagation()
                export_data()
              }}
            >
              <DisableOverflowScrolling />
              Baixar histórico de compras
            </Button>

            <Button
              flat
              waves="light"
              className="white-text edit_button"
              onClick={() => {
                history.push(`/cadastro/alterar${search}`)

                window.dataLayer.push({
                  event: "Click-Editar-Cadastro",
                })
              }}
            >
              Alterar minhas informações
            </Button>

          </div>
        </form>
      </div>

      <Modal
        id="missing_email_popup"
        //@ts-ignore
        className="information_popup"
        open={openMissingEmail}

        options={{
          dismissible: true,
          onCloseEnd: () => {
            setOpenMissingEmail(false)
            //history.push(`/cadastro/alterar${search}`)
          }
        }}

        actions={[

          <Button flat modal="close" node="button" className="primary_background text_primary_background common_text_button common_button_font_size"
            onClick={() => {
              setOpenMissingEmail(false)
              history.push(`/cadastro/alterar${search}`)
            }}
          >
            Completar meus dados cadastrais
          </Button>

        ]}

        header='Ops! Não temos seu e-mail cadastrado...'

      >
        <Button
          flat
          className="white close_button"

          onClick={() => setOpenMissingEmail(false)}
        >
          <i className="material-icons">close</i>
        </Button>

        <p>
          Precisamos do seu endereço de e-mail para enviar o link com a cópia do seu histórico de compras
        </p>

      </Modal>

      <Modal
        id="extract_data_popup"
        //@ts-ignore
        className="information_popup"
        open={openExtractPopup}

        options={{
          dismissible: true,
          onCloseEnd: () => {
            setOpenExtractPopup(false)
          }
        }}

        actions={[

          <Button flat modal="close" node="button" className="primary_background text_primary_background common_text_button common_button_font_size"
            onClick={() => {
              setOpenExtractPopup(false)
            }}
          >
            Ver meus dados cadastrados
          </Button>

        ]}

        header='Solicitação realizada com sucesso!'

      >

        <Button
          flat
          className="white close_button"

          onClick={() => setOpenExtractPopup(false)}
        >
          <i className="material-icons">close</i>
        </Button>

        <p>
          Em até 7 dias será enviado por e-mail um link para download da cópia do seu histórico de compras
        </p>

      </Modal>
    </>
  )
}