import {
  Range,
} from '@assets/types/generics'

type HundredRange = Range<0, 100>

export const pdc_local_storage = {
  ab_tests: {
    baloon_topbar_popup: {
      name: 'baloon-topbar_popup',
      cases: [
        {
          name: 'showWebpushBaloonPopup',
          weight: 50 as HundredRange
        },
        {
          name: 'showWebpushTopPopup',
          weight: 50 as HundredRange
        }
      ],
    },

  },
}

//creds to use for events in wifi templates
export const userType_creds = [localStorage, 'userType'] as const