import { FC } from "react"

import { useAppSelector } from "@/app/hooks"

import './style.sass'

interface Props {
  image: any,
  color?: any,
  className?: any,
  [x:string]: any
}
//icon will have the provided color or the primary color
export const Icon: FC<Props> = ({ image, color, className, ...rest }) => {

  return (

    <div
      className={`icon_component ${className ?? ''}`}
      style={{
        "-webkit-mask-image": `url(${image})`,
        ...color ? {backgroundColor: color} : {}
      } as React.CSSProperties}
      {...rest}
    ></div>
  )
}