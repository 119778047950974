import React, { FC, useEffect, useMemo, useState } from "react"

/* Materialize components */
import { CardPanel } from "react-materialize"

import "./style.sass"

interface FullPageCardProps {
  children?: React.ReactNode,
  className?: string
}

export const FullPageCard: FC = (props: FullPageCardProps) => {

  return (
    <div className="FullPageCard_wrapper" >
      <CardPanel className={`FullPageCard_card ${props.className}`} >
        {props.children}
      </CardPanel>
    </div>
  )
}