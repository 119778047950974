import {
  Offer,
} from '@assets/types'

import { checkStorageListAndStore } from '@assets/utils/storage'

import { promotionEcomData } from '@assets/utils/promotionEcomData'

export const promotionListEcomEvent = (eventProps: {
  event: string;
  [x: string]: any;
}, promotions: Offer[], index?: number, ecommerce?: { [x: string]: any }) => {

  //index - starting index of list

  if (
    !checkStorageListAndStore(sessionStorage, eventProps.event, promotions.map(p => p.id || p.uuid).join(','))
  ) {

    window.dataLayer.push({ ecommerce: null })

    window.dataLayer.push({
      ...eventProps,

      ecommerce: Object.assign({
        currency: "BRL",
        items: promotions.map((item, i, a) => promotionEcomData(item, (index ?? 0) + i + 1, 1))
      }, ecommerce ?? {})
    })
  }
}