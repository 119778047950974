import React, { FC, useState, useEffect, useRef, useMemo, useCallback, } from "react"

import {
  promotionCardTopTagContent,
} from '@assets/functions'

import {
  formatPrice,
  formatPercent,
} from '@assets/utils'

import {
  Offer,
} from "@assets/types"

interface Props {
  promotion?: Offer;
  [x: string]: any,
}

export const PromotionPrices: FC<Props> = ({ promotion, children, ...rest }) => {

  const topTag = useMemo(() => promotionCardTopTagContent(promotion), [promotion])

  const mechanic = useMemo(() => promotion?.mechanic || promotion?.propertiesMap?.mechanic, [promotion])

  const price_unit = useMemo(() => promotion?.properties?.PRICE_UNIT ? `/${promotion?.properties?.PRICE_UNIT}` : '', [promotion?.properties?.PRICE_UNIT])

  return (
    <div {...rest}>

      <div className={`product-line-one ${(
        [
          "reduced_price",
          "virtual_pack",
          "get_and_pay",
          "virtual_pack_money_off",
          'virtual_pack_fixed_price',
        ].includes(mechanic) ||
        (
          [
            "money_off",
            "percent_off",
          ].includes(mechanic)
          && (
            promotion?.originalPrice
            || promotion?.propertiesMap?.originalPrice
          )
          && (
            promotion?.finalPrice
            || promotion?.propertiesMap?.finalPrice
          )
          && (
            promotion?.originalPrice != promotion?.finalPrice
            || promotion?.propertiesMap?.originalPrice != promotion?.propertiesMap?.finalPrice
          )
        )
      )
        && (
          promotion?.originalPrice != promotion?.finalPrice
          || promotion?.propertiesMap?.originalPrice != promotion?.propertiesMap?.finalPrice
        )
        ? 'product-original-price' : ''}`}>

        <span style={{ opacity: 0, width: 0, display: 'inline-flex' }}>A</span>

        {
          (
            [
              "reduced_price",
              "virtual_pack",
              "get_and_pay",
              "virtual_pack_money_off",
              'virtual_pack_fixed_price',
            ].includes(mechanic) ||
            (
              [
                "money_off",
                "percent_off",
              ].includes(mechanic)
              && (promotion?.originalPrice || promotion?.propertiesMap?.originalPrice)
              &&
              (promotion?.finalPrice || promotion?.propertiesMap?.finalPrice)
            )
          )
            && (
              promotion?.originalPrice != promotion?.finalPrice
              || promotion?.propertiesMap?.originalPrice != promotion?.propertiesMap?.finalPrice
            )
            ? formatPrice(promotion?.originalPrice || promotion?.propertiesMap?.originalPrice)
            : ""
        }

        {
          [
            "promote_regular_price",
          ].includes(mechanic)
            || (
              [
                'cashback_product_money',
                'cashback_product_percent',
              ].includes(mechanic)
              && promotion?.finalPrice
            )
            ? 'Por apenas' : ''
        }
      </div>

      <div className={`product-final-price ${window.inIframe ? 'inIframe' : ''}`}
      >
        {
          mechanic === "promote_regular_price" ?
            `${formatPrice((promotion?.originalPrice || promotion?.propertiesMap?.originalPrice) as number)}${price_unit}`
            : ''
        }

        {
          [
            "reduced_price",
            "virtual_pack_fixed_price",
          ].includes(mechanic)
            || (
              mechanic === "money_off"
              && (promotion?.originalPrice || promotion?.propertiesMap?.originalPrice)
              && (promotion?.finalPrice || promotion?.propertiesMap?.finalPrice)
              && (
                promotion?.originalPrice != promotion?.finalPrice
                || promotion?.propertiesMap?.originalPrice != promotion?.propertiesMap?.finalPrice
              )
            )
            ?
            `${formatPrice((promotion?.finalPrice || promotion?.propertiesMap?.finalPrice) as number)}${price_unit}`
            : ""
        }

        {
          mechanic === "percent_off" ?
            (
              (
                promotion?.originalPrice
                || promotion?.propertiesMap?.originalPrice
              )
                && (
                  promotion?.finalPrice
                  || promotion?.propertiesMap?.finalPrice
                )
                && (
                  promotion?.originalPrice != promotion?.finalPrice
                  || promotion?.propertiesMap?.originalPrice != promotion?.propertiesMap?.finalPrice
                )
                ?
                formatPrice((promotion?.finalPrice || promotion?.propertiesMap?.finalPrice) as number)
                :
                `${formatPercent(Math.floor(promotion?.discountPercent || promotion?.propertiesMap?.discountPercent))}% OFF`
            ) : ''
        }

        {
          [
            "virtual_pack",
            "get_and_pay",
          ].includes(mechanic)
            ?
            <>
              {formatPrice((promotion?.finalPrice || promotion?.propertiesMap?.finalPrice) as number)}
              <span className="smaller">
                {price_unit || '/cada'}
              </span>
            </>
            : ''
        }

        {
          [
            "virtual_pack_percent_off",
            'get_and_pay_percent_off',
          ].includes(mechanic) ?
            <>
              {formatPercent(Math.floor(promotion?.discountPercent || promotion?.propertiesMap?.discountPercent))}% OFF
              <span className="smaller">
                {price_unit || '/un'}
              </span>
            </>
            : ''
        }

        {
          [
            'virtual_pack_money_off',
            'virtual_pack_item_off',
            'get_and_pay_item_off',
          ].includes(mechanic)
            ?
            <>
              {formatPrice((promotion?.finalPrice || promotion?.propertiesMap?.finalPrice) as number)}
              <span className="smaller">
                {price_unit || '/un'}
              </span>
            </>
            : ''
        }

        {
          [
            'get_and_pay_money_off',
          ].includes(mechanic)
            ?
            <>
              {formatPrice((promotion?.discountAmount || promotion?.propertiesMap?.discountAmount) as number)} {' OFF'}
              <span className="smaller">
                {price_unit || '/un'}
              </span>
            </>
            : ''
        }

        {
          [
            'cashback_product_money',
            'cashback_product_percent',
          ].includes(mechanic)
            ?
            promotion?.finalPrice ?
              formatPrice((promotion?.originalPrice || promotion?.propertiesMap?.originalPrice) as number)
              :
              <>
                {topTag}
              </>
            : <></>
        }

      </div>

      <div className="installments">
        {
          promotion?.installments
            && promotion?.installmentsPrice
            ?
            <>
              {'Em até '} {promotion?.installments || promotion?.propertiesMap?.installments} {'x de '} {formatPrice((promotion?.installmentsPrice || promotion?.propertiesMap?.installmentsPrice) as number)}
            </>
            : ''
        }
      </div>

      {children}
    </div>
  )
}
