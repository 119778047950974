import { FC, useMemo } from "react";
import { Helmet } from "react-helmet";

export const Head: FC = () => {
  const { glob_programName, favicon, description } = window.parameters

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta property="og:type" content="page" />
      <meta
        property="og:image"
        content={favicon}
      />
      <meta
        property="og:description"
        content={description}
      />
      <meta
        name="description"
        content={description}
      />
      <link
        rel="shortcut icon"
        type="image/png"
        href={favicon}
      />
      <meta
        property="og:title"
        content={`${glob_programName}`}
      />
    </Helmet>
  );
};
