import { createAsyncThunk, createSlice, PayloadAction, Draft } from "@reduxjs/toolkit"

import {
  actions,
  store,
} from '@assets/store'

import {
  Customer,
  LinkPosition,
  RawReminder,
  Reminder,
  SKU,
  TransactionResponse,
  Advertiser,
} from "@assets/types"

import {
  parametersObjectToQueryString,
  getApiRoot,
} from '@/utils/internal functions'

import {
  sessionParameter,
} from '@assets/utils'

export interface ParametersState {
  advertiser: Advertiser[];
  transactions: TransactionResponse[];
  totalAmountPending: number;
  notAuth: boolean;
  showContact: boolean;
  showCashbackModal: boolean;
  showFaleConoscoModal: boolean;
  FooterMobileY: number;
  headerHeight: number;
  DeniedNotificationsModalState: any;
  CashbackLinkPosition: LinkPosition | null;
  lastVisitCustomerCashbackMaxPercent: number;
  lastVisitedMarketplace: number;
  reminders: Reminder[] | null;
  lastViewedReminders: string;
  rawReminders: RawReminder[];
  RemindersLinkPosition: LinkPosition | null;
  popupsOrder: string[];
  //this will trigger the notifications check in DeniedNotificationsModal
  checkNotificationsPermission: string | null | boolean;
  showWebpushTopPopup: boolean;
  showWebpushBaloonPopup: boolean;
  showEmailSkippedPopup: boolean;
  showPhoneSkippedPopup: boolean;
}

const initialState: ParametersState = {
  advertiser: [],
  transactions: [],
  totalAmountPending: 0.0,
  notAuth: false,
  showContact: false,
  showCashbackModal: false,
  showFaleConoscoModal: false,
  FooterMobileY: 0,
  headerHeight: 0,
  DeniedNotificationsModalState: {
    continue: false, // proceed with flow opened it
    opened: false, //modal is opened
  },
  CashbackLinkPosition: null, //cashback link left position in navigations
  lastVisitedMarketplace: 0,//time in milliseconds (same as new Date.getTime())
  lastVisitCustomerCashbackMaxPercent: 0,
  reminders: null,//reminders with sku data
  lastViewedReminders: localStorage.getItem('lastViewedReminders') || '', // reminderState from user
  rawReminders: [],//reminders parsed from user
  RemindersLinkPosition: null, //reminders link left position in navigations
  //the object here holds the order of popups appearence
  popupsOrder: [
    'haveNotStarted',
    'StoreSelectModal',
    'RecentlyActivatedPromotionsModal',
    'SplashscreenAppCTA',
    'ContinueRegistrationModal',
    'RemindersPopup',
    'MarketplacePopup',
  ],
  checkNotificationsPermission: null,
  showWebpushTopPopup: false,
  showWebpushBaloonPopup: false,
  showEmailSkippedPopup: false,
  showPhoneSkippedPopup: false,
};

export const fetchCustomerAdvertiser = createAsyncThunk(
  "propzMedia/fetchCustomerAdvertiser",
  async (_, thunkApi) => {
    try {
      actions.app.update_dataLoading({ advertising: true })

      const resp = await fetch(`${getApiRoot()}/v1/user/marketplace/advertisers?${sessionParameter()}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
      })

      const json = await resp.json()

      actions.app.update_dataLoading({ advertising: false })

      return json
    } catch (error) {
      actions.app.update_dataLoading({ advertising: false })
      console.log("[propzMedia/fetchCustomerAdvertiser] error : ", error)
      return thunkApi.rejectWithValue(error)
    }
  }
);

export const requestDeleteData = createAsyncThunk(
  "propzMedia/requestDeleteData",
  async (_, thunkApi) => {
    try {

      let r = await fetch(`${getApiRoot()}/v1/user/data-delete-request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      })

      actions.app.customer({ ...store.app.customer(), deleteRequestDate: new Date().toISOString() })

      return r

    } catch (error) {
      console.log("[propzMedia/requestDeleteData] error : ", error);
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const cancelRequestDeleteData = createAsyncThunk(
  "propzMedia/cancelRequestDeleteData",
  async (_, thunkApi) => {
    try {

      const r = await fetch(`${getApiRoot()}/v1/user/cancel-data-delete-request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      })

      actions.app.customer({ ...store.app.customer(), deleteRequestDate: null })

      return r

    } catch (error) {
      console.log("[propzMedia/cancelRequestDeleteData] error : ", error);
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const requestExportData = createAsyncThunk(
  "propzMedia/requestExportData",
  async (_, thunkApi) => {
    try {

      return await fetch(`${getApiRoot()}/v1/user/data-export-request?confirmationTemplateName=confirmacao_de_solicitacao_de_exportacao_de_dados`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(data => data.json())

    } catch (error) {
      console.log("[propzMedia/requestExportData] error : ", error);
      return thunkApi.rejectWithValue(error);
    }
  }
)

export const getTransactions = createAsyncThunk(
  "propzMedia/getTransactions",
  async (_, thunkApi) => {

    try {
      actions.app.update_dataLoading({ transactions: true })

      let transactions = await fetch(`${getApiRoot()}/v1/user/marketplace/transactions?${sessionParameter()}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(data => data.json())


      actions.app.update_dataLoading({ transactions: false })

      return transactions

    } catch (error) {

      actions.app.update_dataLoading({ transactions: false })

      console.log("propzMedia/getTransactions error : ", error);
      return thunkApi.rejectWithValue(error);
    }
  }
)

export const activatePromotion = createAsyncThunk(
  "propzMedia/activatePromotion",
  async (properties: {
    promotionId: string,
    trackingIds?: string
  }, thunkApi) => {

    try {
      return await fetch(`${getApiRoot()}/v1/user/promotion-activate?${parametersObjectToQueryString(properties)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    } catch (error) {
      console.log("[propzMedia/activatePromotion] error : ", error);
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "propzMedia/updateCustomer",
  async (properties: Partial<Customer>, thunkApi) => {
    try {
      let r = await fetch(`${getApiRoot()}/v2/cxp/customer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(properties)
      })

      if (r.status != 200) {
        return thunkApi.rejectWithValue(r)
      }

      const json = await r.json()

      actions.app.customer(json)

      return json

    } catch (error) {
      console.log("(propzMediaSlice.ts) error propzMedia/updateCustomer : ", error);
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteCustomerProps = createAsyncThunk(
  "propzMedia/deleteCustomerProps",
  async (props: (keyof Customer)[], thunkApi) => {
    try {
      let r = await fetch(`${getApiRoot()}/v2/cxp/customer?property=${props.join(',')}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
      })

      if (r.status != 200) {
        return thunkApi.rejectWithValue(r)
      }

      const json = await r.json()

      actions.app.customer(json)

      return json

    } catch (error) {
      console.log("(propzMediaSlice.ts) error propzMedia/deleteCustomerProps : ", error);
      return thunkApi.rejectWithValue(error);
    }
  }
)

export const trackEvent = createAsyncThunk(
  "propzMedia/trackEvent",
  async (properties: any, thunkApi) => {
    try {

      /* 
      category: 'behavior',
      action: 'offer-view',
      property: entry.target.dataset.promotion_id,
      label: 'digital',
      trackingIds
        */

      await fetch(`${getApiRoot()}/v1/track-event?dbId=${window.DB_UUID}&${parametersObjectToQueryString(properties)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      })

    } catch (error) {
      console.log("(propzMediaSlice.ts) error propzMedia/trackEvent : ", error);
      thunkApi.rejectWithValue(error);
    }
  }
)

export const trackOfferView = createAsyncThunk(
  "propzMedia/trackOfferView",
  async (properties: any, thunkApi) => {
    try {

      /* 
      promotionIds: entry.target.dataset.promotion_id,
      uid: window.rawUser?.uuid,
      trackingIds
        */

      await fetch(`${getApiRoot()}/v1/track-offer-view?dbId=${window.DB_UUID}&${parametersObjectToQueryString(properties)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      })

    } catch (error) {
      console.log("(propzMediaSlice.ts) error propzMedia/trackOfferView : ", error);
      thunkApi.rejectWithValue(error);
    }
  }
)

export const getReminders = createAsyncThunk(
  "propzMedia/getReminders",
  async (rawReminders: RawReminder[], thunkApi) => {
    try {

      actions.app.update_dataLoading({ reminders: true })

      let skus: any = await fetch(`${getApiRoot()}/v1/user/retail-sku?skuIds=${rawReminders.map(r => r.productId).join(',')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      })

      if (skus.status != 200) {
        actions.app.update_dataLoading({ reminders: false })
        return thunkApi.rejectWithValue(rawReminders)
      }

      skus = await skus.json()
      actions.app.update_dataLoading({ reminders: false })

      return rawReminders.map(rawReminder => {
        return {
          ...rawReminder,
          data: skus.items.find((sku: SKU) => sku.skuId == rawReminder.productId)
        }
      })

    } catch (error) {
      actions.app.update_dataLoading({ reminders: false })
      console.log("(propzMediaSlice.ts) error propzMedia/getReminders : ", error);
      thunkApi.rejectWithValue(error);
    }
  }
)

export const propzMediaSlice = createSlice({
  name: "propzMedia",
  initialState,
  reducers: {
    setPropzMediaStateParameter: (state, { payload }: PayloadAction<{ [K in keyof Draft<ParametersState>]?: Draft<ParametersState>[K] }>) => {
      //payload === {[parameter name to set]: parameter value to set}
      const [name, value] = Object.entries(payload)[0]
      //@ts-ignore
      state[name] = value
    },
  },
  extraReducers: {
    [getReminders.fulfilled.type]: (state, { payload }) => {
      state.reminders = payload
    },
    [getReminders.rejected.type]: (state, { payload }) => {
      state.reminders = payload
    },
    [fetchCustomerAdvertiser.fulfilled.type]: (state, { payload }) => {


      if (typeof payload?.filter == 'function') {
        state.advertiser = payload?.filter(
          (a: any) => a.customerCashbackMaxPercent != null
        )
          .sort(
            (x: any, y: any) =>
              y.customerCashbackMaxPercent - x.customerCashbackMaxPercent
          )
      }
    },
    [getTransactions.fulfilled.type]: (state, { payload }) => {

      state.transactions = payload
    },
  },
});

export const {
  setPropzMediaStateParameter,
} = propzMediaSlice.actions;

export default propzMediaSlice.reducer;
