import { FC } from "react";
import "./style.css";
import loaderImage from './assets/loadingStatus.png'

export const GlobalLoader: FC = () => {
  return (
    <div id="#beforeLoad" className="center-align loaderWrapper">
      <div className="loaderCard" >
        <div className="loader circle-one circle-full blink" style={{position: "absolute", zIndex: 2}} />
        <div className="loader circle-two circle-full blink" style={{position: "absolute", zIndex: 2}} />
        <div className="loader circle-three circle-full blink" style={{position: "absolute", zIndex: 2}} />
      </div>
      <p className="loaderLabel">Bem-vindo!</p>
    </div>
  );
};
