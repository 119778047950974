import { FC, useEffect, useMemo, } from "react"

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import {
  setPropzMediaStateParameter,
} from "@/features/propzMedia/propzMediaSlice"

import {
  ReminderCard,
} from '@/components'

import { QueryLink } from "@/parts"

import { Button } from "react-materialize"

import "./style.sass"

export const Reminders: FC = () => {

  const dispatch = useAppDispatch()

  const {
    reminders,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  useEffect(() => {
    if (reminders) {
      const lastViewedReminders = reminders.map(r => `${r.productId}:${r.remainingDays}`).join(',')

      dispatch(setPropzMediaStateParameter({ lastViewedReminders }))

      localStorage.setItem('lastViewedReminders', lastViewedReminders)
    }
  }, [reminders])

  const singleReminder = useMemo(() => (reminders?.length ?? 0) > 1, [reminders])

  return (
    <div className="reminders_page">

      <div className="header">
        {
          window.parameters.reminders_supermarket ?
            singleReminder ?
              'Não fique sem seu produto favorito!'
              :
              'Não fique sem seus produtos favoritos!'
            :
            <>
              Continue seu tratamento com {window.parameters.glob_programName}!
            </>
        }
      </div>

      <div className="subheader">
        {
          window.parameters.reminders_supermarket ?
            singleReminder ?
              <>
                {'De acordo com seu histórico de compras, este produto deve estar no final. Garanta já com as vantagens '}{window.parameters.glob_programName}!
              </>
              :
              <>
                {'De acordo com seu histórico de compras, estes produtos devem estar no final. Garanta já com as vantagens '}{window.parameters.glob_programName}!
              </>
            :
            singleReminder ?
              <>
                {'De acordo com seu histórico de compras, este medicamento está no final. Garanta já com as vantagens '}{window.parameters.glob_programName}!
              </>
              :
              <>
                {'De acordo com seu histórico de compras, estes medicamentos estão no final. Garanta já com as vantagens '}{window.parameters.glob_programName}!
              </>
        }
      </div>

      <div className={`reminders_container ${Number(reminders?.length) > 2 ? 'reminders_grid' : ''}`}>
        {
          reminders?.map((reminder, i) =>
            <ReminderCard reminder={reminder} key={i} />
          )
        }
      </div>

      <QueryLink to="/" className="to_ofertas_button">
        <Button
          flat
          className="primary_background text_primary_background common_text_button common_button_font_size common_button_border_radius common_button_shadow"
        >
          Quero ver minhas ofertas
        </Button>
      </QueryLink>

    </div >
  );
};
