import { getApiRoot } from '@assets/functions/getApiRoot'
import { pdcStore } from '@assets/store/pdcStore'

import {
  sessionParameter,
  toast,
} from '@assets/utils'
import { vipcommerce_redirect } from '@assets/utils/vipcommerce_redirect'

import * as Sentry from "@sentry/react"

export const add_to_cart = async () => {

  /* 
    Mock success with failures
    
  pdcStore.set.showEcommerceCartErrorPopup(true)
  const failures = pdcStore.get.ecommerceCart().map(product => product.productId)
  pdcStore.set.ecommerceFailures(failures)
  return
  */
//-------------

  if (!window.ecommerceData?.centro_distribuicao_id || !window.ecommerceData?.filial_id) {
    pdcStore.set.showEcommerceCartErrorPopup(true)

    if (!window.ecommerceData?.centro_distribuicao_id) {
      Sentry.captureMessage(`[Vipcommerce] Missing property centro_distribuicao_id`)
    }

    if (!window.ecommerceData?.filial_id) {
      Sentry.captureMessage(`[Vipcommerce] Missing property filial_id`)
    }

    return
  }

  const ecommerceCart = pdcStore.get.ecommerceCart()

  const cart = ecommerceCart.map(product => {
    const { productId, quantity } = product
    return { productId, quantity }
  })

  let r = await fetch(`${getApiRoot()}/v1/ecommerce/user/vip-commerce/add-to-cart?${sessionParameter()}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      cart,
      idCd: window.ecommerceData.centro_distribuicao_id,
      idFilial: window.ecommerceData.filial_id,
    })
  })

  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      items: cart.map(({ productId, quantity }) => ({
        item_id: productId,
        quantity,
      })),
    }
  })

  if (r.status != 200) {
    toast('Erro ao adicionar itens no carrinho, por favor tente novamente')

    pdcStore.set.showEcommerceCartErrorPopup(true)

  } else {

    const body = await r.json()

    console.log('[add_to_cart] response : ', body)

    if (body.inserted) {
      pdcStore.set.showEcommerceCartSuccess(true)
    }

    if (body.failures?.length) {
      pdcStore.set.showEcommerceCartErrorPopup(true)
      pdcStore.set.ecommerceFailures(body.failures)

      if (body.inserted) {
        pdcStore.set.redirectAfterEcommerceCartErrorPopup(true)
      }

    } else {
      if (body.inserted) {
        vipcommerce_redirect()
      }
    }
  }

}