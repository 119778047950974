import { FC, useEffect, useMemo, useState, createRef, useCallback } from "react"
import { NavLink, useLocation } from "react-router-dom";

import { Modal, Button } from "react-materialize";

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import { useMediaQuery } from 'react-responsive'

import {
  setPropzMediaStateParameter,
} from "@/features/propzMedia/propzMediaSlice"

import "./style.sass"

import { orderedPopupTimings } from '@assets/constants/orderedPopupTimings'

import { pdcStore } from "@assets/store/pdcStore"

export const MarketplacePopup: FC = () => {

  const dispatch = useAppDispatch()

  const { pathname } = useLocation()

  const {
    advertiser,
    CashbackLinkPosition,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const MarketplacePopupOpened = pdcStore.use.MarketplacePopupOpened()

  const marketplaceEnable = pdcStore.use.marketplaceEnable()

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const lastClosedPopup = pdcStore.use.lastClosedPopup()

  useEffect(() => {
    if (CashbackLinkPosition) {

      let m = document.querySelector<HTMLElement>('#MarketplacePopup')

      if (isMobile) {
        m?.style.setProperty('--arrow-left', `${CashbackLinkPosition.x - 18}px`)

      } else {

        m?.style.setProperty('left', `${CashbackLinkPosition.x - 10}px`)
        m?.style.setProperty('right', 'auto')

        m?.style.setProperty('--top', `${CashbackLinkPosition.y}px`, 'important')
      }

    }
  }, [CashbackLinkPosition])

  const closePopup = useCallback((wait?: boolean) => {
    pdcStore.set.MarketplacePopupOpened(false)

    setTimeout(() => {
      if (pdcStore.get.showed_popups_count() < window.parameters.allowed_popus_count) {
        //if can show one more -> switch last closed popup
        pdcStore.set.lastClosedPopup('MarketplacePopup')
      }
    }, wait ? orderedPopupTimings.MarketplacePopup : 0)

  }, [])

  useEffect(() => {
    if (lastClosedPopup == 'RemindersPopup') {

      if (marketplaceEnable) {

        if (CashbackLinkPosition) {
          pdcStore.set.MarketplacePopupOpened(true)
          pdcStore.set.increment_showed_popups_count()
        }
      } else {
        closePopup()
      }
    }
  }, [CashbackLinkPosition, marketplaceEnable, lastClosedPopup])

  const advertiserButton = useMemo(() => {
    return advertiser[0] ?
      [
        <Button flat modal="close" node="a" waves="light"
          //@ts-ignore
          href={advertiser[0]?.clickUrl} target="_blank" className="white-text" onClick={
            () => window.dataLayer.push({
              event: 'Click-CTA-Mktplace-Popup',
              store: advertiser[0]?.name
            })
          }>
          Quero aproveitar!
        </Button>
      ] : []
  }, [advertiser])

  return (
    <Modal
      actions={[

        ...advertiserButton,

        <NavLink
          to={'/shopping?utm_source=portal-do-cliente&utm_medium=popup-home&utm_campaign=cta-mktplace#shops_search'}
          className="text_button" onClick={
            () => window.dataLayer.push({
              event: 'Click-CTA-Mktplace-Popup',
            })
          }
        >
          <Button flat modal="close" node="button" waves="light">
            Ver todas as marcas parceiras
          </Button>
        </NavLink>

      ]}
      id="MarketplacePopup"
      open={
        MarketplacePopupOpened
        && !!CashbackLinkPosition
        && pathname != '/shopping'
        && !pathname.includes('cadastro')
        && !pathname.includes('webpush-pagina')
      }
      header="Seu dinheiro de volta!"
      options={{
        dismissible: true,
        onCloseEnd: () => closePopup(true)
      }}
    >

      <p>
        Compre nas melhores marcas e receba cashback para usar em compras Propz Club!
      </p>

      <div className="shop">

        <div className="shop_logo"
          style={{
            backgroundImage: `url(${advertiser[0]?.logoUrl})`
          }}></div>

        <div className="cashback_percent">
          {advertiser[0]?.customerCashbackMaxPercent}% de volta
        </div>

      </div>

    </Modal>
  );
};