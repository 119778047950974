import {
  useLocation,
  match,
} from "react-router-dom"

import { matchPath } from "react-router"

import {
  getRegPhases,

} from "@/utils/internal functions"

import {
  RegistrationPhase
} from '@assets/types'

export const getNextRegStep = (pathname: string) => {

  const phases = getRegPhases()

  const { phase } = (matchPath(pathname, { path: '/cadastro-:phase' }) as match<{ phase: RegistrationPhase }>)?.params ?? {}

  return phases[phases.indexOf(phase) + 1]
}